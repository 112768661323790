import { Injectable } from '@angular/core';

import { Http, Response } from "@angular/http";
import { Headers } from '@angular/http'

@Injectable()
export class SendEmailService {

  constructor(private http: Http) { }

  sendEmail(dest, content, subject) {  
    var cabe = new Headers();
    cabe.append('Content-Type', 'application/json');
    
    //return this.http.post('http://localhost:5000/agb-os/us-central1/sendMail', 
    return this.http.post('https://us-central1-agb-os.cloudfunctions.net/sendMail', 
                      {
                        'dest': dest,
                        'content': content,
                        'subject': subject
                      },{
                        headers : cabe
                      }).toPromise()
                      .then(response => console.log(response))
                      .catch(error => console.log(error));
    
  
  }

}
