import { Component, OnInit } from '@angular/core';
import { DialogComponent, DialogService } from "../../../ng2-bootstrap-modal";
import { CurrencyMaskModule } from "ng2-currency-mask";
import * as moment from 'moment';

import { Estoque } from '../../../classes/estoque';

export interface ConfirmModel {
  title: string;
  message: string;
  itemEstoque: Estoque;
  modoTela: number;
  isMaster: boolean;
  database: string;
}

@Component({
  selector: 'app-estoque-form',
  templateUrl: './estoque-form.component.html',
  styleUrls: ['./estoque-form.component.css']
})
export class EstoqueFormComponent extends DialogComponent<ConfirmModel, boolean> implements ConfirmModel, OnInit {
  title: string;
  message: string;
  itemEstoque: Estoque;
  modoTela: number;
  isMaster: boolean;
  database: string;

  historicoEntrada = [];
  historicoSaida = [];

  constructor(dialogService: DialogService) {
    super(dialogService);
  }

  confirm(form) {
    this.result = true;
    this.close();
  }

  cancelar(form){
    this.close();
  }

  ngOnInit() {
    this.historicoEntrada = this.itemEstoque.historicoEntrada ? JSON.parse(this.itemEstoque.historicoEntrada) : [];
    this.historicoEntrada.forEach((item) => {
      item.qtd = +item.qtd;
      item.qtdExterna = item.qtdExterna ? +item.qtdExterna : 0;
    });
    this.historicoSaida = this.itemEstoque.historicoSaida ? JSON.parse(this.itemEstoque.historicoSaida) : [];
  }

  public formataData(data){
    return moment(data, 'YYYY-MM-DDTHH:mm').format('DD/MM/YYYY HH:mm');
  }

  public removerSaida(index){
    this.historicoSaida.splice(index, 1);
    this.itemEstoque.historicoSaida = JSON.stringify(this.historicoSaida);
  }
  public removerEntrada(index){
    this.historicoEntrada.splice(index, 1);
    this.itemEstoque.historicoEntrada = JSON.stringify(this.historicoEntrada);
  }

}
