import { Component, OnInit, Inject } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import { DialogService } from "../../ng2-bootstrap-modal";
import { DOCUMENT } from '@angular/common';

import { ordemServico } from '../../classes/ordemServico';
import { User } from '../../classes/user';
import * as moment from 'moment';
import * as firebase from 'firebase';
import { OsFormComponent } from '../dialogs/os-form/os-form.component';

import { Observable } from 'rxjs/Observable';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebaseApp from 'firebase/app';
import { OrderOSPipe } from '../pipes/orderOS.pipe';
import { LogService } from '../log/log.service';
import { SendEmailService } from '../services/send-email/send-email.service';
import { OsViewFunctionsService } from './service/os-view-functions.service';

@Component({
  selector: 'app-os-view',
  templateUrl: './os-view.component.html',
  styleUrls: ['./os-view.component.css']
})
export class OsViewComponent implements OnInit {
  dataBase = 'prod';
  isMaster: boolean = false;
  isAdmin: boolean = false;

  clientes: any[] = [];
  clientesEmOS: any[] = [];
  ordemServicoAbertas: AngularFireList<any>;
  //ordemServicoConcluidas: AngularFireList<any>;
  logsList: AngularFireList<any>;
  usuarioList: AngularFireList<any>;
  ultimoCodigoOS: number = 101;
  ordemServico: any[] = [];
  ordemServicoBUG: any[] = [];
  ordemServicoFiltrado: any[] = [];
  //ordemServicoTeste: any[] = [];
  tecnicos: any[] = [];
  setores: any[] = [];
  usuAtivo: User;
  fireUser: firebaseApp.User;
  carregou: boolean = false;
  listaSituacao: Observable<any[]>;
  editingSeq = -1;

  filtroCliente: string = '';
  filtroTecnico: string = '';
  filtroSetor: string = '';
  filtroSituacao: string = '';
  filtroCodigo: string = '';
  filtroLocal: number = -1;
  concluidasAcessadas = false;

  abertas =[];
  fechadas =[];

  isMobile = false;
  constructor(public db: AngularFireDatabase, private dialogService: DialogService, private afAuth: AngularFireAuth, 
              private logServ:LogService, private sendEmailService: SendEmailService, public functions: OsViewFunctionsService,
              @Inject(DOCUMENT) private _document: HTMLDocument) {
    if(/mobi|(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
    || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) 
    this.isMobile = true;

    /*
    let valorParcela = 1000;
    let valorTotal = 50000;
    let totalJuros = 0;
    let jurosParcela = 0.005;
    let count = 0;
    while (valorTotal >= valorParcela) {
      count++;
      let juros = valorTotal * jurosParcela;
      totalJuros += juros;
      valorTotal = valorTotal - (valorParcela);
      console.log(count + '- ' + valorTotal + " - " + (valorParcela + +juros));
      //console.log(valorTotal + " - " + (valorParcela));
    }

    let pagJuros = totalJuros;
    while (pagJuros > valorParcela) {
      let juros = pagJuros * jurosParcela;
      totalJuros += juros;
      pagJuros = pagJuros - (valorParcela + juros);
      console.log(valorParcela + juros);
      count++;
    }

    console.log(count);
    console.log(totalJuros);*/

    let ObsUser2 = afAuth.authState.subscribe((user: firebase.User) => {
      let ObsUser = this.db.object("/usuarios").snapshotChanges().map(action => {
           const data = action.payload.toJSON();
           return data;
         }).subscribe(result => {
            this.tecnicos = [];
            Object.keys(result).map(key=> {
              if (user && result[key].email.toLowerCase() == user.email.toLowerCase()){
                let base = JSON.parse(localStorage.getItem("system-base")).base;
                if (result[key].base == base) {
                  this.usuAtivo = result[key];
                  this.dataBase = this.usuAtivo.base;
                  this.isAdmin = this.usuAtivo.adm;
                  this.isMaster = this.usuAtivo.master;

                  
                  if (result[key].setor) {
                    let setores = result[key].setor.split(';').map((setor) => setor.trim());
                    setores.forEach((item) => {
                      if (this.setores.indexOf(item) == -1) {
                        this.setores.push(item);
                      }
                    })
                    
                  }    
                }
              }

              if (result[key].tecnico && result[key].ativo){
                this.tecnicos.push({ 'key': key, 'data':result[key]});            
              }
            });
            if (this.dataBase == 'prod') {
              this.afAuth.auth.signOut();
            }

            if (window.location.href.indexOf('dgsys') > -1 || window.location.href.indexOf('localhost') > -1) {
              this._document.getElementById('appFavicon').setAttribute('href', '../../../../assets/logo-dg.png');
            } else { 
              if (window.location.href.indexOf('datapush') > -1) {
                this._document.getElementById('appFavicon').setAttribute('href', '../../../../assets/logo-datapush.png');
              } else {
                if (window.location.href.indexOf('drrafael') > -1) {
                  this._document.getElementById('appFavicon').setAttribute('href', '../../../../assets/logo-drrafael.jpeg');
                } else {
                  this._document.getElementById('appFavicon').setAttribute('href', '../../../../favicon.png');
                }
              }              
            }

            var i = 0;
            while (i < this.tecnicos.length){
              if (this.tecnicos[i].data.base != this.dataBase){
                this.tecnicos.splice(i, 1);
              } else {
                i++;
              }
            }

            this.logsList = this.db.list(this.dataBase + "/logs/");
            this.ordemServicoAbertas = this.db.list(this.dataBase + "/ordemServico/abertas/");
            //this.ordemServicoConcluidas = this.db.list(this.dataBase + "/ordemServico/concluidas/");
            this.listaSituacao = this.db.list(this.dataBase + "/situacoes/").valueChanges();
            if (this.dataBase != 'gessner') {
              this.carregarDadosOSComClientes();
            } else {
              this.carregarDadosOSSemClientes();
            }
            
            ObsUser.unsubscribe;
      });

      ObsUser2.unsubscribe;
      return this.fireUser = user;
    });
  }

  public aplicaFiltro(){
    this.ordemServicoFiltrado = [];
    this.ordemServico.forEach((item) => {
      if (this.filtro(item.data)){
        if (item.data.atividades) {
          item.data.atividadesJSON = JSON.parse(item.data.atividades);
        }
        this.ordemServicoFiltrado.push({ 'key': item.key, 'data': item.data, 'nomeCliente': item.nomeCliente, 'mobileVisivel': item.mobileVisivel, 'countAtividades': item.countAtividades});
      }
    });
  }

  carregarDadosOSSemClientes() {
      this.db.object(this.dataBase + "/ordemServico/abertas").snapshotChanges().map(action => {
          const data = action.payload.toJSON();
          return data;
        }).subscribe(result => {
            this.ordemServico = [];
            this.ordemServicoBUG = [];

            let countOS = 0;
            if (result){
              Object.keys(result).map(key=> {
                countOS++;

                // ---------------------
                // IF é OS automática 
                if (moment(result[key].dataInicio, 'YYYY-MM-DDTHH:mm').isAfter(moment('01/08/2019', 'YYYY-MM-DD')) && 
                    (result[key].situacao == "Relatório Backup" ||
                    result[key].situacao == "Check List Servidor")) {
                      let cliente = this.getClienteNome(result[key].codCliente);
                      let countAtividades = 0;
                      if (result[key].atividades){
                        countAtividades = JSON.parse(result[key].atividades).length
                      }                            
                      this.ordemServicoBUG.push({ 'key': key, 'data':result[key], 'nomeCliente': cliente, 'mobileVisivel': false, 'countAtividades': countAtividades});
                }
                // ---------------------

                let setores = this.usuAtivo.setor.split(';');
                if (this.isAdmin || this.isMaster || result[key].nomeTecnico == this.usuAtivo.nome || this.dataBase == 'gessner') {
                  if (result[key].status == 0 || result[key].status == 1)  {                  
                    let countAtividades = 0;
                    if (result[key].atividades){
                      countAtividades = JSON.parse(result[key].atividades).length
                    }
                    this.ordemServico.push({ 'key': key, 'data':result[key], 'nomeCliente': '', 'mobileVisivel': false, 'countAtividades': countAtividades});                              
                  }                  
                }
              });

            }
            this.aplicaFiltro();
            this.carregou = true;
      }); 
  }

  public carregarDadosOSComClientes(){
    this.db.object(this.dataBase + "/clientes").snapshotChanges().map(action => {
         const data = action.payload.toJSON();
         return data;
       }).subscribe(result => {
          this.clientes = [];
          if (result){
            Object.keys(result).map(key=> {
                 this.clientes.push({ 'key': key, 'data':result[key], 'nome': result[key].nomeFantasia});
            });


            if (this.dataBase == 'drrafael') {
              this.db.object(this.dataBase + "/prospect").snapshotChanges().map(action => {
                const data = action.payload.toJSON();
                return data;
              }).subscribe(result => {
                if (result){
                  Object.keys(result).map(key=> {
                        this.clientes.push({ 'key': key, 'data':result[key], 'nome': result[key].nomeFantasia});
                  });
                }
              });
            }

            this.db.object(this.dataBase + "/ordemServico/abertas").snapshotChanges().map(action => {
                 const data = action.payload.toJSON();
                 return data;
               }).subscribe(result => {
                  this.ordemServico = [];
                  this.ordemServicoBUG = [];
                  this.clientesEmOS = [];
                  let countOS = 0;
                  if (result){
                    Object.keys(result).map(key=> {
                      countOS++;

                      // ---------------------
                      // IF é OS automática 
                      if (moment(result[key].dataInicio, 'YYYY-MM-DDTHH:mm').isAfter(moment('01/08/2019', 'YYYY-MM-DD')) && 
                          (result[key].situacao == "Relatório Backup" ||
                           result[key].situacao == "Check List Servidor")) {
                            let cliente = this.getClienteNome(result[key].codCliente);
                            let countAtividades = 0;
                            if (result[key].atividades){
                              countAtividades = JSON.parse(result[key].atividades).length
                            }                            
                            this.ordemServicoBUG.push({ 'key': key, 'data':result[key], 'nomeCliente': cliente, 'mobileVisivel': false, 'countAtividades': countAtividades});
                      }
                      // ---------------------

                      //if (result[key].codigo > 7465 && result[key].codigo < 7586) {
                      //  this.ordemServicoAbertas.remove(key);
                      //}
                      if (result[key].status == 0 || result[key].status == 1)  {
                        let cliente = this.getClienteNome(result[key].codCliente);
                        //if (this.filtro(result[key])){
                            let countAtividades = 0;
                            if (result[key].atividades){
                              countAtividades = JSON.parse(result[key].atividades).length
                            }
                            this.ordemServico.push({ 'key': key, 'data':result[key], 'nomeCliente': cliente, 'mobileVisivel': false, 'countAtividades': countAtividades});
                        //}
                        if (!this.contains(this.clientesEmOS, 'nome', cliente)){
                            this.clientesEmOS.push({ 'nome': cliente });
                        }
                      }
                    });

                  }
                  this.aplicaFiltro();
                  this.carregou = true;
            });



              /*let obsTeste = this.db.object(this.dataBase + "/ordemServico/").snapshotChanges().map(action => {
                   const data = action.payload.toJSON();
                   return data;
                 }).subscribe(result => {
                    let countClosed = 0;
                    let countOpened = 0;
                    this.fechadas = [];
                    this.abertas = [];
                    if (result){
                      Object.keys(result).map(key=> {
                        if (result[key].status == 2){
                            this.fechadas.push({'key': key, 'data': result[key]});
                            countClosed++;
                        } else {
                          if (result[key].status == 0 || result[key].status == 1)  {
                            this.abertas.push({'key': key, 'data': result[key]});
                            countOpened++;
                          }
                        }
                      });
                    }
                    alert(countClosed);
                    alert(countOpened);
                    obsTeste.unsubscribe;
              });*/


            // ####### TESTES
            /*this.db.object(this.dataBase + "/ordemServico").snapshotChanges().map(action => {
                 const data = action.payload.toJSON();
                 return data;
               }).subscribe(result => {
                  this.ordemServicoTeste = [];
                  if (result){
                    Object.keys(result).map(key=> {
                        let cliente = this.getCliente(result[key].codCliente);
                        result[key].clienteQtdHoras = cliente.qtdHoras;
                        result[key].clienteContrato = cliente.contrato
                        result[key].clienteValorHr = cliente.valor;

                        //this.ordemServicoList.update(key, result[key]);
                        this.ordemServicoTeste.push({ 'key': key, 'data':result[key], 'nomeCliente': this.getClienteNome(result[key].codCliente), 'mobileVisivel': false });

                    });
                    //console.log(this.ordemServicoTeste);
                  }
            });*/
          } else {
            this.carregou = true;
          }
    });
  }

  ngOnInit() {

  }

  private contains(array, element, itemParam){
    let retorno = false;
    array.forEach((item) => {
      if (item[element] == itemParam){
        retorno = true;
      }
    });
    return retorno;
  }

  public getClienteNome(codCliParam){
    let codCli = +codCliParam;
    let name = '';
    this.clientes.forEach((cliente, index) => {
      if (cliente.data.codCliente == codCli){
        name =  cliente.data.nomeFantasia;
      }
    });

    return name;
  }


  public getCliente(codCliParam){
    let codCli = +codCliParam;
    let clienteReturn = undefined;
    this.clientes.forEach((cliente, index) => {
      if (cliente.data.codCliente == codCli){
        clienteReturn = cliente.data;
      }
    });

    return clienteReturn;
  }

  public criarOSForm(){
/*    let ordemServicoClosed = this.db.list(this.dataBase + "/ordemServico/concluidas/");
    let ordemServicoAbertas = this.db.list(this.dataBase + "/ordemServico/abertas/");

    this.fechadas.forEach((item) => {
        let myRef = ordemServicoClosed.push(item.data);
    });
    this.abertas.forEach((item) => {
        let myRef = ordemServicoAbertas.push(item.data);
    });

    this.fechadas.forEach((item) => {
      this.db.list(this.dataBase + "/ordemServico/").remove(item.key);
    })

    this.abertas.forEach((item) => {
      this.db.list(this.dataBase + "/ordemServico/").remove(item.key);
    })*/

    var newOS = new ordemServico(0);
    newOS.dataInicio = moment().format('YYYY-MM-DDTHH:mm');

    firebase.database().ref(this.dataBase + "/utils/ultimoCodigoOS/value").once('value').then((snapshot) => {
      this.ultimoCodigoOS = snapshot.val();
      this.ultimoCodigoOS++;
      newOS.codigo = this.ultimoCodigoOS;
      firebase.database().ref(this.dataBase + '/utils/ultimoCodigoOS/').set({
          value: newOS.codigo
        });

      let disposable = this.dialogService.addDialog(OsFormComponent, {
        title:'Criando OS',
        message:'Confirm message',
        itemOS: newOS,
        clienteList: this.clientes,
        modoTela: 0,
        tecList: this.tecnicos,
        usuAtivo: this.usuAtivo
      }).subscribe((isConfirmed)=>{
        if(isConfirmed) {
          let myRef = this.ordemServicoAbertas.push(newOS);
          //AQUI DEVE ENVIAR EMAIL
          if (this.dataBase == 'gessner') {
            const subject = `Criação de Ordem de serviço`;
            const content = `Sua ordem de serviço foi criada com sucesso.<br>
                             Código: ${newOS.codigo}`;
            this.sendEmailService.sendEmail(newOS.emailTecnico, content, subject);
          }  

          if (newOS.urgente) {
            const subject = `OS Urgente`;
            const content = `OS ${newOS.codigo} é urgente no cliente ${this.dataBase}.`;
            this.sendEmailService.sendEmail('suporte@agbcomputadores.com.br', content, subject);
          }

          this.logsList.push({usuario: this.usuAtivo.email, acao: "Criou", codigo: newOS.codigo, tipo: "OS", chaveReg: myRef.key, registros: JSON.stringify(newOS), data: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')});
        }else{
          console.log('Criação de OS cancelada.');
          localStorage.removeItem('AGBLastOS'); 
        }
      });
    });
  }

  public editarOS(ordem){
    var OSBkp = Object.assign({}, ordem.data);
    var OSCopy = Object.assign({}, ordem.data);
    let disposable = this.dialogService.addDialog(OsFormComponent, {
      title:'Alterando OS #' + OSCopy.codigo,
      message:'Confirm message',
      itemOS: OSCopy,
      clienteList: this.clientes,
      modoTela: 1,
      tecList: this.tecnicos,
      usuAtivo: this.usuAtivo
    }).subscribe((isConfirmed)=>{
      if(isConfirmed) {
        let log = this.logServ.comparaOS(OSBkp, OSCopy);
        //if (log.length > 0){
          console.log('############# EDITANDO #############');
          console.log('Gravando LOG: ' + moment().format('HH:mm:ss.SSS'));
          this.logsList.push({usuario: this.usuAtivo.email, acao: "Alterou", codigo: OSCopy.codigo, tipo: "OS", chaveReg: ordem.key, registros: JSON.stringify(log), data: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')});
          console.log('Gravando alterações: ' + moment().format('HH:mm:ss.SSS'));
          this.ordemServicoAbertas.update(ordem.key, OSCopy);

          //AQUI DEVE ENVIAR EMAIL SE FOR ADICIONADO UMA NOVA OS
          if (this.dataBase == 'gessner' && ordem.data.atividades != OSCopy.atividades) {
            const subject = `Alteração em atividade na Ordem de serviço`;
            const content = `Houve alterações nas atividades da sua ordem de serviço ${ordem.data.codigo}.`;
            this.sendEmailService.sendEmail(OSCopy.emailTecnico, content, subject);
          }          

          if (OSCopy.urgente && OSCopy.urgente != OSBkp.urgente) {
            const subject = `OS Urgente`;
            const content = `OS ${ordem.data.codigo} é urgente no cliente ${this.dataBase}.`;
            this.sendEmailService.sendEmail('suporte@agbcomputadores.com.br', content, subject);
          }

          console.log('Terminou de gravar alterações' + moment().format('HH:mm:ss.SSS'));
          console.log('##########################');
        //}
      }else{
        console.log('Alteração de OS cancelada.');
      }
    });
  }

  public formEncerrarOS(ordem){

    var OSBkp = Object.assign({}, ordem.data);
    var OSCopy = Object.assign({}, ordem.data);
    let disposable = this.dialogService.addDialog(OsFormComponent, {
      title:'Encerrando OS',
      message:'Confirm message',
      itemOS: OSCopy,
      clienteList: this.clientes,
      modoTela: 3,
      tecList: this.tecnicos,
      usuAtivo: this.usuAtivo
    }).subscribe((isConfirmed)=>{
      if(isConfirmed) {
        console.log('############# ENCERRANDO #############');
        console.log('Gravando LOG: ' + moment().format('HH:mm:ss.SSS'));
        this.logsList.push({usuario: this.usuAtivo.email, acao: "Encerrou", codigo: OSCopy.codigo, tipo: "OS", chaveReg: ordem.key, registros: JSON.stringify(OSCopy), data: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')});
        console.log('Gravando LOG concluídas: ' + moment().format('HH:mm:ss.SSS'));

        let dataFimFormatada = moment(OSCopy.dataFim, 'yyyy-MM-DDTHH:mm').format('YYYY-MM-DD');
        let myRef = this.db.list(this.dataBase + "/ordemServico/concluidas/" + dataFimFormatada).push(OSCopy);

        console.log('Remove da lista de abertas: ' + moment().format('HH:mm:ss.SSS'));
        this.ordemServicoAbertas.remove(ordem.key);
        //AQUI DEVE ENVIAR EMAIL
        if (this.dataBase == 'gessner') {
          const subject = `Ordem de serviço encerrada`;
          const content = `Sua ordem de serviço ${ordem.data.codigo} foi encerrada.`;
          this.sendEmailService.sendEmail(OSCopy.emailTecnico, content, subject);
        }

        console.log('Terminou de encerrar' + moment().format('HH:mm:ss.SSS'));
        console.log('##########################');
      }else{
      }
    });
  }

  // Mobile
  public mostrarOS(ordem){
    ordem.mobileVisivel = true;
  }

  // Mobile
  public esconderOS(ordem){
    ordem.mobileVisivel = false;
  }

  // Mobile
  public encerrarOS(ordem){
    ordem.mobileVisivel = false;
    if (confirm('Deseja encerrar a OS?')){
      ordem.data.status = 2;
      ordem.data.dataFim = moment().format('YYYY-MM-DDTHH:mm');
      //let log = this.logServ.comparaOS(OSBkp, OSCopy);
      //if (log.length > 0){
        //this.logsList.push({usuario: this.usuAtivo.email, acao: "Alterou", codigo: ordem.data.codigo, tipo: "OS", chaveReg: ordem.key, registros: JSON.stringify(log), data: moment().format('DD-MM-YYYY HH:mm')});

        let dataFimFormatada = moment(ordem.data.dataFim, 'yyyy-MM-DDTHH:mm').format('YYYY-MM-DD');
        let myRef = this.db.list(this.dataBase + "/ordemServico/concluidas/" + dataFimFormatada).push(ordem.data);

        //this.ordemServicoConcluidas.push(ordem.data);
        this.ordemServicoAbertas.remove(ordem.key);
      //}
    }
  }

  // Mobile
  public salvaOS(ordem){
    ordem.mobileVisivel = false;
    //let log = this.logServ.comparaOS(OSBkp, OSCopy);
    //if (log.length > 0){
      //this.logsList.push({usuario: this.usuAtivo.email, acao: "Alterou", codigo: ordem.data.codigo, tipo: "OS", chaveReg: ordem.key, registros: JSON.stringify(log), data: moment().format('DD-MM-YYYY HH:mm')});
      //ordem.data.atividades = JSON.stringify(ordem.data.atividades);
      this.ordemServicoAbertas.update(ordem.key, ordem.data);
    //}
  }

  public filtraCliente(valor){
    this.filtroCliente = valor;
    this.aplicaFiltro();
  }

  public filtraTecnico(valor){
    this.filtroTecnico = valor;
    this.aplicaFiltro();
  }

  public filtraSetor(valor) {
    this.filtroSetor = valor;
    this.aplicaFiltro();
  }

  public filtraSituacao(valor){
    this.filtroSituacao = valor;
    this.aplicaFiltro();
  }

  public filtraCodigo(valor){
    this.filtroCodigo = valor;
    this.aplicaFiltro();
  }

  public filtraLocal(valor){
    this.filtroLocal = valor;
    this.aplicaFiltro();
  }

  public filtro(os){
    let respeitaFiltro = true;
    if (this.filtroCliente != "" && this.filtroCliente.toLowerCase() != this.getClienteNome(os.codCliente).toLowerCase()){
      respeitaFiltro = false;
    }
    if (this.filtroTecnico != "" && this.filtroTecnico.toLowerCase() != os.nomeTecnico.toLowerCase()){
      respeitaFiltro = false;
    }
    if (this.filtroSetor != "" && this.filtroSetor.toLowerCase() != os.setor.toLowerCase()) {
      respeitaFiltro = false;
    }
    if (this.filtroSituacao != "" && this.filtroSituacao.toLowerCase() != os.situacao.toLowerCase()){
      respeitaFiltro = false;
    }
    if (this.filtroCodigo != "" && this.filtroCodigo != os.codigo){
      respeitaFiltro = false;
    }
    if (this.filtroLocal != -1 && this.filtroLocal != os.local){
      respeitaFiltro = false;
    }

    return respeitaFiltro;
  }

  public pegaAtividadesArray(atividade){
    if (atividade){
      return JSON.parse(atividade);
    }
  }

  public carregaConcluidas(){
    this.concluidasAcessadas = true;
  }

  public editSequence(ordem, index){
    if (this.editingSeq != -1 && this.editingSeq == index) {

    } else {  
      if (this.editingSeq != -1) {
        //this.saveSequence(ordem);
      }  
      if (this.editingSeq != index) {
        this.editingSeq = index;
      } else {
        this.editingSeq = -1;
      }
    }  
  }

  public leaveSequenceField(value, ordem) {
    ordem.data['sequence'] = +value;
    this.ordemServicoAbertas.update(ordem.key, ordem.data);
    this.editingSeq = -1;
  }

  public changeSequenceField(value, ordem) {
    value.focus();
  }

  public removerOS(item){
    if (confirm("Deseja remover a OS?")){
      this.logsList.push({usuario: this.usuAtivo.email, acao: "Excluiu", codigo: item.data.codigo, tipo: "OS", chaveReg: item.key, registros: "Removeu OS", data: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')});

      let deleteList = this.db.list(this.dataBase + "/ordemServicoExcluidas");
      deleteList.push(item.data);

      let dataFimFormatada = moment(item.data.dataFim, 'YYYY-MM-DDTHH:mm').format('YYYY-MM-DD');
      let myRef = this.db.list(this.dataBase + "/ordemServico/abertas/").remove(item.key);
      this.carregarDadosOSComClientes();
    }
  }

}
