export class ordemServico {

    public codCliente: number = 0;
    public dataInicio: String = '';
    public dataFim: String = '';
    public status: number = 0;
    public local: number = 0;
    public titulo: String = '';
    public descricao: String = '';
    public nomeTecnico: String = '';
    public emailTecnico: String = '';
    public situacao: String = '';
    public esforco: number = 0;
    public esforcoNoturno: number = 0;
    public cobraValorNoturno: boolean = false;
    public solucao: String = "";
    public esforcoInicio: String = "00:00"; //deprecated
    public esforcoFim: String = "00:00"; //deprecated
    public dataVencimento: String = '';
    public dataEsforco: String = '';
    public isentar: boolean = false;
    public listaEsforco: string = '';
    public atividades: string = '';
    public clienteQtdHoras: number = 0;
    public clienteContrato: boolean = false;
    public clienteValorHr: number = 0.0;
    public clienteQtdHorasPlantao: number = 0;
    public clienteContratoPlantao: boolean = false;
    public clienteValorHrNot: number = 0.0;
    public attached: string = "";
    public sequence: number = 9999;
    public urgente: boolean = false;
    public setor: string = '';

    constructor(public codigo: number) {
    }

}
