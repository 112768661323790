import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';

import { User } from '../../../classes/user';
import { Estoque } from '../../../classes/estoque';

@Component({
  selector: 'app-mobile',
  templateUrl: './mobile.component.html',
  styleUrls: ['./mobile.component.css']
})
export class MobileComponent implements OnInit {
  openedItem = true;
  usuAtivo: User;
  dataBase: String = '';
  isMaster = false;
  listaEstoque = [];
  listaTecnicosEstoqExt = [];
  tecnicos: any[] = [];

  constructor(private afAuth: AngularFireAuth, public db: AngularFireDatabase) { 
    afAuth.authState.subscribe((user: firebase.User) => {
      this.db.object("/usuarios").snapshotChanges().map(action => {
           const data = action.payload.toJSON();
           return data;
         }).subscribe(result => {
            Object.keys(result).map(key=> {
              if (user && result[key].email.toLowerCase() == user.email.toLowerCase()){
                let base = JSON.parse(localStorage.getItem("system-base")).base;
                if (result[key].base == base) {
                  this.usuAtivo = result[key];
                  this.isMaster = this.usuAtivo.master;
                  this.dataBase = this.usuAtivo.base;
                }  
              }

              if (result[key].tecnico && result[key].ativo){
                this.tecnicos.push({ 'key': key, 'data':result[key]});
              }
            });
            var i = 0;
            while (i < this.tecnicos.length){
              if (this.tecnicos[i].data.base != this.dataBase){
                this.tecnicos.splice(i, 1);
              } else {
                i++;
              }
            }



            this.db.object(this.dataBase + "/estoque").snapshotChanges().map(action => {
                 const data = action.payload.toJSON();
                 return data;
              }).subscribe(resultEstoque => {
                  this.listaEstoque = [];
                  if(resultEstoque){
                    Object.keys(resultEstoque).map(key=> {
                        this.listaEstoque.push({'key': key, 'data': resultEstoque[key]});
                    });

                    this.listaEstoque.sort((item1, item2) =>{
                      if (item1.data.desc > item2.data.desc){
                        return 1;
                      } else {
                        if (item1.data.desc < item2.data.desc){
                            return -1
                        }
                      }
                      return 0;
                    });

                    this.listaTecnicosEstoqExt = [];
                    this.listaEstoque.forEach((item) => {

                      if (!item.data.qtdExternaLista) {
                        if (item.data.qtdExterna > 0) {
                          item.data.qtdExternaLista = [{qtdExterna: item.data.qtdExterna, tecnico: "Eric"}];
                        } else {
                          item.data.qtdExternaLista = [];
                        }
                      } else {
                        item.data.qtdExternaLista = Object.keys(item.data.qtdExternaLista).map(i => item.data.qtdExternaLista[i]);
                      }       

                      item.data.qtdExternaLista = Object.keys(item.data.qtdExternaLista).map(i => item.data.qtdExternaLista[i]);
                      item.data.qtdExternaLista.forEach((itemEstq) => {
                        if (this.listaTecnicosEstoqExt.indexOf(itemEstq.tecnico) == -1) {
                          this.listaTecnicosEstoqExt.push(itemEstq.tecnico);
                        }
                      });                      

                      item.data.qtdExternaLista.sort((item1, item2) => {
                        if (item1.tecnico > item2.tecnico){
                          return 1;
                        } else {
                          if (item1.tecnico < item2.tecnico){
                              return -1
                          }
                        }
                        return 0;
                      });
                      
                    });

                    this.listaTecnicosEstoqExt.sort((item1, item2) => {
                      if (item1 > item2){
                        return 1;
                      } else {
                        if (item1 < item2){
                            return -1
                        }
                      }
                      return 0;
                    });
                  }
              });
        });
    });
  }

  ngOnInit() {
  }

  public mostrarItem(item){
    item.mobileVisivel = !item.mobileVisivel;
  }

  public esconderItem(item){
    item.mobileVisivel = !item.mobileVisivel;
  }

}
