import { Component, OnInit } from '@angular/core';
import { DialogComponent, DialogService } from "../../../ng2-bootstrap-modal";
import { User } from "../../../classes/user";
import { DadosBancoService } from '../../services/dados-banco.service';

export interface ConfirmModel {
  title: string;
  message: string;
  usuario: User;
  dataBase: string;
  editing: boolean;
}

@Component({
  selector: 'app-usuario-form',
  templateUrl: './usuario-form.component.html',
  styleUrls: ['./usuario-form.component.css']
})
export class UsuarioFormComponent extends DialogComponent<ConfirmModel, boolean> implements ConfirmModel, OnInit {

  title: string;
  message: string;
  usuario: User;
  dataBase: string;
  editing: boolean;


  ehAdmin = false;
  ehTecnico = false;


  constructor(public serviceDados: DadosBancoService, dialogService: DialogService) {
    super(dialogService);
  }

  ngOnInit() {
    this.ehTecnico = this.usuario.tecnico;
    this.ehAdmin = this.usuario.adm;
  }

  confirm(form) {

    if (form.edtUserName.value == ''){
      alert('Favor informar o "Username" do usuário');
      return
    }
    if (form.edtNomeCompleto.value == ''){
      alert('Favor informar o "Nome" do usuário');
      return
    }
    if (form.edtEmail.value == ''){
      alert('Favor informar o "E-mail" do usuário');
      return
    }
    if (!this.editing && this.validaUserNameRepetido(form.edtUserName.value)){
      alert('O usuário "' + form.edtUserName.value+ '" já existe.');
      return
    }
    if (this.validaEmailRepetido(form.edtEmail.value)){
      this.usuario.primeiroLogon = false;
    }

    //this.usuario.username = form.edtUserName.value + '@' + this.usuario.base;
    //this.usuario.nome = form.edtNomeCompleto.value;
    //this.usuario.email = form.edtEmail.value;
    this.usuario.tecnico = this.ehTecnico ? true : false;
    this.usuario.adm = this.ehAdmin ? true : false;

    this.result = true;
    this.close();
  }

  adminClick(){
    this.ehAdmin = !this.ehAdmin;
  }

  tecnicoClick(){
    this.ehTecnico = !this.ehTecnico;
  }

  public validaUserNameRepetido(username){
    let retorno = false;
    let usuarios = this.serviceDados.getUsuarios();
    usuarios.forEach((item) => {
      if (item.data.username == username && item.data.base == this.usuario.base)
        retorno = true;
    })
    return retorno;
  }

  public validaEmailRepetido(email){
    let retorno = false;
    let usuarios = this.serviceDados.getUsuarios();
    usuarios.forEach((item) => {
      if (item.data.email == email)
        retorno = true;
    })
    return retorno;
  }
}
