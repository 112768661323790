import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: "orderBy"})
export class OrderByPipe {
  transform(array: Array<string>, args: string): Array<string> {
    array.sort((a: any, b: any) => {
      let aValue = a[args].normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();
      let bValue = b[args].normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();

	    if ( aValue < bValue){
	    	return -1;
	    }else if( aValue > bValue){
	        return 1;
	    }else{
	    	return 0;
	    }
    });
    return array;
  }
}
