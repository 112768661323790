import { Component, OnInit } from '@angular/core';
import { DialogComponent, DialogService } from "../../../ng2-bootstrap-modal";
import { Estoque } from '../../../classes/estoque';

export interface ConfirmModel {
  title: string;
  message: string;
  itemEstoque: Estoque;
  tecList: any[];
  database: string;
}

@Component({
  selector: 'app-estoque-form-movi',
  templateUrl: './estoque-form-movi.component.html',
  styleUrls: ['./estoque-form-movi.component.css']
})
export class EstoqueFormMoviComponent extends DialogComponent<ConfirmModel, boolean> implements ConfirmModel, OnInit {
  title: string;
  message: string;
  itemEstoque: Estoque;
  tecList: any[];
  database: string;

  qtdTotalExterna = 0;

  constructor(dialogService: DialogService) {
    super(dialogService);
  }

  confirm(form) {
    let qtdTotalExterna = 0;
    let tecnicoRepetido = false;
    let tecnicoNotFound = false;
    this.itemEstoque.qtdExternaLista.forEach((item, index) => {
      qtdTotalExterna += item.qtdExterna;

      if (item.tecnico == '') {
        tecnicoNotFound = true;
      }

      this.itemEstoque.qtdExternaLista.forEach((item2, index2) => {
        if (index2 != index && item.tecnico == item2.tecnico) {
          tecnicoRepetido = true;
        }
      });
    });

    if (tecnicoRepetido) { alert('Técnico não pode estar repetido no estoque externo.'); return }

    if (tecnicoNotFound) { alert('Técnico não informado no estoque externo.'); return }

    if ((+form.edtQtdInterna.value + qtdTotalExterna) != this.itemEstoque.qtd + this.qtdTotalExterna){
      alert('A quantidade Externa e Interna somadas não pode ser diferente do total.');
      return
    }

    this.itemEstoque.qtd = +form.edtQtdInterna.value;
    this.itemEstoque.qtdExterna = +qtdTotalExterna;
    this.result = true;
    this.close();
  }

  cancelar(form){
    this.close();
  }

  ngOnInit() {
    this.qtdTotalExterna = 0;
    this.itemEstoque.qtdExternaLista.forEach((item) => {
      this.qtdTotalExterna += item.qtdExterna;      
    });
  }

  adicionarEstoqueTecnico(item) {
    this.itemEstoque.qtdExternaLista.push({qtdExterna: 0, tecnico: ""});
  }

}
