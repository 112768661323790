import { Component, OnInit } from '@angular/core';
import { DialogComponent, DialogService } from "../../../ng2-bootstrap-modal";
import * as firebaseApp from 'firebase/app';

export interface ConfirmModel {
  title: string;
  message: string;
  fireUser: firebaseApp.User;
}

@Component({
  selector: 'app-usuario-config',
  templateUrl: './usuario-config.component.html',
  styleUrls: ['./usuario-config.component.css']
})
export class UsuarioConfigComponent extends DialogComponent<ConfirmModel, boolean> implements ConfirmModel, OnInit {

  title: string;
  message: string;
  fireUser: firebaseApp.User;

  constructor(dialogService: DialogService) {
    super(dialogService);
  }

  ngOnInit() {
  }

  confirm(form) {

    if (form.edtSenhaNova.value) {
      if (form.edtSenhaAtual.value == ''){
        alert('Favor informar senha atual.');
      } else {
        const credential = firebaseApp.auth.EmailAuthProvider.credential(
            this.fireUser.email,
            form.edtSenhaAtual.value
        );
        this.fireUser.reauthenticateWithCredential(credential).then(() => {
          if (form.edtSenhaNova.value != form.edtSenhaNovaConfirm.value) {
            alert('A nova senha não é igual ao campo "Confirmar".');
            form.edtSenhaNovaConfirm.value = "";
          } else {
            this.fireUser.updatePassword(form.edtSenhaNova.value).then(() => {
              alert('Senha alterada com sucesso.');
              this.result = true;
              this.close();
            }).catch(function(error) {
              alert('Erro ao alterar a senha: ' + error);
            });
          }
        }).catch(function(error) {
          alert("Erro ao validar senha atual. Verifique os dados informados. \n" + error);
        });

      }
    } else {
      this.result = true;
      this.close();
    }
  }

}
