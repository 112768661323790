import { Component, OnInit } from '@angular/core';
import { DialogComponent, DialogService } from "../../../ng2-bootstrap-modal";
import { cliente } from '../../../classes/cliente';
import { CurrencyMaskModule } from "ng2-currency-mask";
import * as moment from 'moment';

export interface ConfirmModel {
  title: string;
  message: string;
  newCliente: cliente;
  isAdmin: boolean;
  isMaster: boolean;
  database: string;
}

@Component({
  selector: 'app-novo-cliente',
  templateUrl: './novo-cliente.component.html',
  styleUrls: ['./novo-cliente.component.css']
})
export class NovoClienteComponent extends DialogComponent<ConfirmModel, boolean> implements ConfirmModel, OnInit {

  title: string;
  message: string;
  newCliente: cliente;
  isAdmin: boolean;
  isMaster: boolean;
  database: string;
  ehContrato: boolean = false;
  ehContratoPlantao: boolean = false;
  tipoContrato: String = 'Mensal';
  tipoContratoPlantao: String = 'Mensal';
  habilitado: boolean = false;
  
  checklist:     boolean = false;
  backup:        boolean = false;
  firewall:      boolean = false;
  monitoramento: boolean = false;
  office365:     boolean = false;
  antivirus:     boolean = false;
  servidor:      boolean = false;
  dominio:       boolean = false;
  crashplan:     boolean = false;
  desenhoinfra:  boolean = false;

  public mask = [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]
  phoneNumberMask = ['(', /[1-9]/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  celNumberMask = ['(', /[1-9]/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  constructor(dialogService: DialogService) {
    super(dialogService);
  }

  ngOnInit() {
    this.ehContrato = this.newCliente.contrato;
    this.ehContratoPlantao = this.newCliente.contratoPlantao;
    this.tipoContrato = this.newCliente.tipoContrato ? this.newCliente.tipoContrato : 'Mensal';
    this.tipoContratoPlantao = this.newCliente.tipoContratoPlantao ? this.newCliente.tipoContratoPlantao : 'Mensal';
    this.habilitado = this.newCliente.ativo;
    this.checklist     = this.newCliente.checklist == undefined ? false : this.newCliente.checklist;
    this.backup        = this.newCliente.backup == undefined ? false : this.newCliente.backup;
    this.firewall      = this.newCliente.firewall == undefined ? false : this.newCliente.firewall;
    this.monitoramento = this.newCliente.monitoramento == undefined ? false : this.newCliente.monitoramento;
    this.office365     = this.newCliente.office365 == undefined ? false : this.newCliente.office365;
    this.antivirus     = this.newCliente.antivirus == undefined ? false : this.newCliente.antivirus;
    this.servidor      = this.newCliente.servidor == undefined ? false : this.newCliente.servidor;
    this.dominio       = this.newCliente.dominio == undefined ? false : this.newCliente.dominio;
    this.crashplan     = this.newCliente.crashplan == undefined ? false : this.newCliente.crashplan;
    this.desenhoinfra  = this.newCliente.desenhoinfra == undefined ? false : this.newCliente.desenhoinfra;
  }

  confirm(form) {
      // we set dialog result as true on click on confirm button,
      // then we can get dialog result from caller code

    if (this.newCliente.nomeFantasia == ''){
      alert('Nome fantasia é obrigatório');
    } else {
      if (isNaN(this.newCliente.valor)){
        this.newCliente.valor = 0;
      }
      if (this.tipoContrato && this.tipoContrato != 'Mensal' && !this.newCliente.inicioContrato) {
        alert('Necessário informar início do contrato.');
        return
      }

      if (form.optionsRadios) { this.newCliente.tipoPessoa = form.optionsRadios.value }
      this.newCliente.contrato = this.ehContrato;
      this.newCliente.tipoContrato = this.tipoContrato;
      this.newCliente.contratoPlantao = this.ehContratoPlantao == undefined ? false : this.ehContratoPlantao;
      this.newCliente.tipoContratoPlantao = this.tipoContratoPlantao;
      this.newCliente.ativo = this.habilitado;
      this.newCliente.checklist = this.checklist;
      this.newCliente.backup = this.backup;
      this.newCliente.firewall = this.firewall;
      this.newCliente.monitoramento = this.monitoramento;
      this.newCliente.office365 = this.office365;
      this.newCliente.antivirus = this.antivirus;
      this.newCliente.servidor = this.servidor;
      this.newCliente.dominio = this.dominio;
      this.newCliente.crashplan = this.crashplan;
      this.newCliente.desenhoinfra = this.desenhoinfra;

      this.result = true;
      this.close();
    }
  }

  public contratoClick(){
    this.ehContrato = !this.ehContrato;
    if (!this.ehContrato){
      this.newCliente.qtdHoras = 0;
    }
  }

  public contratoPlantaoClick(){
    this.ehContratoPlantao = !this.ehContratoPlantao;
    if (!this.ehContratoPlantao){
      this.newCliente.qtdHorasPlantao = 0;
    }
  }

  public enabledClick()       { this.habilitado = !this.habilitado; }
  public checklistClick()     { this.checklist = !this.checklist; }
  public backupClick()        { this.backup = !this.backup; }
  public firewallClick()      { this.firewall = !this.firewall; }
  public monitoramentoClick() { this.monitoramento = !this.monitoramento; }
  public office365Click()     { this.office365 = !this.office365; }
  public antivirusClick()     { this.antivirus = !this.antivirus; }
  public servidorClick()      { this.servidor = !this.servidor; }
  public dominioClick()       { this.dominio = !this.dominio; }
  public crashplanClick()     { this.crashplan = !this.crashplan; }
  public desenhoinfraClick()  { this.desenhoinfra = !this.desenhoinfra; }


  public imprimir(relatorio){
    let printContents, popupWin;
    printContents = relatorio.innerHTML;
    let pdfName = /*this.clienteData.nomeFantasia + " " +*/ moment().format('DD-MM HHmm');
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css">
          <title>${pdfName}</title>
          <style>
            //........Customized style.......
          </style>
        </head>
        <body onload="window.print();">
          ${printContents}
        </body>
      </html>`
    );
    popupWin.document.close();

  }

  upperCase() {
    if (this.database !== 'drrafael') {
      this.newCliente.nomeFantasia = this.newCliente.nomeFantasia.toUpperCase();
    }
  }

  camelCase() {
    if (this.database !== 'drrafael') {
      this.newCliente.contato = this.newCliente.contato.toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' ');

    }
  }

  phoneFormat() {

  }
}
