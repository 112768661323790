import { Component, OnInit, Inject } from '@angular/core';
import { AngularFireAuth } from 'angularfire2/auth';
import { Router } from '@angular/router';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import { DOCUMENT } from '@angular/common';

import { Observable } from 'rxjs/Observable';
import * as firebase from 'firebase/app';
import * as env from "../../../../environments/environment";

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css']
})
export class LoginPageComponent implements OnInit {
  user: Observable<firebase.User>;
  usuarioList: AngularFireList<any>;
  vInputUserName: any;
  ehPrimeiroAcesso: boolean = false;

  isGessnerUrl = false;
  isDataPushUrl = false;
  isDGUrl = false;
  isDrRafael = false;

  constructor(private afAuth: AngularFireAuth, private router: Router, public db: AngularFireDatabase,
              @Inject(DOCUMENT) private _document: HTMLDocument) {
    this.user = this.afAuth.authState;

    if (window.location.href.indexOf('dgsys') > -1 || window.location.href.indexOf('localhost') > -1) {
      this._document.getElementById('appFavicon').setAttribute('href', '../../../../assets/logo-dg.png');
    } else { 
      if (window.location.href.indexOf('datapush') > -1) {
        this._document.getElementById('appFavicon').setAttribute('href', '../../../../assets/logo-datapush.png');
      } else {
        this._document.getElementById('appFavicon').setAttribute('href', '../../../../favicon.png');
      }              
    }
  }

  ngOnInit() {
    this.isGessnerUrl = window.location.href == 'https://helpdesk.frigorificogessner.com.br/login';
    this.isDataPushUrl = window.location.href == 'https://helpdesk.datapush.com.br/login';
    this.isDGUrl = window.location.href == 'https://helpdesk.dgsys.com.br/login';
    this.isDrRafael = window.location.href == 'https://helpdesk.clinicadrrafaelevaristo.com.br/login';
  }

  getBaseURL(username) {
    let base;
    if (username) {
      base = username.substring(username.indexOf('@')+1, username.length);
    }
    if (!base || base == 'agb') {
      base = 'agb-computadores';
    }
    let url = env.environment.url_firebase.replace('###', base);
    return url;
  }

  form_login(form){
    if (this.ehPrimeiroAcesso){
      if (form.inputPassword.value != form.inputPasswordConfirm.value){
        alert('As senhas informadas não são iguais!');
        return
      }
    }

    this.vInputUserName = form.inputUserName;
    if (this.isGessnerUrl && form.inputUserName.value.indexOf('@') == -1){
      form.inputUserName.value = form.inputUserName.value + '@gessner';
    }
    let found = false;

    let dbRef = this.db.database.app.database(this.getBaseURL(form.inputUserName.value)).ref("/usuarios");
    let UserObservable = this.db.object(dbRef).snapshotChanges().map(action => {
         const data = action.payload.toJSON();
         return data;
       }).subscribe(result => {
           Object.keys(result).map(key=> {
              if (result[key].username.toLowerCase() == form.inputUserName.value.toLowerCase()) {
                if (!(this.isGessnerUrl && result[key].base != 'gessner')) {
                  if (result[key].ativo){
                    if (result[key].primeiroLogon){
                        this.criaUsuario(result[key], form.inputPassword, key);
                    } else {
                        if (this.ehPrimeiroAcesso) {
                            alert('Não é possível definir uma senha para este usuário.\nUsuário já cadastrado.');
                            found = true;
                            return
                        } else {
                            this.doLogin(result[key], form.inputPassword);
                        }
                    }
                  } else {
                    alert('Usuário desabilitado.');
                  }
                  found = true;
                }
              }
           });
           if (!found){
             alert('Usuário não cadastrado.');
           }

           UserObservable.unsubscribe();
    });

  }


  doLogin(user, pwd){
    this.afAuth.auth.signInWithEmailAndPassword(user.email, pwd.value)
                    .then(ok => {
                      localStorage.setItem("system-base", JSON.stringify({
                        "base": user.base
                      }));    
                      this.router.navigate([""]);
                      pwd.value = '';
                      this.vInputUserName = '';
                    }).catch(function(error) {
                      // Handle Errors here.
                      var errorCode = error.code;
                      var errorMessage = error.message;
                      switch (errorCode) {
                        case 'auth/wrong-password': {
                          alert('Senha inválida.');
                          return
                        }
                        case 'auth/invalid-email': {
                          alert('Usuário inválido.');
                          return
                        }
                        case 'auth/user-disabled': {
                          alert('Usuário desabilitado.');
                          return
                        }
                        case 'auth/user-not-found': {
                          alert('Usuário não encontrado.');
                          return
                        }
                        default: alert('Erro: ' + errorMessage);
                      }
                      console.log(error);
                    });

  }

  public emailTrocaSenha(inputUserName){
    if (inputUserName.value == ""){
      alert('Preencha o campo usuário e tente novamente.');
    } else {
      let found = false;
      //this.usuarioList = this.db.list("/prod/usuarios", ref => ref.orderByChild('username').equalTo(inputUserName.value));
      this.db.object("/usuarios").snapshotChanges().map(action => {
           const data = action.payload.toJSON();
           return data;
         }).subscribe(result => {
             Object.keys(result).map(key=> {
                if (result[key].username.toLowerCase() == inputUserName.value.toLowerCase()) {

                  this.afAuth.auth.sendPasswordResetEmail(result[key].email).then(function() {
                    alert('Foi enviado um e-mail para "' + result[key].email + '" com instruções para redefinir a sua senha.');
                  }).catch(function(error) {
                    alert('Não foi possível enviar e-mail, entre em contato com o administrador do sistema.');
                  });
                  found = true;
                }
             });
             if (!found){
               alert('Usuário não cadastrado.');
             }
      });
    }
    return false;
  }

  private criaUsuario(usuario, pwd, key){
    if (pwd.value.length < 6){
      alert('As senha deve conter pelo menos 6 caracteres.');
      return
    }

    let senha = window.prompt("Favor confirmar sua senha:", "");
    if (senha != null){
      if (senha == pwd.value){
        this.afAuth.auth.createUserWithEmailAndPassword(usuario.email, senha).then(user => {
            usuario.primeiroLogon = false;
            this.db.list("/usuarios").update(key, usuario);
            pwd.value = '';
            this.vInputUserName = '';
            this.router.navigate([""]);
        }, function(error) {
          var errorCode = error.code;
          var errorMessage = error.message;
          alert(errorMessage);
        });
      } else {
        alert('As senhas informadas não são iguais, tente novamente.');
      }
    }
  }

  public primeiroAcesso(form) {
    this.ehPrimeiroAcesso = !this.ehPrimeiroAcesso;
    form.inputPassword.value = '';
    form.inputUserName.value = '';
    return false;
  }

}
