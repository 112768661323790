import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from "@angular/common";
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BootstrapModalModule } from './ng2-bootstrap-modal';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';

import { AngularFireDatabase } from 'angularfire2/database';
import { AngularFireModule } from 'angularfire2/index';
import { AngularFireAuth } from 'angularfire2/auth';

import { FirebaseConfig } from './../environments/firebase.config';
import { routes } from './app.router';

import { AppComponent } from './app.component';

import { OsViewComponent } from './components/os-view/os-view.component';
import { DoneComponent } from './components/os-view/done/done.component';
import { ListaClientesComponent } from './components/cliente/lista-clientes.component';
import { HomeComponent } from './components/home/home.component';
import { NovoClienteComponent } from './components/dialogs/novo-cliente/novo-cliente.component';
import { OsFormComponent } from './components/dialogs/os-form/os-form.component';
import { LoginPageComponent } from './components/login/login-page/login-page.component';
import { UsuarioConfigComponent } from './components/dialogs/usuario-config/usuario-config.component';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { BancoSolucaoComponent } from './components/banco-solucao/banco-solucao.component';
import { SelecionaComponent } from './components/relatorios/seleciona/seleciona.component';
import { LogComponent } from './components/log/log.component';
import { ConfigComponent } from './components/config/config.component';
import { BottomNavComponent } from './components/home/bottom-nav/bottom-nav.component';
import { ClienteComponent } from './components/cliente/cliente.component';
import { InventarioClienteComponent } from './components/dialogs/inventario-cliente/inventario-cliente.component';
import { TextMaskModule } from 'angular2-text-mask';
import { MensagensComponent } from './components/dialogs/mensagens/mensagens.component';
import { LogDetailsComponent } from './components/log/log-details/log-details.component';
import { UltimoContatoComponent } from './components/dialogs/ultimo-contato/ultimo-contato.component';
import { UsuarioFormComponent } from './components/dialogs/usuario-form/usuario-form.component';
import { AlertComponent } from './components/dialogs/alert/alert.component';
import { EstoqueComponent } from './components/estoque/estoque.component';
import { EstoqueFormComponent } from './components/dialogs/estoque-form/estoque-form.component';
import { EstoqueFormSaidaComponent } from './components/dialogs/estoque-form-saida/estoque-form-saida.component';
import { EstoqueFormEntradaComponent } from './components/dialogs/estoque-form-entrada/estoque-form-entrada.component';
import { EstoqueFormMoviComponent } from './components/dialogs/estoque-form-movi/estoque-form-movi.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { OsViewMobileComponent } from './components/os-view/mobile/os-view-mobile/os-view-mobile.component';

import { OrderByPipe } from './components/pipes/order-by.pipe';
import { SearchFilterPipe } from './components/pipes/search-filter.pipe';
import { OrderOSPipe } from './components/pipes/orderOS.pipe';

import { LogService } from './components/log/log.service';
import { DadosBancoService } from './components/services/dados-banco.service';
import { ExcelService } from './components/services/export/excel.service';
import { SendEmailService } from './components/services/send-email/send-email.service';
import { AlertService } from './components/dialogs/alert/alert.service';
import { OsViewFunctionsService } from './components/os-view/service/os-view-functions.service';
import { SelecionaMobileComponent } from './components/relatorios/mobile/seleciona-mobile/seleciona-mobile.component';
import { PorClienteMobileComponent } from './components/relatorios/mobile/por-cliente-mobile/por-cliente-mobile.component';
import { TecnicoHorasMobileComponent } from './components/relatorios/mobile/tecnico-horas-mobile/tecnico-horas-mobile.component';
import { ConsolidadoMobileComponent } from './components/relatorios/mobile/consolidado-mobile/consolidado-mobile.component';
import { PopupMenuComponent } from './components/popup-menu/popup-menu.component';
import { MobileComponent } from './components/estoque/mobile/mobile.component';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { OrcamentoComponent } from './components/orcamento/orcamento.component';
import { CadastroComponent } from './components/orcamento/cadastro/cadastro.component';
import { TableModule } from 'primeng/table';
import { UserService } from './components/services/user/user.service';
import {LOCALE_ID} from '@angular/core';

@NgModule({
  declarations: [
    AppComponent,
    OsViewComponent,
    ListaClientesComponent,
    HomeComponent,
    NovoClienteComponent,
    OsFormComponent,
    LoginPageComponent,
    UsuarioConfigComponent,
    OrderByPipe,
    BancoSolucaoComponent,
    SearchFilterPipe,
    SelecionaComponent,
    LogComponent,
    ConfigComponent,
    BottomNavComponent,
    ClienteComponent,
    InventarioClienteComponent,
    OrderOSPipe,
    MensagensComponent,
    LogDetailsComponent,
    UltimoContatoComponent,
    UsuarioFormComponent,
    AlertComponent,
    EstoqueComponent,
    EstoqueFormComponent,
    EstoqueFormSaidaComponent,
    EstoqueFormEntradaComponent,
    EstoqueFormMoviComponent,
    DoneComponent,
    OsViewMobileComponent,
    SelecionaMobileComponent,
    PorClienteMobileComponent,
    TecnicoHorasMobileComponent,
    ConsolidadoMobileComponent,
    PopupMenuComponent,
    MobileComponent,
    OrcamentoComponent,
    CadastroComponent
  ],
  imports: [
    //BootstrapModalModule.forRoot({container:document.body}),
    BootstrapModalModule,
    AngularFireModule.initializeApp(FirebaseConfig),
    CommonModule,
    BrowserModule,
    FormsModule,
    RouterModule.forRoot(routes),
    CurrencyMaskModule,
    TextMaskModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    HttpModule,
    NgbModule.forRoot(),
    TableModule,
    HttpClientModule
  ],
  entryComponents: [
    OsFormComponent,
    NovoClienteComponent,
    UsuarioConfigComponent,
    InventarioClienteComponent,
    UltimoContatoComponent,
    UsuarioFormComponent,
    MensagensComponent,
    LogDetailsComponent,
    EstoqueFormComponent,
    EstoqueFormSaidaComponent,
    EstoqueFormEntradaComponent,
    EstoqueFormMoviComponent,
    CadastroComponent
  ],
  exports: [
    RouterModule
  ],
  providers: [
    AngularFireDatabase,
    AngularFireAuth,
    DadosBancoService,
    LogService,
    AlertService,
    ExcelService,
    SendEmailService,
    OsViewFunctionsService,
    UserService,
    {
      provide: LOCALE_ID,
      useValue: "pt-BR"
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
