import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from 'angularfire2/auth';
import { PopupMenuComponent } from '../../popup-menu/popup-menu.component';
import { AngularFireDatabase } from 'angularfire2/database';


@Component({
  selector: 'app-bottom-nav',
  templateUrl: './bottom-nav.component.html',
  styleUrls: ['./bottom-nav.component.css']
})
export class BottomNavComponent implements OnInit {
  @ViewChild(PopupMenuComponent) menu:PopupMenuComponent;
  isAdmin = false;
  isMaster = false;
  canShow = false;

  constructor(private router: Router, private afAuth: AngularFireAuth, public db: AngularFireDatabase) { 

    
    afAuth.authState.subscribe((user: firebase.User) => {
      this.db.object("/usuarios").snapshotChanges().map(action => {
           const data = action.payload.toJSON();
           return data;
         }).subscribe(result => {
           if (result) {
            Object.keys(result).map(key=> {
               if (user && result[key].email == user.email){
                 let base = JSON.parse(localStorage.getItem("system-base")).base;
                 if (result[key].base == base) {
                   this.isAdmin = result[key].adm;
                   this.isMaster = result[key].master;
                   this.canShow = true;
                 }  
               }
            });
           }
      });
    });
  }

  ngOnInit() {
  }

  public redirect(page){
    this.router.navigateByUrl('/' + page);
  }

  public logoff(){
    this.afAuth.auth.signOut();
  } 
 
  openMenu(e) {
    this.menu.open(e)
  }
 
  itemSelected(item:number) {
    console.log("Item", item)
  }

}
