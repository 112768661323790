export class Estoque {

    public codigo: number = 0;
    public desc: String = "";
    public qtd: number = 0;
    public qtdExterna: number = 0;
    public qtdExternaLista: { qtdExterna: number, tecnico: string }[]
    public valorCusto: number = 0.0;
    public valorVenda: number = 0.0;
    public valorCustoTotal: number = 0.0;
    public valorVendaTotal: number = 0.0;
    public dataAdicionado: String = '';
    public historicoEntrada: string = '';
    public historicoSaida: string = '';
    public qtdAlerta: number = 0;
    public unidade: string = '';
    public classFiscal: string = '';

    constructor() {
    }

}
