import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';

import { Subject } from 'rxjs/Subject';
import { User } from '../../classes/user';
import { AngularFireAuth } from 'angularfire2/auth';
import { DialogService } from "../../ng2-bootstrap-modal";
import { OsFormComponent } from '../dialogs/os-form/os-form.component';

import * as moment from 'moment';

import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-banco-solucao',
  templateUrl: './banco-solucao.component.html',
  styleUrls: ['./banco-solucao.component.css']
})
export class BancoSolucaoComponent implements OnInit {

  
  dataBase = 'prod';
  ordemServico: any[] = [];
  listaOSmostrar: any[] = [];
  usuAtivo: User;
  clientes: any[] = [];
  carregado = false;
  carregando = false;

  constructor(private afAuth: AngularFireAuth, private router: Router, public db: AngularFireDatabase,
              private dialogService: DialogService, private spinner: NgxSpinnerService) {

    afAuth.authState.subscribe((user: firebase.User) => {
      this.db.object("/usuarios").snapshotChanges().map(action => {
           const data = action.payload.toJSON();
           return data;
         }).subscribe(result => {
             Object.keys(result).map(key=> {
               if (user && result[key].email.toLowerCase() == user.email.toLowerCase()){
                 let base = JSON.parse(localStorage.getItem("system-base")).base;
                 if (result[key].base == base) {
                   this.usuAtivo = result[key];
                   this.dataBase = this.usuAtivo.base;
                 }  
               }
             });
      });
    });
  }

  ngOnInit() {
  }

  private carregaDados(){
    this.carregado = true;
    this.ordemServico = [];

    let subject = new Subject();
    
    let ObsCLI = this.db.object(this.dataBase + "/clientes").snapshotChanges().map(action => {
      const data = action.payload.toJSON();
      return data;
    }).subscribe(result => {
       this.clientes = [];
       if (result){
         Object.keys(result).map(key=> {
              this.clientes.push({ 'key': key, 'data':result[key], 'nome': result[key].nomeFantasia });
         });
       }

       let ObsOS = this.db.object(this.dataBase + "/ordemServico/abertas").snapshotChanges().map(action => {
            const data = action.payload.toJSON();
            return data;
        }).subscribe(result => {
            if (result){
              Object.keys(result).map(key=> {
                  this.ordemServico.push({ 'key': key, 'data':result[key], 'solucao': result[key].solucao, 'desc': result[key].descricao});
              });
            }


            let ObsOS2 = this.db.object(this.dataBase + "/ordemServico/concluidas").snapshotChanges().map(action => {
              const data = action.payload.toJSON();
              return data;
            }).subscribe(result => {
                if (result){
                  Object.keys(result).map(date=> {
                    Object.keys(result[date]).map(key => {
                      this.ordemServico.push({ 'key': key, 'data':result[date][key], 'solucao': result[date][key].solucao, 'desc': result[date][key].descricao});
                    });
                  });
                }
                ObsOS2.unsubscribe;

              // ORDENAR ordem serviço
              this.ordemServico.sort((a: any, b: any) => {
                let dataInicioA;
                let dataInicioB;

                dataInicioA = moment(a.data.dataInicio, "YYYY-MM-DDTHH:mm");
                dataInicioB = moment(b.data.dataInicio, "YYYY-MM-DDTHH:mm");

                if (dataInicioA.isSame(dataInicioB)){
                  return 1;
                }
                if (dataInicioA.isBefore(dataInicioB)){
                  return 1;
                }else if(dataInicioA.isAfter(dataInicioB)){
                    return -1;
                }else{
                  return 0;
                }
              });            

              subject.next();
            });          
            ObsOS.unsubscribe;
      });

      ObsCLI.unsubscribe;
    });       

    return subject;
  }

  public temSolucaoEmAtividade(os, value){
    let result = false;
    if (os.data.atividades){
      let atividades = JSON.parse(os.data.atividades);
      atividades.forEach((item) => {
        if (item.solucao.toLowerCase().indexOf(value.toLowerCase()) > -1){
          result = true;
        }
      });
    }
    return result;
  }

  public clickMostrar(input){
    this.carregando = true;
    if (!this.carregado){
      // AQUI deve MOSTRAR CARREGANDO
      this.spinner.show();
      this.carregaDados().subscribe(() => {        
        this.mostraResult(input);
        
        // AQUI deve ESCONDER CARREGANDO

      });
    } else {
      this.spinner.show();
      this.mostraResult(input);
    }    
  }

  public mostraResult(input){
    this.listaOSmostrar = [];

    let filterList = [];
    if (input.value[0] == "\"" && input.value[input.value.length-1] == "\"") {
      filterList.push(input.value.replace("\"", "").slice(0, -1));  
    } else {
      filterList = input.value.split(" ");
      filterList.forEach((item, index) => {
        if (item.length < 3) { 
          filterList.splice(index, 1);
        }
      });
    }

    
    //let copy = this.ordemServico.map(x => Object.assign({}, x));
    let listCount = filterList.length;
    if (listCount > 0){
      this.ordemServico.forEach((os, index) => {
        let countFounded = 0;
        
        filterList.forEach((filter) => {
          let clienteNome = this.getClienteNome(os.data.codCliente).toLowerCase();
          if (((os.desc && os.desc.toLowerCase().indexOf(filter.toLowerCase()) > -1) ||
            (os.solucao && os.solucao.toLowerCase().indexOf(filter.toLowerCase()) > -1) ||
            this.temSolucaoEmAtividade(os, filter) || clienteNome.indexOf(filter.toLowerCase()) > -1)){            
              countFounded++;
          }
        });
        
        if (listCount == countFounded) {
          this.listaOSmostrar.push(os);
        }
      });    
    }

    this.carregando = false;
    this.spinner.hide();
  }

  public redirect(page){
    this.router.navigateByUrl('/' + page);
  }

  public abreOS(item){
    let disposable = this.dialogService.addDialog(OsFormComponent, {
      title:'Visualizando OS',
      message:'Confirm message',
      itemOS: item.data,
      clienteList: this.clientes,
      modoTela: 2,
      tecList: [],
      usuAtivo: this.usuAtivo
    }).subscribe((isConfirmed)=>{
      if(isConfirmed) {
      }else{
      }
    });
  }

  public getClienteNome(codCliParam){
    let codCli = +codCliParam;
    let name = '';
    this.clientes.forEach((cliente, index) => {
      if (cliente.data.codCliente == codCli){
        name =  cliente.data.nomeFantasia;
      }
    });

    return name;
  }
}
