import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class OsViewFunctionsService {

  constructor() { }

  public getLocalDesc(codLocal){
    if (codLocal == 0){
      return "Interno";
    } else {
      return "Externo";
    }
  }

  public formataData(data){
    if (data != ''){
      return moment(data, 'YYYY-MM-DDTHH:mm').format('DD/MM/YYYY HH:mm');
    }else{
      return 'Sem vencimento';
    }
  }
  
  public getVencimentoColor(data){
    if (data != ''){
      if (moment(data, 'YYYY-MM-DDTHH:mm').isBefore(moment())){
        return '#ff9191'; //'#ed6574';
      } else {
        return '#fffcd1';//'#f2f796';
      }
    }
    return
  }

}
