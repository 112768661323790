import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-seleciona-mobile',
  templateUrl: './seleciona-mobile.component.html',
  styleUrls: ['./seleciona-mobile.component.css']
})
export class SelecionaMobileComponent implements OnInit {
  
  constructor(private router: Router) { }

  ngOnInit() {
  }

  gerarRelatorio(tipo) {
    if (tipo == 'atendimentocliente') {
      this.router.navigateByUrl('/relPorClienteMobile');
    } else { 
      if (tipo == 'consolidado') {
        this.router.navigateByUrl('/relConsolidado');
      } else { 
        if (tipo == 'tecnicohoras') {
          this.router.navigateByUrl('/relTecHoras');
        }  
      }
    }
  }

}
