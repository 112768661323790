import { Component, OnInit, HostBinding, HostListener, Input } from '@angular/core';
 
@Component({
  selector: 'app-popup-menu',
  templateUrl: './popup-menu.component.html',
  styleUrls: ['./popup-menu.component.css']
})
export class PopupMenuComponent implements OnInit {
  @HostBinding("style.top") y = "0px"
  @HostBinding("style.left") x = "0px"
  @HostBinding("style.visibility") visibility = "hidden"
  @Input() @HostBinding("style.width") width = "150px"
 
  constructor() { }
 
  ngOnInit() {
  }
 
  open(e:MouseEvent) {
    this.x = `${window.innerWidth-150}px`
    this.y = `${window.innerHeight-190}px`
 
    if (this.visibility == "visible") {
      this.visibility = "hidden"
    } else {
      this.visibility = "visible"
    }
 
    e.stopPropagation()
  }
 
  close() {
    this.visibility = "hidden"
  }
 
  @HostListener('document:click')
  public onDocumentClick() {
    if (this.visibility === "visible") {
      this.close()
    }
  }
}