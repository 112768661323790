import { Component, OnInit } from '@angular/core';
import { DialogComponent, DialogService } from "../../../ng2-bootstrap-modal";

export interface ConfirmModel {
  title: string;
  message: string;
  confirmacao: Boolean;
}

@Component({
  selector: 'app-mensagens',
  templateUrl: './mensagens.component.html',
  styleUrls: ['./mensagens.component.css']
})
export class MensagensComponent extends DialogComponent<ConfirmModel, boolean> implements ConfirmModel, OnInit {

  title: string;
  message: string;
  confirmacao: Boolean;

  constructor(dialogService: DialogService) {
    super(dialogService);
  }

  ngOnInit() {
  }


  confirm(form) {
    this.result = true;
    this.close();
  }

}
