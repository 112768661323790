import { Component, OnInit } from '@angular/core';

import { AngularFireDatabase } from 'angularfire2/database';
import { AngularFireAuth } from 'angularfire2/auth';
import * as moment from 'moment';

import { User } from '../../../../classes/user';
import { DadosBancoService } from '../../../services/dados-banco.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as env from "../../../../../environments/environment";

@Component({
  selector: 'app-por-cliente-mobile',
  templateUrl: './por-cliente-mobile.component.html',
  styleUrls: ['./por-cliente-mobile.component.css']
})
export class PorClienteMobileComponent implements OnInit {
  dataBase = '';
  usuAtivo: User;
  clientes: any[] = [];
  showingReport = false;
  clienteVazio = false;
  semDados = false;
  listaRelatorioFatura: {'mes': String, 'esforco': number, 'valorTotal': number, 'valorTotalFixos': number}[];
  listaOrdemServico = [];

  constructor(private afAuth: AngularFireAuth, public db: AngularFireDatabase, public serviceDados: DadosBancoService,
              private spinner: NgxSpinnerService) { 

    afAuth.authState.subscribe((user: firebase.User) => {
      let dbRef = this.db.database.app.database(this.getBaseURL()).ref(`/usuarios`);
      this.db.object(dbRef).snapshotChanges().map(action => {
           const data = action.payload.toJSON();
           return data;
         }).subscribe(result => {
            Object.keys(result).map(key=> {
              if (user && result[key].email.toLowerCase() == user.email.toLowerCase()){
                let base = JSON.parse(localStorage.getItem("system-base")).base;
                if (result[key].base == base) {
                  this.usuAtivo = result[key];
                  this.dataBase = this.usuAtivo.base;
                }  
              }
            });
            this.carregaCliente();
      });
    });
  }

  ngOnInit() {
  }

  getBaseURL(username = undefined) {
    let base;
    if (username) {
      base = username.substring(username.indexOf('@')+1, username.length);
    } else {
      base = JSON.parse(localStorage.getItem("system-base")).base;
    }

    if (base == 'agb') {
      base = 'agb-computadores';
    }
    
    let url = env.environment.url_firebase.replace('###', base);
    return url;
  }

  private carregaCliente(){
    let dbRef = this.db.database.app.database(this.getBaseURL()).ref(`/dados/clientes`);
    this.db.object(dbRef).snapshotChanges().map(action => {
         const data = action.payload.toJSON();
         return data;
       }).subscribe(result => {
          this.clientes = [];
          if (result){
            Object.keys(result).map(key=> {
                 this.clientes.push({ 'key': key, 'data':result[key], 'nome': result[key].nomeFantasia });
            });
          }
    });
  }  

  resetStatus() {
    this.showingReport = false;
  }

  public getClienteCodigo(lista, nome){
    let codCliente = 0;
    lista.forEach((cliente, index) => {
      if (cliente.data.nomeFantasia.toLowerCase() == nome.toLowerCase()){
        codCliente =  cliente.data.codCliente;
      }
    });
    return codCliente;
  }

  public getClienteDataPorCodigo(codigo){
    let iCliente;
    this.clientes.forEach((cliente, index) => {
      if (cliente.data.codCliente == codigo){
        iCliente =  cliente;
      }
    });
    return iCliente;
  }

  public formataData(data){
    if (data.length > 10){
      return moment(data, 'YYYY-MM-DDTHH:mm').format('DD/MM/YYYY');
    } else {
      if (data.indexOf('-') > -1){
        return moment(data, 'YYYY-MM-DD').format('DD/MM/YYYY');
      }
      return data;
    }
  }

  getDataAtendimento(itemOS){
    if (itemOS.listaEsforco && JSON.parse(itemOS.listaEsforco).length == 1) {
      return JSON.parse(itemOS.listaEsforco)[0].data;
    } else {
      if (itemOS.dataEsforco) {
        return itemOS.dataEsforco;
      } else {
        return itemOS.dataFim;
      }
    }
  }

  public deepIndexOf(arr, obj) {
    return arr.findIndex(function (cur) {
      return Object.keys(obj).every(function (key) {
        return obj[key] === cur[key];
      });
    });
  }

  private situacaoComValor(situacao){
    let situacoes = this.serviceDados.getSituacoes();
    for (let item of situacoes){
      if (situacao.toLowerCase() == item.situacao.toLowerCase()){
        return item.valor;
      }
    }
    return undefined;
  }

  private minutosParaHoras(minParams){
    let horas = Math.floor(minParams / 60);
    let minutes = minParams % 60;

    return (horas < 10 ? "0" + horas : horas) + ":" +
           (minutes < 10 ? "0" + minutes : minutes);
  }
  
  public getLocalDesc(codLocal){
    if (codLocal == 0){
      return "Interno";
    } else {
      return "Externo";
    }
  }

  mostrarRelatorio(form) {
    this.semDados = false;
    if (form.selCliente.value == 'Selecione o cliente') {
      this.clienteVazio = true;
    } else {
      this.clienteVazio = false;
      this.spinner.show();

      let dbRef = this.db.database.app.database(this.getBaseURL()).ref(`/dados/ordemServico/concluidas`);
      let OSObservable = this.db.object(dbRef).snapshotChanges().map(action => {
        const data = action.payload.toJSON();
        return data;
      }).subscribe(result => {
        let codCli = this.getClienteCodigo(this.clientes, form.selCliente.value);
        let dataIni = moment().date(1);
        let dataFim = moment().add('months', 1).date(1).subtract('days', 1);
        dataFim.hours(23);
        dataFim.minutes(59);

        this.listaOrdemServico = [];
        Object.keys(result).map(date=> {
          Object.keys(result[date]).map(key => {
            if (result[date][key].codCliente == codCli){

              // CALCULAR AQUI AS HORAS DE PLANTA DO CLIENTE SELECIONADO 
              // POR MÊS 
              // Quantidade de horas e Valor total
              /*
              if (result[date][key].esforcoNoturno && result[date][key].esforcoNoturno > 0) {
                let mes = moment(result[date][key].dataFim, "YYYY-MM-DDTHH:mm").month();
                valorNoturnoPorMes[mes] += result[date][key].esforcoNoturno * (result[date][key].clienteValorHrNot / 60);
                horasNoturnoPorMes[mes] += result[date][key].esforcoNoturno;
              }                
              */
              

              // -------------------------
              let dataOS = moment(result[date][key].dataFim, 'YYYY-MM-DDTHH:mm');
              if (dataOS.isBetween(dataIni, dataFim)) {
                this.listaOrdemServico.push({ 'key': key, 'data':result[date][key], 'dataAtendimento': this.formataData(this.getDataAtendimento(result[date][key])) });
              }
            }
          })
        });

        /*if (geraPDF){
          var xRelatorioPorCliente = new PorClienteService(this.serviceDados, listaOrdemServico, this.getClienteDataPorCodigo(codCli), 0);
          if (form.edtDescontoReal && form.edtDescontoReal.value){
            xRelatorioPorCliente.descontoReal = form.edtDescontoReal.value;
          }
          if (form.edtDescontoPerc && form.edtDescontoPerc.value){
            xRelatorioPorCliente.descontoPerc = form.edtDescontoPerc.value;
          }

          xRelatorioPorCliente.geraPDF('Relatório Atendimentos');
        } else {*/
          if (this.listaOrdemServico.length == 0) {
            this.semDados = true;
          }
          this.listaRelatorioFatura = [];
          this.listaOrdemServico.forEach((item) => {
              let dataOS = moment(item.data.dataFim, 'YYYY-MM-DDTHH:mm');
              let index = this.deepIndexOf(this.listaRelatorioFatura, {'mes': dataOS.format('MM/YYYY')});
              let valorFixo = this.situacaoComValor(item.data.situacao);
              if (index > -1){

                  if (valorFixo != 0){
                    this.listaRelatorioFatura[index].esforco += item.data.esforco;
                    if (valorFixo){
                      this.listaRelatorioFatura[index].valorTotalFixos += valorFixo;
                    } else {
                      if (this.listaRelatorioFatura[index].esforco > (item.data.clienteQtdHoras*60)){
                        let diff = this.listaRelatorioFatura[index].esforco - (item.data.clienteQtdHoras*60);
                        this.listaRelatorioFatura[index].valorTotal = (diff * (item.data.clienteValorHr/60));
                      }
                    }
                  }
              } else {
                  let esforco = 0;
                  if (valorFixo != 0){
                    esforco = item.data.esforco;
                  }
                  this.listaRelatorioFatura.push({'mes': dataOS.format('MM/YYYY'), 'esforco': esforco, 'valorTotal': 0, 'valorTotalFixos': 0});
              }
          })
        //}

        if (this.listaRelatorioFatura.length > 0) {
          this.showingReport = true;
        }
        this.spinner.hide();
        OSObservable.unsubscribe();
      });      
    }    
  }

}
