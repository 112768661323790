import { Component, OnInit, Input } from '@angular/core';
import { OsViewFunctionsService } from './../../service/os-view-functions.service';
import { OrderOSPipe } from '../../../pipes/orderOS.pipe';

@Component({
  selector: 'app-os-view-mobile',
  templateUrl: './os-view-mobile.component.html',
  styleUrls: ['./os-view-mobile.component.css']
})
export class OsViewMobileComponent implements OnInit {
  @Input('ordemServicoFiltrado') ordemServicoFiltrado: any[];

  openedOS = true;
  constructor(public functions: OsViewFunctionsService) { }

  ngOnInit() {
  }

  selectOpenedOS(opened) {
    this.openedOS = opened;
  }

  public mostrarOS(ordem){
    ordem.mobileVisivel = !ordem.mobileVisivel;
  }

}
