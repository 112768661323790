import { Component, OnInit } from '@angular/core';

import { AngularFireDatabase } from 'angularfire2/database';
import { AngularFireAuth } from 'angularfire2/auth';
import * as moment from 'moment';

import { User } from '../../../../classes/user';
import { DadosBancoService } from '../../../services/dados-banco.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { PorClienteService } from '../../services/por-cliente.service';

import * as env from "../../../../../environments/environment";
import * as firebase from 'firebase';

@Component({
  selector: 'app-consolidado-mobile',
  templateUrl: './consolidado-mobile.component.html',
  styleUrls: ['./consolidado-mobile.component.css']
})
export class ConsolidadoMobileComponent implements OnInit {
  dataBase = '';
  usuAtivo: User;
  clientes: any[] = [];

  mesesVigencia: any[] = [];
  listaOrdemServicoGeral = [];
  totalHorasFaturaMensal = 0;
  totalValorFaturaMensal = 0;
  valorTotalRelatorioConsolidade: number = 0.0;
  esforcoTotalRelatorioConsolidade: number = 0;
  loaded = false;
  showingReport = false;

  valorMinimo: number = 75.0;
  valorPlantao: number = 120.0;
  valorMinPlantao: number = 75.0;

  constructor(private afAuth: AngularFireAuth, public db: AngularFireDatabase, public serviceDados: DadosBancoService,
              private spinner: NgxSpinnerService) { 

    let hoje = moment();
    for (let i=0; i<13; i++){
        this.mesesVigencia.push({'vigencia': hoje.format('MM/YYYY'), 'desc': hoje.format('MMMM/YYYY')})
        hoje.month(hoje.month()-1);
    }
    //this.mesesVigencia.reverse();
    
    afAuth.authState.subscribe((user: firebase.User) => {
      let dbRef = this.db.database.app.database(this.getBaseURL()).ref(`/usuarios`);
      this.db.object(dbRef).snapshotChanges().map(action => {
           const data = action.payload.toJSON();
           return data;
         }).subscribe(result => {
            Object.keys(result).map(key=> {
              if (user && result[key].email.toLowerCase() == user.email.toLowerCase()){
                let base = JSON.parse(localStorage.getItem("system-base")).base;
                if (result[key].base == base) {
                  this.usuAtivo = result[key];
                  this.dataBase = this.usuAtivo.base;
                }  
              }
            });
            this.carregaCliente();
            this.carregaParamsGlobais();
      });
    });
  }

  ngOnInit() {
  }

  getBaseURL(username = undefined) {
    let base;
    if (username) {
      base = username.substring(username.indexOf('@')+1, username.length);
    } else {
      base = this.dataBase;
    }

    if (base == 'agb') {
      base = 'agb-computadores';
    }
    
    let url = env.environment.url_firebase.replace('###', base);
    return url;
  }

  private carregaCliente(){
    let dbRef = this.db.database.app.database(this.getBaseURL()).ref(`/dados/clientes`);
    this.db.object(dbRef).snapshotChanges().map(action => {
         const data = action.payload.toJSON();
         return data;
       }).subscribe(result => {
          this.clientes = [];
          if (result){
            Object.keys(result).map(key=> {
                 this.clientes.push({ 'key': key, 'data':result[key], 'nome': result[key].nomeFantasia });
            });
          }          
    });
  }  

  carregaParamsGlobais() {
    firebase.database().ref("dados/params-globais/valorMinimo/").once('value').then((snapshot) => {
      this.valorMinimo = snapshot.val().value;
    });  
    firebase.database().ref("dados/params-globais/valorPlantao/").once('value').then((snapshot) => {
      this.valorPlantao = snapshot.val().value;
    });    
    firebase.database().ref("dados/params-globais/valorMinPlantao/").once('value').then((snapshot) => {
      this.valorMinPlantao = snapshot.val().value;
    });   
  }


  public formataData(data){
    if (data.length > 10){
      return moment(data, 'YYYY-MM-DDTHH:mm').format('DD/MM/YYYY');
    } else {
      if (data.indexOf('-') > -1){
        return moment(data, 'YYYY-MM-DD').format('DD/MM/YYYY');
      }
      return data;
    }
  }

  getDataAtendimento(itemOS){
    if (itemOS.listaEsforco && JSON.parse(itemOS.listaEsforco).length == 1) {
      return JSON.parse(itemOS.listaEsforco)[0].data;
    } else {
      if (itemOS.dataEsforco) {
        return itemOS.dataEsforco;
      } else {
        return itemOS.dataFim;
      }
    }
  }

  public getClienteDataPorCodigo(codigo){
    let iCliente;
    this.clientes.forEach((cliente, index) => {
      if (cliente.data.codCliente == codigo){
        iCliente =  cliente;
      }
    });
    return iCliente;
  }

  private minutosParaHoras(minParams){
    let horas = Math.floor(minParams / 60);
    let minutes = minParams % 60;

    return (horas < 10 ? "0" + horas : horas) + ":" +
           (minutes < 10 ? "0" + minutes : minutes);
  }

  public changeMonth() {
    this.showingReport = false;
  }

  private situacaoComValor(situacao){
    let situacoes = this.serviceDados.getSituacoes();
    for (let item of situacoes){
      if (situacao.toLowerCase() == item.situacao.toLowerCase()){
        return item.valor;
      }
    }
    return undefined;
  }

  public relatorioConsolidado(form){

    if (form.selVigencia.value == 0){
      alert('Selecione a vigência da fatura.');
      return
    }

    this.loaded = false;
    this.showingReport = false;
    this.spinner.show();
    let dbRef = this.db.database.app.database(this.getBaseURL()).ref(`/dados/ordemServico/concluidas`);
    let OSObservable = this.db.object(dbRef).snapshotChanges().map(action => {
         const data = action.payload.toJSON();
         return data;
       }).subscribe(result => {
        let dataIni = moment(form.selVigencia.value, 'MM/YYYY').date(1);
        let dataFim = moment(form.selVigencia.value, 'MM/YYYY').add('months', 1).date(1).subtract('days', 1);
         dataFim.hours(23);
         dataFim.minutes(59);

         var listaOrdemServicoTodosClientes = [];
         var listaClientes = [];
         Object.keys(result).map(date=> {
           Object.keys(result[date]).map(key => {
              let dataOS = moment(result[date][key].dataFim, 'YYYY-MM-DDTHH:mm');

              if (dataOS.isBetween(dataIni, dataFim)) {
                listaOrdemServicoTodosClientes.push({ 'key': key, 'data':result[date][key], codCliente: result[date][key].codCliente, 'dataAtendimento': this.formataData(this.getDataAtendimento(result[date][key])) });
                if (listaClientes.indexOf(result[date][key].codCliente) == -1){
                  listaClientes.push(result[date][key].codCliente);
                }
              }
           })
         });

         this.listaOrdemServicoGeral = [];
         this.totalHorasFaturaMensal = 0;
         this.totalValorFaturaMensal = 0;

         let totalHorasPlano = 0;
         let totalHorasConsumidas = 0;
         let totalHorasPlanoPlantao = 0;
         let totalHorasConsumidasPlantao = 0;

         listaClientes.forEach((codCliente, index) => {
           var listaOrdemServicoPorCliente = [];
           let xClienteData = this.getClienteDataPorCodigo(codCliente);
           listaOrdemServicoTodosClientes.forEach((itemOS, index) => {
             if (itemOS.codCliente == codCliente){
              let dataOS = moment(itemOS.data.dataFim, 'YYYY-MM-DDTHH:mm');
              if (dataOS.isBetween(dataIni, dataFim)) {
                listaOrdemServicoPorCliente.push(itemOS);
              }  
                

              // --------
              let values = this.calcHorasContrato(xClienteData.data, moment().format('YYYY-MM-DDTHH:mm'), dataFim, itemOS.data);
              totalHorasPlano = values.totalHorasPlano;
              totalHorasConsumidas = values.totalHorasConsumidas;

              let valuesPlantao = this.calcHorasContratoPlantao(xClienteData.data, moment().format('YYYY-MM-DDTHH:mm'), dataFim, itemOS.data);
              totalHorasPlano = valuesPlantao.totalHorasPlano;
              totalHorasConsumidas = valuesPlantao.totalHorasConsumidas;
              /*
              if (xClienteData.data.contrato && xClienteData.data.tipoContrato && xClienteData.data.tipoContrato != 'Mensal') {
                let mesesDesdeInicio = dataFim.diff(moment(xClienteData.data.inicioContrato, 'YYYY-MM-DD'), 'months')+1;
                let mesesInicioContrato = 0;
                let iniContrato;
                let fimContrato;
                if (xClienteData.data.tipoContrato == 'Semestral') {                    
                    mesesInicioContrato = mesesDesdeInicio % 6;
                    totalHorasPlano = xClienteData.data.qtdHoras * 6;

                    iniContrato = moment().subtract(mesesInicioContrato-1, 'months');
                    fimContrato = moment(iniContrato).add(6, 'months');           
                } else {
                  if (xClienteData.data.tipoContrato == 'Trimestral') {
                    mesesInicioContrato = mesesDesdeInicio % 3;
                    totalHorasPlano = xClienteData.data.qtdHoras * 3;

                    iniContrato = moment().subtract(mesesInicioContrato-1, 'months');
                    fimContrato = moment(iniContrato).add(3, 'months');           
                  }                      
                }
                
                
                iniContrato.date(1);
                fimContrato = fimContrato.endOf('month');
                //let iniContrato = moment(itemOS.clienteData.inicioContrato, 'YYYY-MM-DD');
                //iniContrato.add(mesesInicioContrato, 'months');
                let dataOSFim = moment(itemOS.data.dataFim, 'YYYY-MM-DDTHH:mm');

                if (dataOSFim.isBetween(iniContrato, fimContrato)) {
                  let valorFixo = this.situacaoComValor(itemOS.data.situacao);
                  if (!valorFixo && valorFixo != 0 && !itemOS.data.isentar){
                    totalHorasConsumidas += itemOS.data.esforco;  
                    //horasContratoPorMes[dataOSFim.months()] = itemOS.data.clienteQtdHoras;
                  }                   
                }
              }     */
              // --------   
            }
           });

           if (xClienteData) {

              var xRelatorioPorCliente = new PorClienteService(this.serviceDados, listaOrdemServicoPorCliente, xClienteData, 0, 
                                                               this.valorMinimo, this.valorPlantao, this.valorMinPlantao, 
                                                               this.getDadosPlano(xClienteData.data, totalHorasPlano, totalHorasConsumidas),
                                                               this.dataBase, this.getDadosPlanoPlantao(xClienteData.data, totalHorasPlanoPlantao, totalHorasConsumidasPlantao));
              
              var xValorTotalPorCliente = 0;
              var xHorasTotalPorCliente = 0;
              xRelatorioPorCliente.listaDadosRelatorio.forEach((item) => {
                xValorTotalPorCliente += item.valorTotalTipo;
                xHorasTotalPorCliente += item.esforcoTotalTipo;
              });
  
              if (xValorTotalPorCliente < this.valorMinimo && xValorTotalPorCliente > 0 && !xClienteData.data.contrato) {
                xValorTotalPorCliente = this.valorMinimo;
              }
  
              let xAuxHorasTotalPorCliente = 0;
                   
              if (xHorasTotalPorCliente > (xClienteData.data.qtdHoras * 60)) {
                  xAuxHorasTotalPorCliente = xHorasTotalPorCliente - (xClienteData.data.qtdHoras * 60);
              } else {
                if (xHorasTotalPorCliente === xClienteData.qtdHoras) {
                  xAuxHorasTotalPorCliente = 0;
                }
              }

              this.totalHorasFaturaMensal += xAuxHorasTotalPorCliente;
              this.totalValorFaturaMensal += xValorTotalPorCliente;
              this.listaOrdemServicoGeral.push({obj: xRelatorioPorCliente, valorTotal: xValorTotalPorCliente, esforcoTotal: xHorasTotalPorCliente}); 
           }
         });

         this.listaOrdemServicoGeral.sort((n1,n2) => {
           if (n1.obj.clienteData().nomeFantasia.toLowerCase() > n2.obj.clienteData().nomeFantasia.toLowerCase()) {
             return 1
           } else {
             if (n1.obj.clienteData().nomeFantasia.toLowerCase() < n2.obj.clienteData().nomeFantasia.toLowerCase()) {
              return -1
            } else {
              return 0;
            }
           }
         });
         
         this.dadosConsolidados();
         this.loaded = true;
         this.spinner.hide();
         if (this.listaOrdemServicoGeral.length > 0) {
          this.showingReport = true;
         }
         OSObservable.unsubscribe();
       });
  }

  public dadosConsolidados(){
    this.valorTotalRelatorioConsolidade = 0;
    this.esforcoTotalRelatorioConsolidade = 0;
    this.listaOrdemServicoGeral.forEach((item) => {
      this.valorTotalRelatorioConsolidade += item.valorTotal;
      this.esforcoTotalRelatorioConsolidade += item.esforcoTotal;
    });
  }  



  // -----------------------------------------------
  // Calcul horas de contrato 
  // -----------------------------------------------
  public calcHorasContrato(dataCli, dataFimValue, dataFimMoment, OSData) {
    let result = {
      totalHorasPlano: 0, 
      totalHorasConsumidas: 0
    }
    if (dataCli.contrato && dataCli.tipoContrato && dataCli.tipoContrato != 'Mensal') {
      let dataFimAux = moment(dataFimValue, 'YYYY-MM-DDTHH:mm');
      dataFimAux.hours(23);
      dataFimAux.minutes(59);

      let mesesDesdeInicio = dataFimMoment.diff(moment(dataCli.inicioContrato, 'YYYY-MM-DD'), 'months')+1;
      let mesesInicioContrato = 0;
      let iniContrato;
      let fimContrato;
      if (dataCli.tipoContrato == 'Semestral') {                    
          mesesInicioContrato = mesesDesdeInicio % 6;
          result.totalHorasPlano = dataCli.qtdHoras * 6;

          iniContrato = dataFimAux.subtract(mesesInicioContrato-1, 'months');
          fimContrato = moment(iniContrato).add(6, 'months');                      
      } else {
        if (dataCli.tipoContrato == 'Trimestral') {
          mesesInicioContrato = mesesDesdeInicio % 3;
          result.totalHorasPlano = dataCli.qtdHoras * 3;

          iniContrato = dataFimAux.subtract(mesesInicioContrato-1, 'months');
          fimContrato = moment(iniContrato).add(3, 'months');
        }                      
      }
      
      iniContrato.date(1);
      fimContrato = fimContrato.endOf('month');
      let dataOSFim = moment(OSData.dataFim, 'YYYY-MM-DDTHH:mm');

      if (dataOSFim.isBetween(iniContrato, fimContrato)) {
        let valorFixo = this.situacaoComValor(OSData.situacao);
        if (!valorFixo && valorFixo != 0 && !OSData.isentar){
          result.totalHorasConsumidas += OSData.esforco;  
        }                   
      }
    }

    return result;
  }

  // -----------------------------------------------
  // Calcul horas de contrato plantão
  // -----------------------------------------------
  public calcHorasContratoPlantao(dataCli, dataFimValue, dataFimMoment, OSData) {

    let result = {
      totalHorasPlano: 0, 
      totalHorasConsumidas: 0
    }
    if (dataCli.contratoPlantao && dataCli.tipoContratoPlantao && dataCli.tipoContratoPlantao != 'Mensal') {
      let dataFimAux = moment(dataFimValue, 'YYYY-MM-DDTHH:mm');
      dataFimAux.hours(23);
      dataFimAux.minutes(59);

      let mesesDesdeInicio = dataFimMoment.diff(moment(dataCli.inicioContratoPlantao, 'YYYY-MM-DD'), 'months')+1;
      let mesesInicioContrato = 0;
      let iniContrato;
      let fimContrato;
      if (dataCli.tipoContratoPlantao == 'Semestral') {                    
          mesesInicioContrato = mesesDesdeInicio % 6;
          result.totalHorasPlano = dataCli.qtdHorasPlantao * 6;

          iniContrato = dataFimAux.subtract(mesesInicioContrato-1, 'months');
          fimContrato = moment(iniContrato).add(6, 'months');                      
      } else {
        if (dataCli.tipoContratoPlantao == 'Trimestral') {
          mesesInicioContrato = mesesDesdeInicio % 3;
          result.totalHorasPlano = dataCli.qtdHorasPlantao * 3;

          iniContrato = dataFimAux.subtract(mesesInicioContrato-1, 'months');
          fimContrato = moment(iniContrato).add(3, 'months');
        }                      
      }
      
      iniContrato.date(1);
      fimContrato = fimContrato.endOf('month');
      let dataOSFim = moment(OSData.dataFim, 'YYYY-MM-DDTHH:mm');

      if (dataOSFim.isBetween(iniContrato, fimContrato)) {
        let valorFixo = this.situacaoComValor(OSData.situacao);
        if (!valorFixo && valorFixo != 0 && !OSData.isentar){
          result.totalHorasConsumidas += OSData.esforco;  
        }                   
      }
    }
    return result;
  }

  public getDadosPlano(dataCli, totalHorasPlano, totalHorasConsumidas) {
    let saldoHoras = 0;
    let dadosPlano = undefined;

    if (totalHorasPlano > 0) {
      saldoHoras = (totalHorasPlano*60) - totalHorasConsumidas;
      if (saldoHoras < 0) {
        saldoHoras = 0;
      }
      dadosPlano = {saldoHorasContrato: saldoHoras, totalHorasConsumidas: totalHorasConsumidas, tipoContrato: dataCli.tipoContrato, totalHoras: totalHorasPlano}
    }

    return dadosPlano;
  }

  public getDadosPlanoPlantao(dataCli, totalHorasPlanoPlantao, totalHorasConsumidasPlantao) {
    let saldoHorasPlantao = 0;
    let dadosPlanoPlantao = undefined;

    if (totalHorasPlanoPlantao > 0) {
      saldoHorasPlantao = (totalHorasPlanoPlantao*60) - totalHorasConsumidasPlantao;
      if (saldoHorasPlantao < 0) {
        saldoHorasPlantao = 0;
      }
      dadosPlanoPlantao = {saldoHorasContrato: saldoHorasPlantao, totalHorasConsumidas: totalHorasConsumidasPlantao, tipoContrato: dataCli.tipoContratoPlantao, totalHoras: totalHorasPlanoPlantao}
    } else {
      if (dataCli.tipoContratoPlantao == 'Mensal') {
        saldoHorasPlantao = -1;
      }  
    }    

    return dadosPlanoPlantao;
  }


}
