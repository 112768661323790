import { Component, OnInit } from '@angular/core';
import { DadosBancoService } from '../../services/dados-banco.service';
import { DialogComponent, DialogService } from "../../../ng2-bootstrap-modal";

export interface ConfirmModel {
  title: string;
  message: string;
  log: string;
}

@Component({
  selector: 'app-log-details',
  templateUrl: './log-details.component.html',
  styleUrls: ['./log-details.component.css']
})
export class LogDetailsComponent extends DialogComponent<ConfirmModel, boolean> implements ConfirmModel, OnInit {
  title: string;
  message: string;
  log: any;

  listaLogs = [];

  constructor(dialogService: DialogService) {
    super(dialogService);
  }

  ngOnInit() {
    let logs = '';
    this.listaLogs = JSON.parse(this.log.data.registros);
    this.listaLogs.splice(this.listaLogs.length-1, 1);

    for (let i =0; i < JSON.parse(this.log.data.registros).length; i++ ){
      if (JSON.parse(this.log.data.registros)[i][0] != 'datalog') {
        let item = JSON.parse(this.log.data.registros)[i];
        if (item[0] == 'Descrição'){
          //this.listaLogs.push(item[0] + ': <br>De: ' + item[1] + '<br>Para: ' + item[2])
        } else {
          //this.listaLogs.push(item[0] + ': ' + item[1] + ' para ' + item[2])
        }

        //logs += JSON.parse(this.log.data.registros)[i][0] + ' de ' + JSON.parse(this.log.data.registros)[i][1] + ' para ' + JSON.parse(this.log.data.registros)[i][2] + '<br>';
      }
    }
  }

  confirm(form) {
    this.result = true;
    this.close();
  }

}
