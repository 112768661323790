import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderOS'
})
export class OrderOSPipe implements PipeTransform {

/*  transform(value: any, args?: any): any {
    return null;
  }*/

  transform(array: Array<string>, args: string, dataBase: string): Array<string> {
    if (dataBase != 'dg') {
      array.sort((a: any, b: any) => {
        if (a['data'][args] == undefined || a['data'][args] == ''){
          return 1;
        }
  
        if (a['data'][args] == '' && b['data'][args] != ''){
          return 1;
        }
        if (b['data'][args] == '' && a['data'][args] != ''){
          return -1;
        }
  
        if ( a['data'][args].toLowerCase() < b['data'][args].toLowerCase() ){
          return -1;
        }else if( a['data'][args].toLowerCase() > b['data'][args].toLowerCase() ){
          return 1;
        }else{
          return 0;
        }
      });
    } else {
      array.sort((a: any, b: any) => { 
        if (a.data['sequence'] < b.data['sequence']) {
          return -1;
        } else {
          if (a.data['sequence'] > b.data['sequence']) {
            return 1;
          } else {
            return 0;
          }         
        }
      });
    }

    return array;
  }

}
