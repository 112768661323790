import { Component, OnInit, Pipe } from '@angular/core';
import { Router } from '@angular/router';
import { cliente } from '../../classes/cliente';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import {  DialogService } from "../../ng2-bootstrap-modal";
import * as firebase from 'firebase';
import { NovoClienteComponent } from '../dialogs/novo-cliente/novo-cliente.component';
import { InventarioClienteComponent } from '../dialogs/inventario-cliente/inventario-cliente.component';
import { AngularFireAuth } from 'angularfire2/auth';
import { User } from '../../classes/user';
import { OrderByPipe } from '../pipes/order-by.pipe';
import * as moment from 'moment';
import { BottomNavComponent } from '../home/bottom-nav/bottom-nav.component';
import { DadosBancoService } from '../services/dados-banco.service';
import { UltimoContatoComponent } from '../dialogs/ultimo-contato/ultimo-contato.component';
import { LogService } from '../log/log.service';
import { AlertService } from '../dialogs/alert/alert.service';
import { ExcelService } from '../services/export/excel.service';

import * as jsPDF from 'jspdf';


@Component({
  selector: 'app-cliente',
  templateUrl: './lista-clientes.component.html',
  styleUrls: ['./lista-clientes.component.css'],
  providers: [ExcelService]
})

export class ListaClientesComponent implements OnInit {

  clientesList: AngularFireList<any>;
  prospectList: AngularFireList<any>;
  logsList: AngularFireList<any>;
  dataBase = 'prod';
  clientes: any[] = [];
  prospect: any[] = [];
  ultimoCodigoCliente: number = 0;
  usuAtivo: User;
  isAdmin: boolean = false;
  isMaster: boolean = false;
  isDataAvailable: boolean = false;
  mostrarTodos: boolean = false;
  mostrandoProspect = false;

  pageHeight = "0px";
  dataBaseLoaded = false;

  constructor(private router: Router, public db: AngularFireDatabase, private dialogService: DialogService, 
              private afAuth: AngularFireAuth, private dados: DadosBancoService, private logServ:LogService, 
              private alertService: AlertService, private excelExport: ExcelService) {
    var body = document.body, html = document.documentElement;    
    this.pageHeight = (Math.max( body.scrollHeight, body.offsetHeight, 
                            html.clientHeight, html.scrollHeight, html.offsetHeight ) - 90) + "px";
    var table = document.getElementById("myTable");
  
    //table.style.height = height + '';




    let ObsUser1 = afAuth.authState.subscribe((user: firebase.User) => {
      let ObsUser2 = this.db.object("/usuarios").snapshotChanges().map(action => {
           const data = action.payload.toJSON();
           return data;
         }).subscribe(result => {
             Object.keys(result).map(key=> {
               if (user && result[key].email.toLowerCase() == user.email.toLowerCase()){
                 let base = JSON.parse(localStorage.getItem("system-base")).base;
                 if (result[key].base == base) {
                    this.usuAtivo = result[key];
                    this.dataBase = this.usuAtivo.base;
                    this.isAdmin = this.usuAtivo.adm;
                    this.isMaster = this.usuAtivo.master;
                 }   
               }
             });

             this.dataBaseLoaded = true;
             this.carregaDados();
             ObsUser2.unsubscribe;
      });
      ObsUser1.unsubscribe;
    });
  }

  ngOnInit() {

  }

  transformCelPhoneNumber(value: string): any {
    if (value) {
      const regex: RegExp = new RegExp(/(\d{2})(\d{1})(\d{4})(\d{4})/);
      const match = value.match(regex);
      if(match) {
        return `(${match[1]}) ${match[2]} ${match[3]}-${match[4]}`;
      } else {
        return value;
      }
    }
    return '';
  }


  transformPhoneNumber(value: string): any {
    if (value) {
      const regex: RegExp = new RegExp(/(\d{2})(\d{4})(\d{4})/);
      const match = value.match(regex);
      if(match) {
        return `(${match[1]}) ${match[2]}-${match[3]}`;
      } else {
        return value;
      }
    }
    return '';
  }

  private carregaDados(){
    this.clientesList = this.db.list(this.dataBase + "/clientes");
    this.prospectList = this.db.list(this.dataBase + "/prospect");
    this.logsList = this.db.list(this.dataBase + "/logs/");

    this.carregaClientes();
  }

  public carregaClientes() {
    this.db.object(this.dataBase + "/clientes").snapshotChanges().map(action => {
      const data = action.payload.toJSON();
      return data;
    }).subscribe(result => {
      this.clientes = [];
      this.alertService.clear();
       if (result){
         Object.keys(result).map(key=> {
              if (result[key].ativo == undefined) {
                result[key].ativo = true;
              }
              if ((this.mostrarTodos || result[key].ativo)) {
                this.clientes.push({ 'key': key, 'data':result[key], 'nome': result[key].nomeFantasia});
                if (result[key].lembreteContato){
                  if (moment().isAfter(moment(result[key].lembreteContato, 'YYYY-MM-DD'))){
                    this.alertService.success('Lembrete de contato ao cliente "' + result[key].nomeFantasia + '"', false);
                  }
                }
              }
         });
       }

       this.isDataAvailable = true;
     });    
  }

  public mostrarAbaProspect() {
    this.mostrandoProspect = true;
    this.carregaProspect();
  }
    
  public carregaProspect() {
    this.db.object(this.dataBase + "/prospect").snapshotChanges().map(action => {
      const data = action.payload.toJSON();
      return data;
    }).subscribe(result => {
      this.prospect = [];
      this.alertService.clear();
       if (result){
         Object.keys(result).map(key=> {
              if ((this.mostrarTodos || result[key].ativo)) {
                this.prospect.push({ 'key': key, 'data':result[key], 'nome': result[key].nomeFantasia});
                if (result[key].lembreteContato){
                  if (moment().isAfter(moment(result[key].lembreteContato, 'YYYY-MM-DD'))){
                    this.alertService.success('Lembrete de contato ao cliente "' + result[key].nomeFantasia + '"', false);
                  }
                }
              }
         });
       }

       this.isDataAvailable = true;
     }); 
  }

  public redirect(page, id){
    if (id){
      this.router.navigateByUrl('/' + page + '/' + id);
    }else{
      this.router.navigateByUrl('/' + page);
    }
  }

  public abrirNovoClienteForm(){
    var newcliente = new cliente(0, '');
    if (this.dataBase == 'drrafael') {
      newcliente.tipoPessoa = 'PF';
    }
    newcliente.prospect = this.mostrandoProspect;

    let disposable = this.dialogService.addDialog(NovoClienteComponent, {
      title: this.dataBase != 'drrafael' ? 'Adicionando Cliente' : 'Adicionando Paciente',
      message:'Confirm message',
      newCliente: newcliente,
      isAdmin: this.isAdmin,
      isMaster: this.isMaster,
      database: this.dataBase
    }).subscribe((isConfirmed)=>{
      if(isConfirmed) {
        if (newcliente.prospect) {
          firebase.database().ref(this.dataBase + "/utils/ultimoCodigoCliente/value").once('value').then((snapshot) => {
              this.ultimoCodigoCliente = snapshot.val();
              newcliente.codCliente = this.ultimoCodigoCliente + 1;
  
              let myRef = this.prospectList.push(newcliente);
              this.logsList.push({usuario: this.usuAtivo.email, acao: "Criou", codigo: newcliente.codCliente, tipo: "Prospect", chaveReg: myRef.key, registros: JSON.stringify(newcliente), data: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')});
  
              firebase.database().ref(this.dataBase + '/utils/ultimoCodigoCliente/').set({
                  value: newcliente.codCliente
                });
          });

        } else {
          firebase.database().ref(this.dataBase + "/utils/ultimoCodigoCliente/value").once('value').then((snapshot) => {
              this.ultimoCodigoCliente = snapshot.val();
              newcliente.codCliente = this.ultimoCodigoCliente + 1;
  
              let myRef = this.clientesList.push(newcliente);
              this.logsList.push({usuario: this.usuAtivo.email, acao: "Criou", codigo: newcliente.codCliente, tipo: "Cliente", chaveReg: myRef.key, registros: JSON.stringify(newcliente), data: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')});
  
              firebase.database().ref(this.dataBase + '/utils/ultimoCodigoCliente/').set({
                  value: newcliente.codCliente
                });
          });
        }
      }else{
      }
    });
  }

  public formataData(data, formato){
    if (data && data != ''){
      return moment(data, 'YYYY-MM-DDTHH:mm').format(formato);
    }else{
      return 'Sem data';
    }
  }

  public editarCliente(cliente){
    var clienteBkp = Object.assign({}, cliente.data);
    var clienteCopy = Object.assign({}, cliente.data);
    let disposable = this.dialogService.addDialog(NovoClienteComponent, {
      title: this.dataBase != 'drrafael' ? 'Alterando Cliente' : 'Alterando Paciente',
      message:'Confirm message',
      newCliente: clienteCopy,
      isAdmin: this.isAdmin,
      isMaster: this.isMaster,
      database: this.dataBase
    }).subscribe((isConfirmed)=>{
      if(isConfirmed) {
        if (clienteCopy.prospect) {
          if (!clienteBkp.prospect) {
            // tem que remover da lista de cliente
            this.clientesList.remove(cliente.key);
          }

          // tem que atualizar/adicionar na lista de prospect ( Nào poderá ser com UPDATE)
          this.logsList.push({usuario: this.usuAtivo.email, acao: "Alterou", codigo: clienteCopy.codCliente, tipo: "Prospect", chaveReg: cliente.key, registros: JSON.stringify(clienteCopy), data: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')});
          //this.clientesList.update(cliente.key, clienteCopy);
          this.prospectList.set(cliente.key, clienteCopy);
        } else {
          if (clienteBkp.prospect) {
            // tem que remover da lista de propspect
            this.prospectList.remove(cliente.key);
          }

          // Tem que adicionar/atualizar na lista de clientes (Não vai mais poder usar o update)
          this.logsList.push({usuario: this.usuAtivo.email, acao: "Alterou", codigo: clienteCopy.codCliente, tipo: "Cliente", chaveReg: cliente.key, registros: JSON.stringify(clienteCopy), data: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')});
          //this.clientesList.update(cliente.key, clienteCopy);
          this.clientesList.set(cliente.key, clienteCopy);
        }
      }else{
      }
    });
  }

  public inventarioCliente(cliente){
    var clienteBkp = Object.assign({}, cliente.data);
    var clienteCopy = Object.assign({}, cliente.data);
    let disposable = this.dialogService.addDialog(InventarioClienteComponent, {
      title:'Inventário',
      message:'Confirm message',
      cliente: clienteCopy,
      isAdmin: this.isAdmin
    }).subscribe((isConfirmed)=>{
      if(isConfirmed) {

        this.logsList.push({usuario: this.usuAtivo.email, acao: "Alterou inventário", codigo: clienteCopy.codCliente, tipo: "Cliente", chaveReg: cliente.key, registros: clienteCopy.inventario, data: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')});
        this.clientesList.update(cliente.key, clienteCopy);
      }else{
      }
    });

  }

  public getContratoDesc(ehContrato){
    if (ehContrato){
      return "SIM"
    } else {
      return "NÃO"
    }
  }

  public removerCliente(item){
    if (item.data.prospect) {
      if (confirm("Deseja remover este prospect?")){
        this.logsList.push({usuario: this.usuAtivo.email, acao: "Removeu", codigo: item.data.codCliente, tipo: "Prospect", chaveReg: item.key, registros: JSON.stringify(item.data), data: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')});
        this.prospectList.remove(item.key);
      }
    } else {
      if (confirm("Deseja remover este cliente?")){
        this.logsList.push({usuario: this.usuAtivo.email, acao: "Removeu", codigo: item.data.codCliente, tipo: "Cliente", chaveReg: item.key, registros: JSON.stringify(item.data), data: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')});
        this.clientesList.remove(item.key);
      }
    }
  }

  public contato(item){
      var clienteCopy = Object.assign({}, item.data);
      let disposable = this.dialogService.addDialog(UltimoContatoComponent, {
        title:'Último contato',
        message:'Confirm message',
        cliente: clienteCopy
      }).subscribe((isConfirmed)=>{
        if(isConfirmed) {
          let log = this.logServ.comparaUltimoContato(item.data, clienteCopy);
          this.logsList.push({usuario: this.usuAtivo.email, acao: "Alterou último contato", codigo: clienteCopy.codCliente, tipo: "Cliente", chaveReg: item.key, registros: JSON.stringify(log), data: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')});
          this.clientesList.update(item.key, clienteCopy);
        }else{
        }
      });
  }

  private showAll() {
    this.mostrarTodos = !this.mostrarTodos;
    if (this.mostrandoProspect) {
      this.carregaProspect();
    } else {
      this.carregaClientes();
    }
  }

  private exportClientesExcel() {
    let data: [{cliente: string, email: string, email2: string, email3: string}];
    data = [{"cliente": "", "email": "", "email2": "", "email3": ""}];

    if (this.mostrandoProspect) {
      this.prospect.forEach((item) => { 
        let emails = item.data.email.split(';', 3);
        data.push({"cliente": item.data.nomeFantasia, "email": emails[0], "email2": emails[1], "email3": emails[2]});
      });
    } else {
      this.clientes.forEach((item) => { 
        let emails = item.data.email.split(';', 3);
        data.push({"cliente": item.data.nomeFantasia, "email": emails[0], "email2": emails[1], "email3": emails[2]});
      });
    }
    data.splice(0,1);
    this.excelExport.exportAsExcelFile(data, 'clientes');
  }

  private exportClientesPDF() {
    let data = [];
    if (this.mostrandoProspect) {
      data = this.prospect;
    } else {
      data = this.clientes;
    }
  
    let pdfName = 'clientes';
    var doc = new jsPDF('p', 'in', 'a4');

    var verticalOffset = 0;

    doc.setFontSize(11);
    doc.text(0.2, verticalOffset + 0.3, moment().format('DD/MM/YYYY'));

    doc.setFontSize(23);
    doc.text(3.2, 0.9, 'Clientes');

    verticalOffset = 1.25;

    let startgrid = 1.25;
    let Column1 = 2.7;
    let Column2 = 3.6;
    let Column3 = Column2 + 0.543;
    let Column4 = Column3 + 0.543;
    let Column5 = Column4 + 0.543;
    let Column6 = Column5 + 0.543;
    let Column7 = Column6 + 0.543;
    let Column8 = Column7 + 0.543;
    let Column9 = Column8 + 0.543;
    
    
    doc.setLineWidth(0.009);
    doc.setFillColor(255,255,255);
    doc.setDrawColor(0,0,0);
    doc.roundedRect(0.2, verticalOffset, 7.8, 0.25, 0.02, 0.02, 'FD');
    doc.setFontSize(10);
    doc.setFontType('bold');
    doc.text(0.35, verticalOffset+0.18, "Nome"); 
    doc.text(2.75, verticalOffset+0.18, "Telefone");
    
    doc.setFontSize(8);
    doc.setFontType('normal');
    if (this.dataBase !== 'drrafael') {
      doc.text(Column2 + 0.05, verticalOffset+0.18, "Contrato");
      doc.text(Column3 + 0.05, verticalOffset+0.18, "Checklist");
      doc.text(Column4 + 0.05, verticalOffset+0.18, "Backup"); 
      doc.text(Column5 + 0.05, verticalOffset+0.18, "Firewall"); 
      doc.text(Column6 + 0.05, verticalOffset+0.18, "Monit."); 
      doc.text(Column7 + 0.05, verticalOffset+0.18, "Anti-vírus"); 
      doc.text(Column8 + 0.05, verticalOffset+0.18, "Servidor"); 
      doc.text(Column9 + 0.05, verticalOffset+0.18, "Domínio"); 
    } else {
      doc.text(Column2 + 0.05, verticalOffset+0.18, "Cidade");
    }

    verticalOffset = 1.5;
    data.forEach((item) => {
        doc.setLineWidth(0.009);
        doc.setFillColor(255,255,255);
        doc.setDrawColor(0,0,0);
        doc.roundedRect(0.2, verticalOffset, 7.8, 0.25, 0.02, 0.02, 'FD');
        
        verticalOffset += 0.18;
        doc.text(0.35, verticalOffset, item.data.nomeFantasia);
        doc.text(2.75, verticalOffset, item.data.telefone.split(" ")[0]);
        if (this.dataBase !== 'drrafael') {
          doc.text(Column2 + 0.05, verticalOffset, item.data.contrato ? "Sim" : "Não");
          doc.text(Column3 + 0.05, verticalOffset, item.data.checklist ? "Sim" : "Não");
          doc.text(Column4 + 0.05, verticalOffset, item.data.backup ? "Sim" : "Não");
          doc.text(Column5 + 0.05, verticalOffset, item.data.firewall ? "Sim" : "Não");
          doc.text(Column6 + 0.05, verticalOffset, item.data.monitoramento ? "Sim" : "Não");
          doc.text(Column7 + 0.05, verticalOffset, item.data.antivirus ? "Sim" : "Não");
          doc.text(Column8 + 0.05, verticalOffset, item.data.servidor ? "Sim" : "Não");
          doc.text(Column9 + 0.05, verticalOffset, item.data.domínio ? "Sim" : "Não");
        } else {
          doc.text(Column2 + 0.05, verticalOffset, item.data.cidade ? item.data.cidade : "");
        }
        verticalOffset += 0.05;


        if (verticalOffset >= 11){
          doc.setDrawColor(0,0,0);
          doc.setLineWidth(0.01);
          doc.line(Column1, startgrid, Column1, verticalOffset+0.02);
          doc.setDrawColor(0,0,0);
          doc.setLineWidth(0.01);
          doc.line(Column2, startgrid, Column2, verticalOffset+0.02);

          if (this.dataBase !== 'drrafael') {
            doc.setDrawColor(0,0,0);
            doc.setLineWidth(0.01);
            doc.line(Column3, startgrid, Column3, verticalOffset+0.02);
            doc.setDrawColor(0,0,0);
            doc.setLineWidth(0.01);
            doc.line(Column4, startgrid, Column4, verticalOffset+0.02);
            doc.setDrawColor(0,0,0);
            doc.setLineWidth(0.01);
            doc.line(Column5, startgrid, Column5, verticalOffset+0.02);
            doc.setDrawColor(0,0,0);
            doc.setLineWidth(0.01);
            doc.line(Column6, startgrid, Column6, verticalOffset+0.02);
            doc.setDrawColor(0,0,0);
            doc.setLineWidth(0.01);
            doc.line(Column7, startgrid, Column7, verticalOffset+0.02);
            doc.setDrawColor(0,0,0);
            doc.setLineWidth(0.01);
            doc.line(Column8, startgrid, Column8, verticalOffset+0.02);
            doc.setDrawColor(0,0,0);
            doc.setLineWidth(0.01);
            doc.line(Column9, startgrid, Column9, verticalOffset+0.02);
          } else {
            doc.setDrawColor(0,0,0);
            doc.setLineWidth(0.01);
            doc.line(Column3+1, startgrid, Column3+1, verticalOffset+0.02);
          }
          doc.addPage();
          verticalOffset = 0.3;
          startgrid = 0.3;
        }  
    });

    doc.setDrawColor(0,0,0);
    doc.setLineWidth(0.01);
    doc.line(Column1, startgrid, Column1, verticalOffset+0.02);
    doc.setDrawColor(0,0,0);
    doc.setLineWidth(0.01);
    doc.line(Column2, startgrid, Column2, verticalOffset+0.02);
    if (this.dataBase !== 'drrafael') {
      doc.setDrawColor(0,0,0);
      doc.setLineWidth(0.01);
      doc.line(Column3, startgrid, Column3, verticalOffset+0.02);
      doc.setDrawColor(0,0,0);
      doc.setLineWidth(0.01);
      doc.line(Column4, startgrid, Column4, verticalOffset+0.02);
      doc.setDrawColor(0,0,0);
      doc.setLineWidth(0.01);
      doc.line(Column5, startgrid, Column5, verticalOffset+0.02);
      doc.setDrawColor(0,0,0);
      doc.setLineWidth(0.01);
      doc.line(Column6, startgrid, Column6, verticalOffset+0.02);
      doc.setDrawColor(0,0,0);
      doc.setLineWidth(0.01);
      doc.line(Column7, startgrid, Column7, verticalOffset+0.02);
      doc.setDrawColor(0,0,0);
      doc.setLineWidth(0.01);
      doc.line(Column8, startgrid, Column8, verticalOffset+0.02);
      doc.setDrawColor(0,0,0);
      doc.setLineWidth(0.01);
      doc.line(Column9, startgrid, Column9, verticalOffset+0.02);
    } else {
      doc.setDrawColor(0,0,0);
      doc.setLineWidth(0.01);
      doc.line(Column3+1, startgrid, Column3+1, verticalOffset+0.02);
    }

    doc.save(pdfName);  
  
  }

}
