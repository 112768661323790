import { Component, OnInit } from '@angular/core';
import { DialogComponent, DialogService } from "../../../ng2-bootstrap-modal";
import * as moment from 'moment';
import { Estoque } from '../../../classes/estoque';
import { CurrencyMaskModule } from "ng2-currency-mask";

export interface ConfirmModel {
  title: string;
  message: string;
  itemEstoque: Estoque;
  tecList: any[];
  database: string;
}

@Component({
  selector: 'app-estoque-form-saida',
  templateUrl: './estoque-form-saida.component.html',
  styleUrls: ['./estoque-form-saida.component.css']
})

export class EstoqueFormSaidaComponent extends DialogComponent<ConfirmModel, boolean> implements ConfirmModel, OnInit {
  title: string;
  message: string;
  itemEstoque: Estoque;
  tecList: any[];
  database: string;

  historicoSaida = [];
  valorNeg = 0;
  tecSelecionado = "";

  constructor(dialogService: DialogService) {
    super(dialogService);
  }

  confirm(form) {
    let qtdTotal = (form.edtQtdExterna ? +form.edtQtdExterna.value : 0) + +form.edtQtdInterna.value;
    if (qtdTotal == 0){
      alert('Favor informar a quantidade de itens para baixa no estoque.');
      return
    }

    let qtdRestanteInterno = this.itemEstoque.qtd - +form.edtQtdInterna.value;
    if (qtdRestanteInterno < 0) {
      if (this.itemEstoque.qtd == 1){
          alert('Não foi possível dar baixa.\nExistem apenas 1 item no estoque interno.');
      } else {
          alert('Não foi possível dar baixa.\nExistem apenas ' + this.itemEstoque.qtd + ' itens no estoque interno.');
      }

      return
    }

    if (this.database != 'drrafael' && form.edtQtdExterna.value > 0 && !form.selectNomeTecnico.value) {
      alert('É necessário informar o técnico.');
      return
    } else {
      let alertMsg = false;   
      let indexItemComZero = -1;   
      this.itemEstoque.qtdExternaLista.forEach((item, index)=> {
        if (item.tecnico == form.selectNomeTecnico.value) {
          let qtdRestanteExterno = item.qtdExterna - +form.edtQtdExterna.value;
          if (qtdRestanteExterno < 0) {
            alertMsg = true;
            if (item.qtdExterna == 1){
                alert('Não foi possível dar baixa.\nExistem apenas 1 item no estoque externo.');
            } else {
                alert('Não foi possível dar baixa.\nExistem apenas ' + item.qtdExterna + ' itens no estoque externo deste técnico.');
            }
      
            return
          } else {
            item.qtdExterna = item.qtdExterna - +form.edtQtdExterna.value;
          }
        }

        if (item.qtdExterna == 0) {
          indexItemComZero = index;
        }
      });
      if (alertMsg) {
        return;
      }
      if (indexItemComZero >-1) {
        this.itemEstoque.qtdExternaLista.splice(indexItemComZero, 1);
      }      
    }


    let qtdRestanteExterno = 0;
    this.itemEstoque.qtdExternaLista.forEach((item)=> {
      qtdRestanteExterno += item.qtdExterna;      
    });

    if (form.edtMotivo.value == ''){
      alert('É necessário informar um motivo.');
      return
    }

    if (form.edtDestino.value == ''){
      alert('É necessário informar um destino.');
      return
    }

    this.itemEstoque.qtd = qtdRestanteInterno;
    this.itemEstoque.qtdExterna = qtdRestanteExterno;


    this.historicoSaida.push({'data': moment().format('YYYY-MM-DDTHH:mm'), 'qtd': qtdTotal, 'motivo': form.edtMotivo.value, 'destino': form.edtDestino.value, 'PrecoNegociado': this.valorNeg});
    this.itemEstoque.historicoSaida = JSON.stringify(this.historicoSaida);

    this.result = true;
    this.close();
  }

  cancelar(form){
    this.close();
  }

  ngOnInit() {
    this.valorNeg = this.itemEstoque.valorVenda;
    this.historicoSaida = this.itemEstoque.historicoSaida ? JSON.parse(this.itemEstoque.historicoSaida) : [];
  }

}
