import { Component, OnInit } from '@angular/core';
import { DialogComponent, DialogService } from "../../../ng2-bootstrap-modal";
import { cliente } from '../../../classes/cliente';

export interface ConfirmModel {
  title: string;
  message: string;
  cliente: cliente;
  isAdmin: boolean;
}

@Component({
  selector: 'app-inventario-cliente',
  templateUrl: './inventario-cliente.component.html',
  styleUrls: ['./inventario-cliente.component.css']
})

export class InventarioClienteComponent extends DialogComponent<ConfirmModel, boolean> implements ConfirmModel, OnInit {
  title: string;
  message: string;
  cliente: cliente;
  isAdmin: boolean;

  inventario: any[] = [];


  constructor(dialogService: DialogService) {
    super(dialogService);
  }

  ngOnInit() {
    if (this.cliente.inventario){
      this.inventario = JSON.parse(this.cliente.inventario);
    } else {
      this.inventario = [];
    }

    this.inventario.forEach((item) => {
      item.mostraDetalhado = false;
    });
  }

  confirm() {
    this.cliente.inventario = JSON.stringify(this.inventario);

    this.result = true;
    this.close();
  }


  public mostrarDetalhe(item){
    item.mostraDetalhado = true;
  }

  public esconderDetalhe(item){
    item.mostraDetalhado = false;
  }

  public novoInventario(){
    this.inventario.push({nome: '', qtd: 0, detalhes: '', mostraDetalhado: false});
  }

}
