import { Component, OnInit, Inject } from '@angular/core';
import * as firebase from 'firebase';
import { FirebaseApp } from 'angularfire2';
import { Router } from '@angular/router';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';

  constructor(@Inject(FirebaseApp) firebaseApp: any, private router: Router) {
      firebaseApp.auth().onAuthStateChanged(function(user) {
        if (user) {
          router.navigate([""]);
        }else {
          router.navigate(["login"]);
        }
      });
    }

    ngOnInit() {
    }
}
