import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import * as firebaseApp from 'firebase/app';
import { DialogService } from "../../ng2-bootstrap-modal";
import { AngularFireAuth } from 'angularfire2/auth';
import * as moment from 'moment';
import * as firebase from 'firebase';

import { User } from '../../classes/user';
import { Estoque } from '../../classes/estoque';
import { EstoqueFormComponent } from '../dialogs/estoque-form/estoque-form.component';
import { EstoqueFormSaidaComponent } from '../dialogs/estoque-form-saida/estoque-form-saida.component';
import { EstoqueFormEntradaComponent } from '../dialogs/estoque-form-entrada/estoque-form-entrada.component';
import { EstoqueFormMoviComponent } from '../dialogs/estoque-form-movi/estoque-form-movi.component';

@Component({
  selector: 'app-estoque',
  templateUrl: './estoque.component.html',
  styleUrls: ['./estoque.component.css']
})

export class EstoqueComponent implements OnInit {
  obsEstoque: AngularFireList<any>;
  listaEstoque = [];
  listaEstoqueFiltrado = [];
  listaTecnicosEstoqExt = [];
  usuAtivo: User;
  dataBase: String = '';
  ultimoCodigoEstoque: number = 1;
  isMaster = false;

  filtroDesc = "";
  filtroSemEstoque = false;
  tecnicos: any[] = [];

  orderbyDesc: boolean = false;
  orderbyCodigo: boolean = false;
  orderbyCusto: boolean = false;
  orderbyVenda: boolean = false;
  colunaOrderby: string = 'dataFim';
  orderbyASC: boolean = false;

  constructor(public db: AngularFireDatabase, private router: Router,
              private afAuth: AngularFireAuth, private dialogService: DialogService) {
    afAuth.authState.subscribe((user: firebase.User) => {
      this.db.object("/usuarios").snapshotChanges().map(action => {
           const data = action.payload.toJSON();
           return data;
         }).subscribe(result => {
            Object.keys(result).map(key=> {
              if (user && result[key].email.toLowerCase() == user.email.toLowerCase()){
                let base = JSON.parse(localStorage.getItem("system-base")).base;
                if (result[key].base == base) {
                  this.usuAtivo = result[key];
                  this.isMaster = this.usuAtivo.master;
                  this.dataBase = this.usuAtivo.base;
                }  
              }

              if (result[key].tecnico && result[key].ativo){
                this.tecnicos.push({ 'key': key, 'data':result[key]});
              }
            });
            var i = 0;
            while (i < this.tecnicos.length){
              if (this.tecnicos[i].data.base != this.dataBase){
                this.tecnicos.splice(i, 1);
              } else {
                i++;
              }
            }



            this.db.object(this.dataBase + "/estoque").snapshotChanges().map(action => {
                 const data = action.payload.toJSON();
                 return data;
              }).subscribe(resultEstoque => {
                  this.listaEstoque = [];
                  if(resultEstoque){
                    Object.keys(resultEstoque).map(key=> {
                        this.listaEstoque.push({'key': key, 'data': resultEstoque[key]});
                    });

                    this.listaEstoque.sort((item1, item2) =>{
                      if (item1.data.desc > item2.data.desc){
                        return 1;
                      } else {
                        if (item1.data.desc < item2.data.desc){
                            return -1
                        }
                      }
                      return 0;
                    });

                    this.listaTecnicosEstoqExt = [];
                    this.listaEstoque.forEach((item) => {

                      if (!item.data.qtdExternaLista) {
                        if (item.data.qtdExterna > 0) {
                          item.data.qtdExternaLista = [{qtdExterna: item.data.qtdExterna, tecnico: "Eric"}];
                        } else {
                          item.data.qtdExternaLista = [];
                        }
                      } else {
                        item.data.qtdExternaLista = Object.keys(item.data.qtdExternaLista).map(i => item.data.qtdExternaLista[i]);
                      }       

                      item.data.qtdExternaLista = Object.keys(item.data.qtdExternaLista).map(i => item.data.qtdExternaLista[i]);
                      item.data.qtdExternaLista.forEach((itemEstq) => {
                        if (this.listaTecnicosEstoqExt.indexOf(itemEstq.tecnico) == -1) {
                          this.listaTecnicosEstoqExt.push(itemEstq.tecnico);
                        }
                      });                      

                      item.data.qtdExternaLista.sort((item1, item2) => {
                        if (item1.tecnico > item2.tecnico){
                          return 1;
                        } else {
                          if (item1.tecnico < item2.tecnico){
                              return -1
                          }
                        }
                        return 0;
                      });
                      
                    });

                    this.listaTecnicosEstoqExt.sort((item1, item2) => {
                      if (item1 > item2){
                        return 1;
                      } else {
                        if (item1 < item2){
                            return -1
                        }
                      }
                      return 0;
                    });
                  }

                  this.aplicaFiltro();
              });

            this.obsEstoque = this.db.list(this.dataBase + "/estoque/");
        });
    });
  }

  ngOnInit() {
  }

  public redirect(page){
    this.router.navigateByUrl('/' + page);
  }

  public addProduto(){
    let prod = new Estoque();
    prod.dataAdicionado = moment().format('YYYY-MM-DDTHH:mm');

    let disposable = this.dialogService.addDialog(EstoqueFormComponent, {
      title:'Criando produto',
      message:'Confirm message',
      itemEstoque: prod,
      modoTela: 0,
      isMaster: this.isMaster,
      database: this.dataBase
    }).subscribe((isConfirmed)=>{
      if(isConfirmed) {
        firebase.database().ref(this.dataBase + "/utils/ultimoCodigoEstoque/value").once('value').then((snapshot) => {
          this.ultimoCodigoEstoque = snapshot.val();

          prod.codigo = this.ultimoCodigoEstoque + 1;
          /*let myRef = */this.obsEstoque.push(prod);
          //this.logsList.push({usuario: this.usuAtivo.email, acao: "Criou", codigo: newOS.codigo, tipo: "OS", chaveReg: myRef.key, registros: JSON.stringify(newOS), data: moment().format('DD-MM-YYYY HH:mm')});
          this.ultimoCodigoEstoque++;
          firebase.database().ref(this.dataBase + '/utils/ultimoCodigoEstoque/').set({
              value: prod.codigo
            });
        });
      }else{
      }
    });
  }

  public verProduto(item){

    var OSCopy = Object.assign({}, item.data);
    let disposable = this.dialogService.addDialog(EstoqueFormComponent, {
      title:'Criando produto',
      message:'Confirm message',
      itemEstoque: OSCopy,
      modoTela: 0,
      isMaster: this.isMaster,
      database: this.dataBase
    }).subscribe((isConfirmed)=>{
      if(isConfirmed) {

        /*let myRef = */this.obsEstoque.update(item.key, OSCopy);
        //this.logsList.push({usuario: this.usuAtivo.email, acao: "Criou", codigo: newOS.codigo, tipo: "OS", chaveReg: myRef.key, registros: JSON.stringify(newOS), data: moment().format('DD-MM-YYYY HH:mm')});
        this.ultimoCodigoEstoque++;

      }else{
      }
    });
  }

  public entradaEstoque(item){
    var itemEstoqueBkp = this.deepCopy(item.data);
    if (!itemEstoqueBkp.qtdExternaLista) {
      if (itemEstoqueBkp.qtdExterna > 0) {
        itemEstoqueBkp.qtdExternaLista = [{qtdExterna: itemEstoqueBkp.qtdExterna, tecnico: "Eric"}];
      } else {
        itemEstoqueBkp.qtdExternaLista = [];
      }
    } else {
      itemEstoqueBkp.qtdExternaLista = Object.keys(itemEstoqueBkp.qtdExternaLista).map(i => itemEstoqueBkp.qtdExternaLista[i]);
    }       


    let disposable = this.dialogService.addDialog(EstoqueFormEntradaComponent, {
      title:'Criando produto',
      message:'Confirm message',
      itemEstoque: itemEstoqueBkp,
      tecList: this.tecnicos,
      database: this.dataBase
    }).subscribe((isConfirmed)=>{
      if(isConfirmed) {
        this.obsEstoque.update(item.key, itemEstoqueBkp);
      }else{
      }
    });
  }

  public saidaEstoque(item){
    if (item.data.qtd + item.data.qtdExterna == 0){
      alert('Não é possível dar baixa.\nNão possuí estoque deste item.');
      return
    }

    var itemEstoqueBkp = this.deepCopy(item.data);
    if (!itemEstoqueBkp.qtdExternaLista) {
      if (itemEstoqueBkp.qtdExterna > 0) {
        itemEstoqueBkp.qtdExternaLista = [{qtdExterna: itemEstoqueBkp.qtdExterna, tecnico: "Eric"}];
      } else {
        itemEstoqueBkp.qtdExternaLista = [];
      }
    } else {
      itemEstoqueBkp.qtdExternaLista = Object.keys(itemEstoqueBkp.qtdExternaLista).map(i => itemEstoqueBkp.qtdExternaLista[i]);
    }       
    let disposable = this.dialogService.addDialog(EstoqueFormSaidaComponent, {
      title:'Criando produto',
      message:'Confirm message',
      itemEstoque: itemEstoqueBkp,
      tecList: this.tecnicos,
      database: this.dataBase
    }).subscribe((isConfirmed)=>{
      if(isConfirmed) {
        this.obsEstoque.update(item.key, itemEstoqueBkp);
      }else{
      }
    });
  }

  public excluirProduto(item){
    if (confirm('Tem certeza que deseja remover o item "' + item.data.desc + '" do estoque?'))
    this.obsEstoque.remove(item.key);
  }

  public movimentoEstoque(item){

    if (item.data.qtd + item.data.qtdExterna == 0){
      alert('Não é possível movimentar estoque.\nNão possuí estoque deste item.');
      return
    }

    var itemEstoqueBkp = this.deepCopy(item.data);
    if (!itemEstoqueBkp.qtdExternaLista) {
      if (itemEstoqueBkp.qtdExterna > 0) {
        itemEstoqueBkp.qtdExternaLista = [{qtdExterna: itemEstoqueBkp.qtdExterna, tecnico: "Eric"}];
      } else {
        itemEstoqueBkp.qtdExternaLista = [];
      }
    } else {
      itemEstoqueBkp.qtdExternaLista = Object.keys(itemEstoqueBkp.qtdExternaLista).map(i => itemEstoqueBkp.qtdExternaLista[i]);
    }    
    let disposable = this.dialogService.addDialog(EstoqueFormMoviComponent, {
      title:'Criando produto',
      message:'Confirm message',
      itemEstoque: itemEstoqueBkp,
      tecList: this.tecnicos,
      database: this.dataBase
    }).subscribe((isConfirmed)=>{
      if(isConfirmed) {
        this.obsEstoque.update(item.key, itemEstoqueBkp);
      }else{
      }
    });
  }

  public ImprimirEstoqueCSV(){
    let popupWin, printCSV;

    printCSV = 'Descrição;Qtd. Int.; Qtd. Ext.;Tipo;Valor Compra; Valor Venda<br>';

    this.listaEstoque.forEach((item) => {
        let tipo = item.data.unidade ? item.data.unidade : ' ';
        printCSV += item.data.desc + ';' + item.data.qtd + ';' + item.data.qtdExterna + ';' + tipo + ';' + item.data.valorCusto.toFixed(2) + ';' + item.data.valorVenda.toFixed(2) + '<br>';
    });


    let pdfName = "Estoque CSV - " + moment().format('DD-MM HHmm');
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css">
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
          <title>${pdfName}</title>
          <style>
            //........Customized style.......
          </style>
        </head>
        <body>
          ${printCSV}
        </body>
      </html>`
    );
    popupWin.document.close();
  }

  public ImprimirEstoque(){
    let printContents, popupWin;

    printContents = `
    <div class="table-responsive rounded-top">
    <table class="table table-sm table-hover">
          <thead class='thead-dark'>
            <tr>
              <th></th>
              <th>#</th>
              <th>Descrição</th>
              <th>Qtd. Int.</th>
              <th style="min-width:120px">Qtd. Ext.</th>
              <th>Tipo</th>
              <th>Valor C. Uni</th>
              <th>Valor C. Total</th>
              <th>Valor V. Uni</th>
              <th>Valor V. Total</th>
              <th></th>
            </tr>
          </thead>
          <tbody>`;

    let totalIntQtd = 0;
    let totalExtQtd = 0;
    let valorCustoTotal = 0;
    let valorVendaTotal = 0;
    this.listaEstoque.forEach((item) => {
      if (item.data.qtdExterna > 0 || item.data.qtd) {
        totalIntQtd += item.data.qtd;
        totalExtQtd += item.data.qtdExterna;
        valorCustoTotal += (item.data.valorCusto * (item.data.qtd + item.data.qtdExterna));
        valorVendaTotal += (item.data.valorVenda * (item.data.qtd + item.data.qtdExterna));

        let tipo = item.data.unidade ? item.data.unidade : ' ';
        let contentLine = "<tr>";
        if (item.data.classFiscal){
            contentLine += "<td width=22>**</td>";
        } else {
            contentLine += "<td></td>";
        }
        contentLine += "<td>" + item.data.codigo + "</td>";
        contentLine += "<td>" + item.data.desc + "</td>";
        contentLine += "<td>" + item.data.qtd + "</td>";
        if (item.data.qtdExternaLista.length == 0) {
          contentLine += "<td>0</td>";
        } else {
          contentLine += "<td>";
          item.data.qtdExternaLista.forEach((item) => {
            contentLine += "<div>" + item.qtdExterna + " - " + item.tecnico + "</div>";
          });
          contentLine += "</td>";
        }
        

        
        
      

        contentLine += "<td>" + tipo + "</td>";
        contentLine += "<td>R$" + item.data.valorCusto.toFixed(2) + "</td>";
        contentLine += "<td>R$" + (item.data.valorCusto * (item.data.qtd + item.data.qtdExterna)).toFixed(2) + "</td>";
        contentLine += "<td>R$" + item.data.valorVenda.toFixed(2) + "</td>";
        contentLine += "<td>R$" + (item.data.valorVenda * (item.data.qtd + item.data.qtdExterna)).toFixed(2) + "</td>";
        contentLine += "</tr>";
        printContents = printContents + contentLine;
      } 
    });

    printContents = printContents + `</tbody>
                                      <tfoot class='thead-dark'>
                                        <tr>
                                          <td></td>
                                          <td>Total</td>
                                          <td>${totalIntQtd}</td>
                                          <td>${totalExtQtd}</td>
                                          <td></td>
                                          <td></td>
                                          <td>R$${valorCustoTotal.toFixed(2)}</td>
                                          <td></td>
                                          <td>R$${valorVendaTotal.toFixed(2)}</td>
                                        </tr>
                                      </tfoot>

                                     </table>
                                     </div>`


    let pdfName = "Estoque - " + moment().format('DD-MM HHmm');
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css">
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
          <title>${pdfName}</title>
          <style>
            //........Customized style.......
          </style>
        </head>
        <body onload="window.print();window.close()">
          ${printContents}
        </body>
      </html>`
    );
    popupWin.document.close();
  }

  public aplicaFiltro(){
    this.listaEstoqueFiltrado = [];
    this.listaEstoque.forEach((item) => {
      if (this.filtro(item.data)){
        this.listaEstoqueFiltrado.push({ 'key': item.key, 'data': item.data});
      }
    });
  }

  public filtro(itemEstoque){
    let respeitaFiltro = true;

    if (this.filtroDesc != "" && itemEstoque.desc.toLowerCase().indexOf(this.filtroDesc.toLowerCase()) == -1){
      respeitaFiltro = false;
    }
    if (this.filtroSemEstoque && (itemEstoque.qtd + itemEstoque.qtdExterna) > 0){
      respeitaFiltro = false;
    }

    return respeitaFiltro;
  }

  public filtraDesc(valor){
    this.filtroDesc = valor;
    this.aplicaFiltro();
  }

  public filtraSemEstoque(checkbox){
    this.filtroSemEstoque = checkbox.checked;
    this.aplicaFiltro();
  }


  public orderby(coluna){
    if (this.colunaOrderby == coluna){
      this.orderbyASC = !this.orderbyASC;
      this.listaEstoqueFiltrado.reverse();
    } else {
      this.orderbyASC = true;
      this.colunaOrderby = coluna;
      this.orderbyCodigo = (coluna == 'codigo');
      this.orderbyDesc = (coluna == 'desc');
      this.orderbyCusto = (coluna == 'custo');
      this.orderbyVenda = (coluna == 'venda');

      this.sortListaEstoqueFiltrado();
      this.listaEstoqueFiltrado.reverse();
    }
  }

  public sortListaEstoqueFiltrado(){
    this.listaEstoqueFiltrado.sort((a: any, b: any) => {
      let dadoA;
      let dadoB;

      if (this.colunaOrderby == 'valorCusto' || this.colunaOrderby == 'valorVenda'){
        dadoA = a.data[this.colunaOrderby];
        dadoB = b.data[this.colunaOrderby];
      } else {
        dadoA = a.data[this.colunaOrderby].toLowerCase();
        dadoB = b.data[this.colunaOrderby].toLowerCase();
      }


      if (isNaN(dadoA)){
        if (dadoA == ''){
          return 1;
        }
        if ( dadoA < dadoB){
          return 1;
        }else if( dadoA > dadoB){
            return -1;
        }else{
          return 0;
        }
      } else {
        if ( dadoA < dadoB ){
          return 1;
        }else if( dadoA > dadoB ){
            return -1;
        }else{
          return 0;
        }
      }
    });
  }

  deepCopy(obj) {
    var copy;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || "object" != typeof obj) return obj;

    // Handle Date
    if (obj instanceof Date) {
        copy = new Date();
        copy.setTime(obj.getTime());
        return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
        copy = [];
        for (var i = 0, len = obj.length; i < len; i++) {
            copy[i] = this.deepCopy(obj[i]);
        }
        return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
        copy = {};
        for (var attr in obj) {
            if (obj.hasOwnProperty(attr)) copy[attr] = this.deepCopy(obj[attr]);
        }
        return copy;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
}

}
