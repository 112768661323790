import { Component, OnInit } from '@angular/core';
import { DialogComponent, DialogService } from "../../../ng2-bootstrap-modal";
import { cliente } from '../../../classes/cliente';

export interface ConfirmModel {
  title: string;
  message: string;
  cliente: cliente;
}

@Component({
  selector: 'app-ultimo-contato',
  templateUrl: './ultimo-contato.component.html',
  styleUrls: ['./ultimo-contato.component.css']
})
export class UltimoContatoComponent extends DialogComponent<ConfirmModel, boolean> implements ConfirmModel, OnInit {
  title: string;
  message: string;
  cliente: cliente;

  constructor(dialogService: DialogService) {
    super(dialogService);
  }

  ngOnInit() {
  }

  confirm() {
    this.result = true;
    this.close();
  }

}
