import { Injectable } from '@angular/core';

import * as moment from 'moment';

@Injectable()
export class LogService {

  constructor() { }

  public comparaOS(oldOS, newOS){
    let log = [];
    if (oldOS.codCliente != newOS.codCliente) {
      let valores = ['Cliente', oldOS.codCliente, newOS.codCliente];
      log.push(valores);
      //let logJson = JSON.stringify(log);
      //console.log(JSON.stringify(log));
      //console.log(log);
      //let testeArray = JSON.parse(logJson);
    }
    if (oldOS.dataInicio != newOS.dataInicio) {

    }
    if (oldOS.dataFim != newOS.dataFim) {
      let valores = ['Data Fim', oldOS.dataFim, newOS.dataFim];
      log.push(valores);
    }
    if (oldOS.status != newOS.status) {

    }
    if (oldOS.local != newOS.local) {
      let valores = ['Local', this.getLocalDesc(oldOS.local), this.getLocalDesc(newOS.local)];
      log.push(valores);
    }
    if (oldOS.descricao != newOS.descricao) {
      let valores = ['Descrição', oldOS.descricao, newOS.descricao];
      log.push(valores);
    }
    if (oldOS.nomeTecnico != newOS.nomeTecnico) {
      let valores = ['Técnico', oldOS.nomeTecnico, newOS.nomeTecnico];
      log.push(valores);
    }
    if (oldOS.situacao != newOS.situacao) {
      let valores = ['Situação', oldOS.situacao, newOS.situacao];
      log.push(valores);
    }
    if (oldOS.esforco != newOS.esforco) {
      let valores = ['Esforço', oldOS.esforco, newOS.esforco];
      log.push(valores);
    }
    if (oldOS.solucao != newOS.solucao) {
      let valores = ['Solução', oldOS.solucao, newOS.solucao];
      log.push(valores);
    }
    if (oldOS.dataVencimento != newOS.dataVencimento) {
      let valores = ['Vencimento', oldOS.dataVencimento, newOS.dataVencimento];
      log.push(valores);
    }
    if (oldOS.dataEsforco != newOS.dataEsforco) {
      let valores = ['Data esforço', oldOS.dataEsforco, newOS.dataEsforco];
      log.push(valores);
    }
    if (oldOS.isentar != newOS.isentar) {
      let valores = ['Isentar', oldOS.isentar, newOS.isentar];
      log.push(valores);
    }
    if (oldOS.listaEsforco != newOS.listaEsforco) {
      /*let valores = ['Esforços', oldOS.listaEsforco, newOS.listaEsforco];
      log.push(valores);*/
    }

    let oldAtividades = [];
    let newAtividades = [];
    if (oldOS.atividades){
      oldAtividades = JSON.parse(oldOS.atividades);
    }
    if (newOS.atividades){
      newAtividades = JSON.parse(newOS.atividades);
    }
    if (oldAtividades.length > newAtividades.length){
      let valores = ['Removeu Atividade', oldAtividades.length, newAtividades.length];
      log.push(valores);
    }
    if (oldAtividades.length < newAtividades.length){
      let valores = ['Adicionou Atividade', oldAtividades.length, newAtividades.length];
      log.push(valores);
    }
    if (oldAtividades.length == newAtividades.length){
      if (oldAtividades.length > 0){
        oldAtividades.forEach((item, index) => {
          if (item.solucao != newAtividades[index].solucao){
            let valores = ['Alterou solução da Atividade', item.solucao, newAtividades[index].solucao];
            log.push(valores);
          }

          if (item.esforco != newOS.atividades[index].esforco){
            let valores = ['Alterou esforço da Atividade', item.esforco, newOS.atividades[index].esforco];
            log.push(valores);
          }
        });
      }
    }

    if (log.length > 0) {
      let valores = ['datalog', moment().format('DD/MM/YYYY HH:mm')];
      log.push(valores);
    }
    return log;
  }

  public getLocalDesc(codLocal){
    if (codLocal == 0){
      return "Interno";
    } else {
      return "Externo";
    }
  }

  public comparaUltimoContato(oldCliente, newCliente){
    let log = [];
    if (oldCliente.observContato != newCliente.observContato){
      let valores = ['Alterou descrição', oldCliente.observContato, newCliente.observContato];
      log.push(valores);
    }
    if (oldCliente.ultimoContato != newCliente.ultimoContato){
      let valores = ['Alterou data', oldCliente.ultimoContato, newCliente.ultimoContato];
      log.push(valores);
    }

    if (log.length > 0) {
      let valores = ['datalog', moment().format('DD/MM/YYYY HH:mm')];
      log.push(valores);
    }
    return log;
  }

  public comparaInventario(oldCliente, newCliente){
    
  }

}
