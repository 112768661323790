import { Injectable, OnInit } from '@angular/core';

import { AngularFireAuth } from 'angularfire2/auth';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import * as firebaseApp from 'firebase/app';
import { User } from '../../classes/user';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class DadosBancoService {
  dataBase: string = '';

  ultimoCodigoOS: number = 0;
  ultimoCodigoCliente: number = 0;

  tecnicos: any[] = [];
  clientes: any[] = [];
  situacoes: any[] = [];
  usuarios: any[] = [];

  usuAtivo: User;
  isAdmin: boolean = false;

  constructor(public db: AngularFireDatabase, private afAuth: AngularFireAuth) {
    //console.log('criou serviço.');

    let obsUser = this.afAuth.authState.subscribe((user: firebase.User) => {
      let obsUser2 = this.db.object("/usuarios").snapshotChanges().map(action => {
           const data = action.payload.toJSON();
           return data;
         }).subscribe(result => {
            this.tecnicos = [];
            this.usuarios = [];
            Object.keys(result).map(key=> {
              if (user && result[key].email.toLowerCase() == user.email.toLowerCase()){
                let base = JSON.parse(localStorage.getItem("system-base")).base;
                if (result[key].base == base) {
                  this.usuAtivo = result[key];
                  this.isAdmin = this.usuAtivo.adm;
                  this.dataBase = this.usuAtivo.base;
                }  
              }
              this.usuarios.push({ 'key': key, 'data':result[key]});
              if (result[key].tecnico && result[key].ativo){
                this.tecnicos.push({ 'key': key, 'data':result[key]});
              }
            });
            //if (this.dataBase != ''){
              this.carregaDados();
            //}
            obsUser2.unsubscribe;
      });
      obsUser.unsubscribe;
    });
  }

  getDataBase() {
    if (this.dataBase == 'agb') {
      return 'agb-computadores';
    } else {
      return this.dataBase;
    }
  }

  private carregaDados(){

    let obsCli = this.db.object("/dados/clientes").snapshotChanges().map(action => {
         const data = action.payload.toJSON();
         return data;
       }).subscribe(result => {
          this.clientes = [];
          if (result){
            Object.keys(result).map(key=> {
                 this.clientes.push({ 'key': key, 'data':result[key], 'nome': result[key].nomeFantasia});
            });
          }
          obsCli.unsubscribe;
    });

    this.db.object("/dados/situacoes/").snapshotChanges().map(action => {
         const data = action.payload.toJSON();
         return data;
       }).subscribe(result => {
          this.situacoes = [];
          if (result){
            Object.keys(result).map(key=> {
              this.situacoes.push({ 'key': key, 'situacao':result[key].situacao, 'valor':result[key].valor});
            });
          }
    });
  }

  public getNovoCodigoCliente(){
    firebase.database().ref(this.dataBase + "/utils/ultimoCodigoCliente/value").once('value').then((snapshot) => {
        this.ultimoCodigoCliente = snapshot.val();
    });

    return this.ultimoCodigoCliente + 1;
  }

  public getNovoCodigoOS(){
    firebase.database().ref(this.dataBase + "/utils/ultimoCodigoOS/value").once('value').then((snapshot) => {
      this.ultimoCodigoOS = snapshot.val();
    });

    return this.ultimoCodigoOS + 1;
  }


  public getTecnicos(){
    return this.tecnicos;
  }

  public getDataBaseName(){
    return this.dataBase;
  }

  public getUsuarioAtivo(){
    return this.usuAtivo;
  }

  public getIsAdmin(){
    return this.isAdmin;
  }

  public getClientes(){
    return this.clientes;
  }

  public getSituacoes(){
    return this.situacoes;
  }

  public getUsuarios(){
    return this.usuarios;
  }

  public getClientesObservable(): Observable<any>{
    return this.db.object(this.getDataBaseName() + "/clientes").snapshotChanges().map(action => {
         const data = action.payload.toJSON();
         return data;
    });
  }

  public getCliente(codigo): Observable<any> {
    return this.db.object(this.getDataBaseName() + "/clientes/" + codigo).snapshotChanges().map(action => {
         const data = action.payload.toJSON();

         if (data['ativo'] == undefined) {
           data['ativo'] = true;
         }
         return data;
    });
  }

}
