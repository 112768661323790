import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CadastroComponent } from './cadastro/cadastro.component';
import { DialogService } from "../../ng2-bootstrap-modal";
import { Http } from '@angular/http';
import { AngularFireAuth } from 'angularfire2/auth';
import { first } from 'rxjs/operators';
import * as moment from 'moment';
import * as firebase from 'firebase';

import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';

import { Orcamento } from '../../classes/orcamento';
import { User } from '../../classes/user';

@Component({
  selector: 'app-orcamento',
  templateUrl: './orcamento.component.html',
  styleUrls: ['./orcamento.component.css']
})
export class OrcamentoComponent implements OnInit {
  mostrandoEncerrados = false;
  userData: any = undefined;
  clientes: any[] = [];
  orcamentos: any[] = [];
  orcamentosEncerrados: any[] = [];
  orcamentosFiltrado: any[] = [];
  orcamentosEncerradosFiltrado: any[] = [];
  usuarios: any[] = [];
  colabs: any[] = [];
  ultimoCodigoOrc: number = 0;  

  orcamentoAberto: AngularFireList<any>;
  orcamentoEncerrado: AngularFireList<any>;
  logsList: AngularFireList<any>;
  
  usuAtivo: User;
  dataBase: String = '';
  loaded = false;
  clientesEmOrc: any[] = [];
  clientesEmOrcEncerrado: any[] = [];

  constructor(
          private router: Router, 
          private dialogService: DialogService,
          private afAuth: AngularFireAuth,
          public db: AngularFireDatabase,
          private http: Http) { 
  }

  async getUserData() {
    if (!this.userData) {
      this.userData = await this.afAuth.authState.pipe(first()).toPromise();
    }
    return this.userData;
  }

  async getUserToken() {
    let data = await this.getUserData()
    return data['pa'];
  }

  async getUsers() {
    if (this.usuarios.length == 0) {
      //let token = await this.getUserToken();
       let data = await this.http.get(`https://agb-os.firebaseio.com/usuarios/.json`).toPromise(); 
       this.usuarios = this.convertObjToArray(data)
    }
    return this.usuarios;
  }  

  async loadDataUser() {
    let users = await this.getUsers();
    let userData = await this.getUserData();
    let base = JSON.parse(localStorage.getItem("system-base")).base;

    users.forEach((item) => {
      if (item.base == base) {
        if (item.email.toLowerCase() == userData['email'].toLowerCase()){        
          this.usuAtivo = item;
          this.dataBase = this.usuAtivo.base; 
        }
        if (item.ativo) {
          this.colabs.push(item);
        }
      }
    })
  }

  async loadCustomer() {
    let token = await this.getUserToken();
    let data = await this.http.get(`https://agb-os.firebaseio.com/${this.dataBase}/clientes/.json?auth=${token}`).toPromise();    
    this.clientes = this.convertObjToArray(data);
  }

  async loadProspect() {
    let token = await this.getUserToken();
    let data = await this.http.get(`https://agb-os.firebaseio.com/${this.dataBase}/prospect/.json?auth=${token}`).toPromise();    
    this.clientes = this.clientes.concat(this.convertObjToArray(data))
  }

  async loadOrc() {
    let token = await this.getUserToken();
    let data = await this.http.get(`https://agb-os.firebaseio.com/${this.dataBase}/orcamento/aberto/.json?auth=${token}`).toPromise();    
    this.orcamentos = this.convertObjToArray(data);

    this.orcamentos.sort((a,b) => {
      if (moment(a.dataGerado, '').isAfter(moment(b.dataGerado))) {
        return -1;
      } else {
        if (moment(b.dataGerado, '').isAfter(moment(a.dataGerado))) {
          return 1;
        } else {
          return 0;
        }
      }
    });
    this.clientesEmOrc = [];
    this.orcamentos.forEach((item) => {
      let cliente = this.getDadosClientePorCod(item.codigoCliente);
      if (cliente) {
        item['nomeCliente'] = cliente.nomeFantasia;
        item['contato'] = cliente.contato;
        item['telefone'] = cliente.telefone;
        item['celular'] = cliente.celular;
        item['cidade'] = cliente.cidade;
        item['valorTotal'] = this.getValorTotal(item).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  
        if (this.clientesEmOrc.indexOf(cliente.nomeFantasia) == -1) {
          this.clientesEmOrc.push(cliente.nomeFantasia);
        }
      }
    });

    this.aplicaFiltro();
  }

  async loadFinishedOrc() {
    let token = await this.getUserToken();
    let data = await this.http.get(`https://agb-os.firebaseio.com/${this.dataBase}/orcamento/encerrado/.json?auth=${token}`).toPromise();    
    this.orcamentosEncerrados = this.convertObjToArray(data);

    this.orcamentosEncerrados.sort((a,b) => {
      if (moment(a.dataGerado, '').isAfter(moment(b.dataGerado))) {
        return -1;
      } else {
        if (moment(b.dataGerado, '').isAfter(moment(a.dataGerado))) {
          return 1;
        } else {
          return 0;
        }
      }
    });
    this.clientesEmOrcEncerrado = [];
    this.orcamentosEncerrados.forEach((item) => {
      let cliente = this.getDadosClientePorCod(item.codigoCliente);
      if (cliente) {
        item['nomeCliente'] = cliente.nomeFantasia;
        item['contato'] = cliente.contato;
        item['telefone'] = cliente.telefone;
        item['celular'] = cliente.celular;
        item['cidade'] = cliente.cidade;
        item['valorTotal'] = this.getValorTotal(item).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
        
        if (this.clientesEmOrcEncerrado.indexOf(cliente.nomeFantasia) == -1) {
          this.clientesEmOrcEncerrado.push(cliente.nomeFantasia);
        }
      }
    });
    
    this.aplicaFiltro();
  }  

  getValorTotal(orc) {
    let total = 0;
    if (orc.itemsOrcamento) {
      orc.itemsOrcamento.forEach((item) => {
        if (this.dataBase !== 'drrafael') {
          if (item.valorUnitario && item.qtd) {
            total += item.valorUnitario * item.qtd;
          }
        } else {
          total += item.valorUnitario;
        }
      });
    }
    if (this.dataBase == 'drrafael' && orc.desconto) {
     total -= orc.desconto;
    }
    return total;
  }
  

  convertObjToArray(obj) {
    let objs = JSON.parse(obj['_body']);
    if (obj && objs) {
      return Object.keys(objs).map( (item) => {
              let data = objs[item];
              data['id'] = item;
              return data;
            });
    }
    return [];
  }

  async ngOnInit() {
    await this.loadDataUser();
    await this.loadCustomer();
    await this.loadProspect();
    await this.loadOrc();
    this.aplicaFiltro();
    this.logsList = this.db.list(this.dataBase + "/logs/");
    this.orcamentoAberto = this.db.list(this.dataBase + "/orcamento/aberto/");
    this.orcamentoEncerrado = this.db.list(this.dataBase + "/orcamento/encerrado/");

    this.loaded = true;
  }

  public redirect(page, id){
    if (id){
      this.router.navigateByUrl('/' + page + '/' + id);
    }else{
      this.router.navigateByUrl('/' + page);
    }
  }

  async mostrarAbaEncerrados(){
    this.mostrandoEncerrados = true;
    await this.loadFinishedOrc();
    this.limparFiltros();
  }

  novoOrcamento() {
    var newOrc = new Orcamento();
    newOrc.dataGerado = moment().format('YYYY-MM-DDTHH:mm');

    firebase.database().ref(this.dataBase + "/utils/ultimoCodigoOrc/value").once('value').then((snapshot) => {
      this.ultimoCodigoOrc = snapshot.val();
      this.ultimoCodigoOrc++;
      newOrc.codigo = this.ultimoCodigoOrc;
      firebase.database().ref(this.dataBase + '/utils/ultimoCodigoOrc/').set({
          value: newOrc.codigo
        });
    
      let disposable = this.dialogService.addDialog(CadastroComponent, {
        title:'Criando Orçamento',
        orcamento: newOrc,
        clienteList: this.clientes,
        modoTela: 0,
        colabList: this.colabs
      }).subscribe((isConfirmed)=>{
        if(isConfirmed) {
          Object.keys(newOrc).forEach(key => newOrc[key] === undefined && delete newOrc[key]);
          let myRef = this.orcamentoAberto.push(newOrc);
          this.logsList.push({usuario: this.usuAtivo.email, acao: "Criou", codigo: newOrc.codigo, tipo: "Orçamento", chaveReg: myRef.key, registros: JSON.stringify(newOrc), data: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')});

          this.loadOrc();
        }else{
        }
      });
    });
  }

  editarOrcamento(orc){
    var OrcBkp = Object.assign({}, orc);
    var OrcCopy = Object.assign({}, orc);


    let disposable = this.dialogService.addDialog(CadastroComponent, {
      title:'Alterando Orçamento #' + OrcCopy.codigo,
      orcamento: OrcCopy,
      clienteList: this.clientes,
      modoTela: 0,
      colabList: this.colabs
    }).subscribe((isConfirmed)=>{
      if(isConfirmed) {
        OrcCopy.dataGerado = moment().format('YYYY-MM-DDTHH:mm');
        if (OrcBkp.status == 'Orçado' && OrcCopy.status == 'Orçado') {
          this.orcamentoAberto.update(OrcCopy.id, OrcCopy);
          this.loadOrc();
        } else {
          if (OrcBkp.status == 'Orçado' && OrcCopy.status != 'Orçado') {
            if (OrcCopy.status == 'Aprovado') {
              let cliente = this.getDadosClientePorCod(orc.codigoCliente);
              if (cliente.prospect) {
                if (!cliente.id) {
                  alert('Não foi possível fazer a conversão automática de PROSPECT para CLIENTE.');
                } else {
                  cliente.prospect = false;
                  this.db.list(this.dataBase + "/prospect").remove(cliente.id);
                  this.db.list(this.dataBase + "/clientes").set(cliente.id, cliente);
                }                 
              }
            }
            
            this.encerraOrcamento(OrcCopy);
          } else {
            if (OrcBkp.status != 'Orçado' && OrcCopy.status == 'Orçado') {
              this.reabrirOrcamento(OrcCopy);          
            } else {
              if (OrcBkp.status != 'Orçado' && OrcCopy.status != 'Orçado') {
                this.orcamentoEncerrado.update(OrcCopy.id, OrcCopy);   
                this.loadFinishedOrc();     
              }
            }
          }
        }

        this.logsList.push({usuario: this.usuAtivo.email, acao: "Alterou", codigo: OrcCopy.codigo, tipo: "Orçamento", chaveReg: OrcCopy.id, registros: JSON.stringify(OrcCopy), data: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')});

        this.loadOrc();
      }else{
      }
    });

  }

  // Será chamado quando o status mudar na edição
  // Na criação o status deve ser Em Andamento por padrão, só depois de criado permitir alterar
  encerraOrcamento(orc){
    Object.keys(orc).forEach(key => orc[key] === undefined && delete orc[key]);
    this.orcamentoAberto.remove(orc.id);
    this.orcamentoEncerrado.push(orc);
    this.loadOrc();
  }

  reabrirOrcamento(orc){
    Object.keys(orc).forEach(key => orc[key] === undefined && delete orc[key]);
    this.orcamentoEncerrado.remove(orc.id);
    this.orcamentoAberto.push(orc);
    this.loadFinishedOrc();
  }

  getDadosClientePorCod(cod) {
    let result = undefined;
    this.clientes.forEach((item) => {
      if (item.codCliente == cod) {
        result = item;
      }
    });
    return result;
  }

  formataData(data, comHora) {
    if (comHora) {
      return moment(data).format('DD/MM/YYYY HH:mm');
    } else {
      return moment(data).format('DD/MM/YYYY');
    }
  }

  deletarOrcamento(orc, aberto) {
    Object.keys(orc).forEach(key => orc[key] === undefined && delete orc[key]);
    if (confirm('Deseja excluir o orçamento?')) {
      if (aberto) {
        this.orcamentoAberto.remove(orc.id);
        this.loadOrc();
      } else {
        this.orcamentoEncerrado.remove(orc.id);
        this.loadFinishedOrc();
      }
    }
  }

  filtroCliente: string = '';
  filtroStatus: string = '';
  filtroCodigo: string = '';
  public filtraCliente(valor){
    this.filtroCliente = valor;
    this.aplicaFiltro();
  }

  public filtraStatus(valor){
    this.filtroStatus = valor;
    this.aplicaFiltro();
  }

  public filtraCodigo(valor){
    this.filtroCodigo = valor;
    this.aplicaFiltro();
  }

  limparFiltros() {
    this.filtroCodigo = '';
    this.filtroStatus = '';
    this.filtroCliente = '';
    this.aplicaFiltro();
  }

  public filtro(orc){
    let respeitaFiltro = true;
    if (this.filtroCliente != "" && this.filtroCliente.toLowerCase() != orc.nomeCliente.toLowerCase()){
      respeitaFiltro = false;
    }
    if (this.filtroStatus != "" && this.filtroStatus.toLowerCase() != orc.status.toLowerCase()){
      respeitaFiltro = false;
    }
    if (this.filtroCodigo != "" && this.filtroCodigo != orc.codigo){
      respeitaFiltro = false;
    }

    return respeitaFiltro;
  }

  public aplicaFiltro(){
    if (this.mostrandoEncerrados) {
      this.orcamentosEncerradosFiltrado = [];
      this.orcamentosEncerrados.forEach((item) => {
        if (this.filtro(item)){
          this.orcamentosEncerradosFiltrado.push(item);
        }
      });

    } else {
      this.orcamentosFiltrado = [];
      this.orcamentos.forEach((item) => {
        if (this.filtro(item)){
          this.orcamentosFiltrado.push(item);
        }
      });
    }
  }

  transformCelPhoneNumber(value: string): any {
    if (value) {
      const regex: RegExp = new RegExp(/(\d{2})(\d{1})(\d{4})(\d{4})/);
      const match = value.match(regex);
      if(match) {
        return `(${match[1]}) ${match[2]} ${match[3]}-${match[4]}`;
      } else {
        return value;
      }
    }
    return '';
  }


  transformPhoneNumber(value: string): any {
    if (value) {
      const regex: RegExp = new RegExp(/(\d{2})(\d{4})(\d{4})/);
      const match = value.match(regex);
      if(match) {
        return `(${match[1]}) ${match[2]}-${match[3]}`;
      } else {
        return value;
      }
    }
    return '';
  }

  openedOrc = true;
  selectOpenedOrc(opened) {
    if (!opened) {
      this.mostrarAbaEncerrados();
    }
    this.openedOrc = opened;
  }
}
