import { Component, OnInit } from '@angular/core';

import { AngularFireDatabase } from 'angularfire2/database';
import { AngularFireAuth } from 'angularfire2/auth';
import * as moment from 'moment';

import { User } from '../../../../classes/user';
import { NgxSpinnerService } from 'ngx-spinner';
import * as env from "../../../../../environments/environment";

@Component({
  selector: 'app-tecnico-horas-mobile',
  templateUrl: './tecnico-horas-mobile.component.html',
  styleUrls: ['./tecnico-horas-mobile.component.css']
})
export class TecnicoHorasMobileComponent implements OnInit {
  dataBase = '';
  usuAtivo: User;
  clientes: any[] = [];
  relTecPorData: {tecnico: String, totalHoras: number, listaData: {data: String, totalHoras: number, listaEsforcos: {item: any, cliente: String, paralelo: boolean, codigoOS: number, situacao: string}[]}[] }[] = [];
  dataIniCampo = '';
  dataFimCampo = '';
  showingReport = false;

  constructor(private afAuth: AngularFireAuth, public db: AngularFireDatabase, private spinner: NgxSpinnerService) { 
    let dataIni = moment().date(1);
    let dataFim = moment().add('months', 1).date(1).subtract('days', 1);
    dataFim.hours(23);
    dataFim.minutes(59);
    this.dataIniCampo = dataIni.format('YYYY-MM-DD');
    this.dataFimCampo = dataFim.format('YYYY-MM-DD');

    afAuth.authState.subscribe((user: firebase.User) => {
      this.db.object("/usuarios").snapshotChanges().map(action => {
           const data = action.payload.toJSON();
           return data;
         }).subscribe(result => {
            Object.keys(result).map(key=> {
              if (user && result[key].email.toLowerCase() == user.email.toLowerCase()){
                let base = JSON.parse(localStorage.getItem("system-base")).base;
                if (result[key].base == base) {
                  this.usuAtivo = result[key];
                  this.dataBase = this.usuAtivo.base;
                }  
              }
            });
            this.carregaCliente();
      });
    });    
  }

  ngOnInit() {
  }

  getBaseURL(username = undefined) {
    let base;
    if (username) {
      base = username.substring(username.indexOf('@')+1, username.length);
    } else {
      base = this.dataBase;
    }

    if (base == 'agb') {
      base = 'agb-computadores';
    }
    
    let url = env.environment.url_firebase.replace('###', base);
    return url;
  }

  private carregaCliente(){
    let dbRef = this.db.database.app.database(this.getBaseURL()).ref(`/dados/clientes`);
    this.db.object(dbRef).snapshotChanges().map(action => {
         const data = action.payload.toJSON();
         return data;
       }).subscribe(result => {
          this.clientes = [];
          if (result){
            Object.keys(result).map(key=> {
                 this.clientes.push({ 'key': key, 'data':result[key], 'nome': result[key].nomeFantasia });
            });
          }
    });
  }  

  public getClienteDataPorCodigo(codigo){
    let iCliente;
    this.clientes.forEach((cliente, index) => {
      if (cliente.data.codCliente == codigo){
        iCliente =  cliente;
      }
    });
    return iCliente;
  }

  private minutosParaHoras(minParams){
    let horas = Math.floor(minParams / 60);
    let minutes = minParams % 60;

    return (horas < 10 ? "0" + horas : horas) + ":" +
           (minutes < 10 ? "0" + minutes : minutes);
  }
  

  private verificaAtendimentoParalelo(lista, item, itemEsforco){
    let retorno = false;

    let inicio = moment(itemEsforco.data+itemEsforco.inicio, 'DD/MM/YYYYHH:mm');
    let fim = moment(itemEsforco.data+itemEsforco.fim, 'DD/MM/YYYYHH:mm');
    lista.forEach((itemTec) => {
      if (itemTec.tecnico == item.tecnico){
          if (itemTec.codCliente != item.codCliente ||
              itemTec.inicioEsforco != itemEsforco.inicio ||
              itemTec.fimEsforco != itemEsforco.fim ||
              itemTec.dataAtendimento != itemEsforco.data){

            let atendimentoInicio = moment(itemTec.dataAtendimento + "" + itemTec.inicioEsforco, 'DD/MM/YYYYHH:mm');
            let atendimentoFim = moment(itemTec.dataAtendimento + "" + itemTec.fimEsforco, 'DD/MM/YYYYHH:mm');

            if (atendimentoInicio.isBetween(inicio, fim) ||
                atendimentoFim.isBetween(inicio, fim) ||
                inicio.isBetween(atendimentoInicio, atendimentoFim) ||
                fim.isBetween(atendimentoInicio, atendimentoFim)){
              retorno = true;
            }
          }
      }

    });

    return retorno;
  }

  public changeDate() {
    this.showingReport = false;
  }

  public geraRelatorioTecnico(){
    let OSporTecnico: {tecnico: String, dataAtendimento: String, codCliente: number, codigoOS: number, situacao: string, inicioEsforco: String, fimEsforco: String, paralelo: boolean}[] = [];

    this.spinner.show();
    let OSObservable = this.db.object(this.dataBase + "/ordemServico/concluidas").snapshotChanges().map(action => {
         const data = action.payload.toJSON();
         return data;
       }).subscribe(result => {
        let dataIni = moment(this.dataIniCampo, 'YYYY-MM-DD');
        let dataFim = moment(this.dataFimCampo, 'YYYY-MM-DD');
         dataFim.hours(23);
         dataFim.minutes(59);
         

         Object.keys(result).map(date=> {
           Object.keys(result[date]).map(key => {
             if (result[date][key].atividades) {
                for (let i =0; i < JSON.parse(result[date][key].atividades).length; i++ ){
                  let atividade = JSON.parse(result[date][key].atividades)[i];
                  atividade.esforcos.forEach((item) => {
                    let dataEsforco = moment(item.data + item.inicio, 'DD/MM/YYYYHH:mm');
                    if (item.tecnico && dataEsforco.isBetween(dataIni, dataFim)){
                      OSporTecnico.push({'tecnico': item.tecnico, 'dataAtendimento': item.data, 'codCliente': result[date][key].codCliente, 'codigoOS': result[date][key].codigo, 'situacao': result[date][key].situacao, 'inicioEsforco': item.inicio, 'fimEsforco': item.fim, 'paralelo': false});
                    }
                  });
                }
              }
            })
         });


         let OSObservable = this.db.object(this.dataBase + "/ordemServico/abertas").snapshotChanges().map(action => {
              const data = action.payload.toJSON();
              return data;
            }).subscribe(result => {
              Object.keys(result).map(key=> {
                if (result[key].atividades) {
                   for (let i =0; i < JSON.parse(result[key].atividades).length; i++ ){
                     let atividade = JSON.parse(result[key].atividades)[i];
                     atividade.esforcos.forEach((item) => {
                       let dataEsforco = moment(item.data + item.inicio, 'DD/MM/YYYYHH:mm');
                       if (item.tecnico && dataEsforco.isBetween(dataIni, dataFim)){
                         OSporTecnico.push({'tecnico': item.tecnico, 'dataAtendimento': item.data, 'codCliente': result[key].codCliente, 'codigoOS': result[key].codigo, 'situacao': result[key].situacao, 'inicioEsforco': item.inicio, 'fimEsforco': item.fim, 'paralelo': false});
                       }
                     });
                   }
                 }
              });

              OSporTecnico.sort((n1,n2) => {
                if (n1.tecnico > n2.tecnico) {
                  return 1
                } else {
                  if (n1.tecnico < n2.tecnico) {
                    return -1
                  }
                }

                if (n1.dataAtendimento > n2.dataAtendimento) {
                  return 1
                } else {
                  if (n1.dataAtendimento < n2.dataAtendimento) {
                    return -1
                  }
                }

                if (n1.inicioEsforco > n2.inicioEsforco) {
                  return 1
                } else {
                  if (n1.inicioEsforco < n2.inicioEsforco) {
                   return -1
                 }
                }

                if (n1.fimEsforco > n2.fimEsforco) {
                  return 1
                } else {
                  if (n1.fimEsforco < n2.fimEsforco) {
                   return -1
                 } else {
                   return 0;
                 }
                }
              });

              // Verifica atendimento paralelo
              // {tecnico: String, listaCliente: {codCliente: number, listaEsforcos: {item: any, paralelo: boolean}[]}[] }[] = [];
              //OSporTecnico: {tecnico: String, dataAtendimento: String, codCliente: number, inicioEsforco: String, fimEsforco: String, paralelo: boolean}[] = [];
              //let relTecPorData: {tecnico: String, totalHoras: number, listaData: {data: String, listaEsforcos: {item: any, cliente: number, paralelo: boolean}[]}[] }[] = [];
              this.relTecPorData = [];
              let tecCorrente: String = '';
              let dataCorrente: String = '';
              let indexTec = -1;
              let indexData = -1;
              OSporTecnico.forEach((itemTec) => {
                itemTec.paralelo = this.verificaAtendimentoParalelo(OSporTecnico, itemTec, {'data': itemTec.dataAtendimento, 'inicio': itemTec.inicioEsforco, 'fim': itemTec.fimEsforco});
                let clienteData = this.getClienteDataPorCodigo(itemTec.codCliente);

                let clienteNome: String = '';
                if (clienteData) {
                  clienteNome = clienteData.nome;
                } else {
                  clienteNome = itemTec.tecnico;
                }

                if (clienteData || this.dataBase == 'gessner') {

                  if (tecCorrente != itemTec.tecnico){
                    let inicio = moment(itemTec.dataAtendimento+''+itemTec.inicioEsforco, 'DD/MM/YYYYHH:mm');
                    let fim = moment(itemTec.dataAtendimento+''+itemTec.fimEsforco, 'DD/MM/YYYYHH:mm');
                    let minutes = moment.duration(fim.diff(inicio)).asMinutes();

                    this.relTecPorData.push({'tecnico': itemTec.tecnico, 'totalHoras': minutes, 'listaData':
                                              [{'data': itemTec.dataAtendimento, 'totalHoras': minutes,'listaEsforcos':
                                              [{'item': {'inicio': itemTec.inicioEsforco, 'fim': itemTec.fimEsforco},
                                                'cliente': clienteNome, 'paralelo': itemTec.paralelo, 'codigoOS': itemTec.codigoOS,
                                                'situacao': itemTec.situacao}]
                                          }]
                                        });
                    indexTec++;
                    indexData = 0;
                  } else {
                    let inicio = moment(itemTec.dataAtendimento+''+itemTec.inicioEsforco, 'DD/MM/YYYYHH:mm');
                    let fim = moment(itemTec.dataAtendimento+''+itemTec.fimEsforco, 'DD/MM/YYYYHH:mm');
                    let minutes = moment.duration(fim.diff(inicio)).asMinutes();

                    this.relTecPorData[indexTec].totalHoras += minutes;
                    if (dataCorrente != itemTec.dataAtendimento){
                        this.relTecPorData[indexTec].listaData.push({'data': itemTec.dataAtendimento, 'totalHoras': minutes, 'listaEsforcos':
                                                                    [{'item': {'inicio': itemTec.inicioEsforco, 'fim': itemTec.fimEsforco},
                                                                      'cliente': clienteNome, 'paralelo': itemTec.paralelo, 'codigoOS': itemTec.codigoOS,
                                                                      'situacao': itemTec.situacao}]
                                                                });
                        indexData++;
                    } else {
                      this.relTecPorData[indexTec].listaData[indexData].totalHoras += minutes;
                        this.relTecPorData[indexTec].listaData[indexData].listaEsforcos.push(
                                  {'item': {'inicio': itemTec.inicioEsforco, 'fim': itemTec.fimEsforco}, 'cliente': clienteNome,
                                    'paralelo': itemTec.paralelo, 'codigoOS': itemTec.codigoOS, 'situacao': itemTec.situacao});
                    }
                  }
                  tecCorrente = itemTec.tecnico;
                  dataCorrente = itemTec.dataAtendimento;
                }
              });
              
              if (this.relTecPorData.length > 0) {
                this.showingReport = true;
              } else { 
                this.showingReport = false;
              }
              this.spinner.hide();
              OSObservable.unsubscribe();
            });
       });
  }
}
