import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import {  DialogService } from "../../ng2-bootstrap-modal";
import * as moment from 'moment';
import { OsFormComponent } from '../dialogs/os-form/os-form.component';
import { AngularFireAuth } from 'angularfire2/auth';
import { User } from '../../classes/user';
import { ordemServico } from '../../classes/ordemServico';
import { NovoClienteComponent } from '../dialogs/novo-cliente/novo-cliente.component';
import { LogDetailsComponent } from '../log/log-details/log-details.component';

@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.css']
})
export class LogComponent implements OnInit {
  dataBase = 'prod';
  logs: any[] = [];
  clientes: any[] = [];
  tecnicos: any[] = [];
  usuAtivo: User;
  apagando = false;
  itensApagados = 0;
  isAdmin: boolean = false;
  isMaster: boolean = false;

  constructor(private afAuth: AngularFireAuth, private router: Router, public db: AngularFireDatabase, private dialogService: DialogService) {


    afAuth.authState.subscribe((user: firebase.User) => {
      let ObsUser = this.db.object("/usuarios").snapshotChanges().map(action => {
           const data = action.payload.toJSON();
           return data;
         }).subscribe(result => {
             Object.keys(result).map(key=> {
               if (user && result[key].email.toLowerCase() == user.email.toLowerCase()){
                 let base = JSON.parse(localStorage.getItem("system-base")).base;
                 if (result[key].base == base) {
                   this.usuAtivo = result[key];
                   this.dataBase = this.usuAtivo.base;
                   this.isAdmin = this.usuAtivo.adm;
                   this.isMaster = this.usuAtivo.master;
                 }  
               }
               if (result[key].tecnico){
                 this.tecnicos.push({ 'key': key, 'data':result[key] });
               }
             });
             this.carregaDados();

             ObsUser.unsubscribe();
      });
    });

  }

  private carregaDados(){
      this.carregaLogs('');

      let ObsCliente = this.db.object(this.dataBase + "/clientes").snapshotChanges().map(action => {
           const data = action.payload.toJSON();
           return data;
         }).subscribe(result => {
            this.clientes = [];
            if (result){
              Object.keys(result).map(key=> {
                   this.clientes.push({ 'key': key, 'data':result[key], 'nome': result[key].nomeFantasia});
              });
            }
            ObsCliente.unsubscribe();
      });
  }

  public carregaLogs(filtro){
    let ObsLog = this.db.object(this.dataBase + "/logs").snapshotChanges().map(action => {
         const data = action.payload.toJSON();
         return data;
       }).subscribe(result => {
          this.logs = [];
          if (result){
            Object.keys(result).map(key=> {
               if (filtro == '' || filtro == result[key].codigo){
                 this.logs.push({ 'key': key, 'data': result[key] });
               }
            });
            this.logs.reverse();
          }
          ObsLog.unsubscribe();
    });
  }

  ngOnInit() {
  }

  public redirect(page){
    this.router.navigateByUrl('/' + page);
  }

  public formataData(item){
    return moment(item.data).format('DD/MM/YYYY HH:mm');
  }

  public podeMostrarDados(item) {
    return item.data.acao == 'Criou' || item.data.acao == 'Alterou' || item.data.acao == 'Alterou último contato';
  }

  public verDados(item){

    if (item.data.tipo == 'OS'){
      if (item.data.acao == 'Criou' || moment(item.data.data, 'DD-MM-YYYY HH:mm').isBefore(moment('23-03-2018 21:05', 'DD-MM-YYYY HH:mm'))){
        let disposable = this.dialogService.addDialog(OsFormComponent, {
          title:'Visualizando OS #' + JSON.parse(item.data.registros).codigo,
          message:'Confirm message',
          itemOS: JSON.parse(item.data.registros),
          clienteList: this.clientes,
          modoTela: 2,
          tecList: this.tecnicos,
          usuAtivo: this.usuAtivo
        }).subscribe((isConfirmed)=>{
          if(isConfirmed) {
          }else{
          }
        });
      } else {
        if (item.data.acao == 'Alterou'){

          let disposable = this.dialogService.addDialog(LogDetailsComponent, {
            title:'Alterações OS #' + item.data.codigo,
            message:'Confirm message',
            log: item
          }).subscribe((isConfirmed)=>{
            if(isConfirmed) {
            }else{
            }
          });
        }
      }
    } else {
       if (item.data.acao == 'Alterou último contato'){
         let disposable = this.dialogService.addDialog(LogDetailsComponent, {
           title:'Alterações último contato - #' + item.data.codigo,
           message:'Confirm message',
           log: item
         }).subscribe((isConfirmed)=>{
           if(isConfirmed) {
           }else{
           }
         });
       } else {
          var clienteCopy = Object.assign({}, JSON.parse(item.data.registros));
          let disposable = this.dialogService.addDialog(NovoClienteComponent, {
            title:'Adicionando Cliente',
            message:'Confirm message',
            newCliente: clienteCopy,
            isAdmin: this.isAdmin,
            isMaster: this.isMaster,
            database: this.dataBase
          }).subscribe((isConfirmed)=>{
            if(isConfirmed) {
            }else{
            }
          });
       }
    }
  }

  public aplicaFiltro(valor){
    this.carregaLogs(valor);
  }

  public limparLogs(){
    this.apagando = true;
    let prazoLogs = moment();
    prazoLogs.month(prazoLogs.month()-2);
    let logsList = this.db.list(this.dataBase + "/logs/");

    this.logs.forEach((item) => {
      let dataLog = moment(item.data.data, "DD-MM-YYYY HH:mm");
      if (prazoLogs.isAfter(dataLog)){
        logsList.remove(item.key);
        this.itensApagados++;
      }
    });
    this.apagando = false;
  }

}
