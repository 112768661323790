import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'searchFilter'})
export class SearchFilterPipe implements PipeTransform {

  transform(items: Array<string>, field: string, field2: string, value: string): Array<string> {
     if (!items || value == "") return [];
     return items.filter(
       it => {
         var xFound = it[field].toLowerCase().indexOf(value.toLowerCase()) !== -1;
         if (!xFound){
           xFound = it[field2].toLowerCase().indexOf(value.toLowerCase()) !== -1;
         }
         return xFound;
      }
       //it => (it['data'].solucao.toLowerCase().indexOf(value.toLowerCase()) !== -1) || (it['data'].descricao.toLowerCase().indexOf(value.toLowerCase()))
     );
   }

}
