export class Orcamento {

    public codigo: number = 0;
    public codigoCliente: number = 0;
    public contatoCliente: string = '';
    public emailCliente: string = '';

    public status: String = "Orçado"; // Orçado / Aprovado / Cancelado
    public dataGerado: String = "";
    public validadeOrc: number = 0; // Em dias
    public condicaoPag: String = "À VISTA";
    public itemsOrcamento: any[] = [];
    public Observacao: String;
    public colaborador: String = '';
    public faturamento: String = 'AGB'; // Direto fornecedor / AGB
    public tipoFrete: String = 'FOB'; // FOB ou CIF
    public observacao: String = ''; // Acompanhamento
    public observacao2: String = ''; 
    public desconto: number = 0.0;

    constructor() {
    }

    getListaDeStatus() {
        return ['Orçado', 'Aprovado', 'Cancelado'];
    }
    getListaPagamento() {
        return ['À VISTA', 'À PRAZO']
    }

}
