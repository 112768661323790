import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DadosBancoService } from '../services/dados-banco.service';

@Component({
  selector: 'app-cliente',
  templateUrl: './cliente.component.html',
  styleUrls: ['./cliente.component.css']
})
export class ClienteComponent implements OnInit {
  private sub: any;
  cliente: any = null;
  codigo: number = 0;

  constructor(private route: ActivatedRoute, private dados: DadosBancoService) {}

  ngOnInit() {
    this.sub = this.route.params.subscribe(params => {
      this.codigo = params['cod'];
    });
    this.carregaCliente(this.codigo);
  }

  private carregaCliente(codigo){
    this.dados.getCliente(codigo).subscribe(result => {
        Object.keys(result).map(key=> {
          this.cliente = result;
        });
    });;
  }
}
