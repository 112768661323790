import { Component, OnInit } from '@angular/core';
import { DialogComponent, DialogService } from "../../../ng2-bootstrap-modal";
import * as moment from 'moment';
import { Estoque } from '../../../classes/estoque';

export interface ConfirmModel {
  title: string;
  message: string;
  itemEstoque: Estoque;
  tecList: any[];
  database: string;
}

@Component({
  selector: 'app-estoque-form-entrada',
  templateUrl: './estoque-form-entrada.component.html',
  styleUrls: ['./estoque-form-entrada.component.css']
})

export class EstoqueFormEntradaComponent extends DialogComponent<ConfirmModel, boolean> implements ConfirmModel, OnInit {
  title: string;
  message: string;
  itemEstoque: Estoque;
  tecList: any[];
  database: string;

  historicoEntrada = [];
  qtdExternaLista = [{qtdExterna: 0, tecnico: ""}];

  constructor(dialogService: DialogService) {
    super(dialogService);
  }

  confirm(form) {
    if (form.edtMotivo.value == ''){
      alert('É necessário informar um motivo.');
      return
    }
    let tecnicoRepetido = false;
    let tecnicoNotFound = false;

    this.itemEstoque.qtd += +form.edtQtdInterna.value;
    let qtdExternaTotal = 0;
    this.qtdExternaLista.forEach((item, index) => {
      if (item.qtdExterna != 0 && item.tecnico == '') {
        tecnicoNotFound = true;
      }

      this.qtdExternaLista.forEach((item2, index2) => {
        if (index2 != index && item.tecnico == item2.tecnico) {
          tecnicoRepetido = true;
        }
      });

      if (item.qtdExterna != 0) {
        qtdExternaTotal += item.qtdExterna;
        let added = false;
        this.itemEstoque.qtdExternaLista.forEach((itemOri) => {
          if (itemOri.tecnico == item.tecnico) {
            itemOri.qtdExterna += item.qtdExterna;
            added = true;
          }
        })
        if (!added) {
          this.itemEstoque.qtdExternaLista.push(item);
        }
        
      }      
    });
    this.itemEstoque.qtdExterna += qtdExternaTotal;

    if (tecnicoRepetido) { alert('Técnico não pode estar repetido no estoque externo.'); return }
    if (tecnicoNotFound) { alert('Técnico não informado no estoque externo.'); return }

    this.historicoEntrada.push({'data': moment().format('YYYY-MM-DDTHH:mm'),
                                'qtd': form.edtQtdInterna.value,
                                'qtdExterna': qtdExternaTotal,
                                'motivo': form.edtMotivo.value,
                                'Fornecedor': form.edtFornecedor.value,
                                'NotaFiscal': form.edtNotaFiscal ? form.edtNotaFiscal.value : ''});
    this.itemEstoque.historicoEntrada = JSON.stringify(this.historicoEntrada);

    this.result = true;
    this.close();
  }

  cancelar(form){
    this.close();
  }

  ngOnInit() {
    this.historicoEntrada = this.itemEstoque.historicoEntrada ? JSON.parse(this.itemEstoque.historicoEntrada) : [];
  }

  adicionarEstoqueTecnico(item) {
    this.qtdExternaLista.push({qtdExterna: 0, tecnico: ""});
  }

}
