export class User {

    public username: string = '';
    public email: string = '';
    public adm: boolean;
    public master: boolean;
    public tecnico: boolean;
    public nome: string = '';
    public ativo: boolean = true;
    public base: string = '';
    public primeiroLogon: boolean = true;
    public setor: string = '';

    constructor() {
    }

}
