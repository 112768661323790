export class cliente {

    public ativo: boolean = true;
    public prospect: boolean = true;
    public inicioContrato: String = '';
    public inicioContratoPlantao: String = '';
    public CNPJ: String = '';
    public CPF: String = '';
    public RG: String = '';
    public razaoSocial: String = '';
    public rua: String = '';
    public cidade: String = '';
    public bairro: String = '';
    public estado: String = '';
    public telefone: String = '';
    public telefone2: String = '';
    public celular: String = '';
    public whatsapp: String = '';
    public valor: number = 0.0;
    public valorNoturno: number = 0.0;
    public tipoPessoa: String = "PJ";
    //public valorMinimo: number = 75.0;

    public contrato: boolean = false;
    public tipoContrato: String = 'Mensal';
    public contratoPlantao: boolean = false;
    public tipoContratoPlantao: String = 'Mensal';
    public qtdHoras: number = 0;
    public qtdHorasPlantao: number = 0;
    public email: String = '';
    public dataCadastro: String = '';
    public status: String = '';
    public inventario: string = "[]";
    public saldo: any[] = [];
    public instEstadual: String = '';
    public contato: String = '';

    public ultimoContato: String = '';
    public observContato: String = '';
    public lembreteContato: String = '';

    public backup: boolean = false;
    public checklist: boolean = false;

    public firewall: boolean   = false;
    public monitoramento: boolean = false;
    public office365: boolean = false;
    public antivirus: boolean = false;
    public servidor: boolean = false;
    public dominio: boolean = false;
    public crashplan: boolean = false;
    public desenhoinfra: boolean = false;


    constructor(
      public codCliente: number,
      public nomeFantasia: String) {
    }

}
