import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { AngularFireAuth } from 'angularfire2/auth';
import { first } from 'rxjs/operators';
import { User } from '../../../classes/user';
import * as env from "../../../../environments/environment";

@Injectable()
export class UserService {
  userData: any = undefined;
  fireUserData: any = undefined;
  usuarios: any[] = [];
  usuAtivo: User;

  constructor(
      private http: Http,
      private afAuth: AngularFireAuth) { }

  async getFireUserData() {
    if (!this.fireUserData) {
      this.fireUserData = await this.afAuth.authState.pipe(first()).toPromise();
    }
    return this.fireUserData;
  }

  async getUserToken() {
    let data = await this.getFireUserData()
    return data['pa'];
  }

  async getUsers() {
    if (this.usuarios.length == 0) {
      //let token = await this.getUserToken();
       let data = await this.http.get(`https://agb-os.firebaseio.com/usuarios/.json`).toPromise(); 
       this.usuarios = this.convertObjToArray(data)
    }
    return this.usuarios;
  }  

  convertObjToArray(obj) {
    let objs = JSON.parse(obj['_body']);
    if (obj && objs) {
      return Object.keys(objs).map( (item) => {
              let data = objs[item];
              data['id'] = item;
              return data;
            });
    }
    return [];
  }

  
  getBaseURL(username = undefined) {
    let base;
    if (username) {
      base = username.substring(username.indexOf('@')+1, username.length);
    } else {
      base = JSON.parse(localStorage.getItem("system-base")).base;
    }
    if (base == 'agb') {
      base = 'agb-computadores';
    }
    
    let url = env.environment.url_firebase.replace('###', base);
    return url;
  }

  public async get(path) {
    let token = await this.getUserToken();

    
    let data = await this.http.get(`${this.getBaseURL()}/${path}/.json?auth=${token}`).toPromise();    
    return this.convertObjToArray(data);
  }

  public async loadCurrentUserData(): Promise<User> {
    if (!this.usuAtivo) {
      let users = await this.getUsers();
      let fireUserData = await this.getFireUserData();
      let base = JSON.parse(localStorage.getItem("system-base")).base;
  
      users.forEach((item) => {
        if (item.base == base) {
          if (item.email.toLowerCase() == fireUserData['email'].toLowerCase()){        
            this.usuAtivo = item;
          }
        }
      });
    }

    return this.usuAtivo;
  }
}
