import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase } from 'angularfire2/database';
import { Router } from '@angular/router';
import { User } from '../../classes/user';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase';

import { DialogService } from "../../ng2-bootstrap-modal";
import { UsuarioFormComponent } from '../dialogs/usuario-form/usuario-form.component';
import { ordemServico } from '../../classes/ordemServico';
import { UserService } from '../services/user/user.service'
import * as moment from 'moment';
import { CurrencyMaskModule } from "ng2-currency-mask";

@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.css']
})
export class ConfigComponent implements OnInit {
  dataBase = 'prod';
  listaSituacao: any[] = [];
  usuAtivo: User;
  usuarios: any[] = [];
  tecnicos: any[] = [];
  tecnicoSelecionado = "";
  usuariosPerm: {'userkey': string, 'perm': string}[] = [];

  isMaster = false;
  //listaPermissoes: {'Desc': string, 'Codigo': number, 'Usuarios': {'userkey': string, 'perm': string}[]}[] = [];
  //mostrandoPerm = false;

  constructor(private router: Router, public db: AngularFireDatabase, private afAuth: AngularFireAuth,
              private dialogService: DialogService, private userService: UserService) {
    afAuth.authState.subscribe((user: firebase.User) => {

      this.db.object("/usuarios").snapshotChanges().map(action => {
           this.usuarios = [];
           this.tecnicos = [];
           this.usuariosPerm = [];
           const data = action.payload.toJSON();
           return data;
         }).subscribe(result => {
            Object.keys(result).map(key=> {
              if (user && result[key].email.toLowerCase() == user.email.toLowerCase()){
                let base = JSON.parse(localStorage.getItem("system-base")).base;
                if (result[key].base == base) {
                  this.usuAtivo = result[key];
                  this.dataBase = this.usuAtivo.base;
                  this.isMaster = this.usuAtivo.master;

                }  
              }
              this.usuarios.push({'key': key, 'obj': result[key]});

              if (result[key].tecnico && result[key].ativo && result[key].base == this.dataBase) {
                this.tecnicos.push({'key': key, 'obj': result[key]});
              }
            });
            this.carregaSituacoes();
            this.carregaParamsGlobais();


            /*this.listaPermissoes.push({'Desc': "Remover Ordem de Serviço", 'Codigo': 0, 'Usuarios': []});
            this.listaPermissoes.push({'Desc': "Executar Relatórios Financeiros", 'Codigo': 1, 'Usuarios': []});
            this.listaPermissoes.push({'Desc': "Executar Relatórios Estoque", 'Codigo': 2, 'Usuarios': []});
            this.listaPermissoes.push({'Desc': "Executar Relatórios Técnicos", 'Codigo': 3, 'Usuarios': []});
            this.listaPermissoes.push({'Desc': "Executar Relatórios financeiros", 'Codigo': 4, 'Usuarios': []});
            */

            var i = 0;
            while (i < this.usuarios.length){
              if (this.usuarios[i].obj.base != this.dataBase){
                this.usuarios.splice(i, 1);
              } else {
                /*if (this.usuarios[i].obj.ativo){
                  this.listaPermissoes.forEach((item) => {
                    item.Usuarios.push({'userkey': this.usuarios[i].key, 'perm': '0-0-0'});
                  });
                  this.usuariosPerm.push({'userkey': this.usuarios[i].key, 'perm': '0-0-0'});
                }*/
                i++;
              }
            }

            /*let permOS = this.db.object(this.dataBase + "/permissoes/").snapshotChanges().map(action => {
                 const data = action.payload.toJSON();
                 return data;
               }).subscribe(result => {
                  this.listaSituacao = [];
                  if (result){
                    Object.keys(result).map(key=> {
                        this.usuariosPerm.forEach((item) => {
                          this.loadPerm(key, item.userkey, result[key][item.userkey]);
                        })
                    });
                  }

                  permOS.unsubscribe;
             });*/
      });
    });
  }

  /*private loadPerm(index, userkey, perm){
    //let permissao: {'Desc': string, 'Codigo': number, 'Usuarios': {'userkey': string, 'perm': string}[]};
    let permissao = this.listaPermissoes[index];

    permissao.Usuarios.forEach((item) => {
      if (item.userkey == userkey){
        item.perm = perm;
      }
    });
  }

  private loadUser(key){
    let retorno = undefined;
    this.usuarios.forEach((item) => {
      if (item.key == key){
          retorno = item.obj
      }
    })
    return retorno;
  }*/

  private carregaSituacoes(){
    this.db.object(this.dataBase + "/situacoes/").snapshotChanges().map(action => {
         const data = action.payload.toJSON();
         return data;
       }).subscribe(result => {
          this.listaSituacao = [];
          if (result){
            Object.keys(result).map(key=> {
              this.listaSituacao.push({ 'key': key, 'situacao':result[key].situacao, 'valor':result[key].valor});
            });
          }
    });
  }

  ngOnInit() {
  }

  public redirect(page){
    this.router.navigateByUrl('/' + page);
  }

  public removeSituacao(item){
    if (item.value){
      this.db.list(this.dataBase + "/situacoes/").remove(item.value);
    }
  }

  public alteraSituacao(item){
    let Obs = this.db.object(this.dataBase + "/situacoes/" + item.value).snapshotChanges().map(action => {
         const data = action.payload.toJSON();
         return data;
       }).subscribe(result => {

          let nomeSituacao = window.prompt("Altere o nome da situação:", result['situacao']);

          if (nomeSituacao){
            if (nomeSituacao == ""){
              alert('Não é possível adicionar uma situação vazia');
            } else {
              let valorSituacao = window.prompt("Informe o valor para a situação caso desejado:", result['valor']);
              if (valorSituacao && valorSituacao != ""){
                this.db.list(this.dataBase + "/situacoes/").update(item.value, {situacao: nomeSituacao, valor: valorSituacao});
              } else {
                this.db.list(this.dataBase + "/situacoes/").update(item.value, {situacao: nomeSituacao});
              }
            }
          }

          Obs.unsubscribe();
       });
  }

  public addSituacao(){
    let nomeSituacao = window.prompt("Informe a situação a ser adicionada:", "");

    if (nomeSituacao){
      if (nomeSituacao == ""){
        alert('Não é possível adicionar uma situação vazia');
      } else {
        let valorSituacao = window.prompt("Informe o valor para a situação caso desejado:", "");
        if (valorSituacao && valorSituacao != ""){
          this.db.list(this.dataBase + "/situacoes/").push({situacao: nomeSituacao, valor: valorSituacao});
        } else {
          this.db.list(this.dataBase + "/situacoes/").push({situacao: nomeSituacao});
        }
      }
    }
  }

  doLogin(user, pwd){
    this.afAuth.auth.signInWithEmailAndPassword(user.email, pwd.value)
                    .then(ok => {
                      this.router.navigate([""]);
                    }).catch(function(error) {
                      // Handle Errors here.
                      var errorCode = error.code;
                      var errorMessage = error.message;
                      switch (errorCode) {
                        case 'auth/wrong-password': {
                          alert('Senha inválida.');
                          return
                        }
                        case 'auth/invalid-email': {
                          alert('Usuário inválida.');
                          return
                        }
                        case 'auth/user-disabled': {
                          alert('Usuário desabilitado.');
                          return
                        }
                        case 'auth/user-not-found': {
                          alert('Usuário não encontrado.');
                          return
                        }
                        default: alert('Erro: ' + errorMessage);
                      }
                      console.log(error);
                    });

  }

  public adicionarUsuario(){
    let xUsuario = new User();
    xUsuario.base = this.dataBase;

    let disposable = this.dialogService.addDialog(UsuarioFormComponent, {
      title: 'Criando Usuário',
      message: 'Confirm message',
      usuario: xUsuario,
      dataBase: this.dataBase,
      editing: false
    }).subscribe((isConfirmed)=>{
      if(isConfirmed) {        
        this.db.list("/usuarios").push(xUsuario);
      }else{
      }
    });
  }

  public removerUsuario(nome, chaveUsuario){
    if (confirm('Deseja excluir o usuário "' + nome + '"')){
      this.db.list("/usuarios").remove(chaveUsuario);
    }
  }

  public alterarUsuario(usuario) {

    //var UserBkp = Object.assign({}, usuario.obj);
    var UserCopy = Object.assign({}, usuario.obj);
    let xUsuario = usuario.obj;
    xUsuario.base = this.dataBase;

    let disposable = this.dialogService.addDialog(UsuarioFormComponent, {
      title: 'Criando Usuário',
      message: 'Confirm message',
      usuario: UserCopy,
      dataBase: this.dataBase,
      editing: true
    }).subscribe((isConfirmed)=>{
      if(isConfirmed) {        
        this.db.list("/usuarios").update(usuario.key, UserCopy);
      }else{
      }
    });
  }

  public ativarClick(nome, chaveUsuario){
    let i = 0;
    while (i < this.usuarios.length){
      if (this.usuarios[i].key == chaveUsuario){
        this.usuarios[i].obj.ativo = !this.usuarios[i].obj.ativo;
        this.db.list("/usuarios/").update(chaveUsuario, this.usuarios[i].obj);
      }
      i++;
    }
  }

  public adminClick(chaveUsuario){
    let i = 0;
    while (i < this.usuarios.length){
      if (this.usuarios[i].key == chaveUsuario){
        this.usuarios[i].obj.adm = !this.usuarios[i].obj.adm;
        this.db.list("/usuarios/").update(chaveUsuario, this.usuarios[i].obj);
      }
      i++;
    }
  }

  public masterClick(chaveUsuario){
    let i = 0;
    while (i < this.usuarios.length){
      if (this.usuarios[i].key == chaveUsuario){
        this.usuarios[i].obj.master = !this.usuarios[i].obj.master;
        this.db.list("/usuarios/").update(chaveUsuario, this.usuarios[i].obj);
      }
      i++;
    }
  }

  public tecnicoClick(chaveUsuario){
    let i = 0;
    while (i < this.usuarios.length){
      if (this.usuarios[i].key == chaveUsuario){
        this.usuarios[i].obj.tecnico = !this.usuarios[i].obj.tecnico;
        this.db.list("/usuarios/").update(chaveUsuario, this.usuarios[i].obj);
      }
      i++;
    }
  }
  
  criarOSAut(backupOS, checklistOS) {

    if (!this.tecnicoSelecionado) {
      alert('Selecione um técnico.');
      return
    }
    let count = 0;
    firebase.database().ref(this.dataBase + "/utils/ultimoCodigoOS/value").once('value').then((snapshot) => {
      let ultimoCodigoOS = snapshot.val();
      let obsCliente = this.db.object(this.dataBase + "/clientes").snapshotChanges().map(action => {
        const data = action.payload.toJSON();
        return data;
      }).subscribe(result => {      
        if (result){
          Object.keys(result).map(key=> {
              
              if (result[key].backup && backupOS.checked) {
                count++;
                var newOS = new ordemServico(0);
                newOS.dataInicio = moment().format('YYYY-MM-DDTHH:mm');
  
                newOS.clienteQtdHoras = result[key].qtdHoras;
                newOS.clienteContrato = result[key].contrato;
                newOS.clienteValorHr = result[key].valor;
                newOS.clienteValorHrNot = result[key].valorNoturno ? result[key].valorNoturno : (result[key].valor * 1.5);

                ultimoCodigoOS++;   
                newOS.codigo = ultimoCodigoOS;

                // ALIMENTAR OS VALORES AQUI
                newOS.situacao = "Relatório Backup";
                newOS.codCliente = result[key].codCliente;
                newOS.nomeTecnico = this.tecnicoSelecionado;
                //newOS.dataVencimento
                newOS.local = 0;


                let ref = this.db.list(this.dataBase + "/dados/ordemServico/abertas/");
                ref.push(newOS);                           
                
              }
  
              if (result[key].checklist && checklistOS.checked) {
                count++;
                var newOS = new ordemServico(0);
                newOS.dataInicio = moment().format('YYYY-MM-DDTHH:mm');
                
                newOS.clienteQtdHoras = result[key].qtdHoras;
                newOS.clienteContrato = result[key].contrato;
                newOS.clienteValorHr = result[key].valor;
                newOS.clienteValorHrNot = result[key].valorNoturno ? result[key].valorNoturno : (result[key].valor * 1.5);
  
                ultimoCodigoOS++;   
                newOS.codigo = ultimoCodigoOS;

                // ALIMENTAR OS VALORES AQUI
                newOS.situacao = "Check List Servidor";
                newOS.codCliente = result[key].codCliente;
                newOS.nomeTecnico = this.tecnicoSelecionado;
                //newOS.dataVencimento
                newOS.local = 0;


                let ref = this.db.list(this.dataBase + "/dados/ordemServico/abertas/");
                ref.push(newOS);           
              }    
          });


          firebase.database().ref(this.dataBase + '/dados/utils/ultimoCodigoOS/').set({
            value: ultimoCodigoOS
          });

          alert("Foram criadas " + count + " OS.");

        }
        obsCliente.unsubscribe();
       });      

    });
  }

  carregaParamsGlobais() {
    firebase.database().ref("/dados/params-globais/valorMinimo/").once('value').then((snapshot) => {
      this.valorMinimo = snapshot.val().value;
    });  
    firebase.database().ref("/dados/params-globais/valorPlantao/").once('value').then((snapshot) => {
      this.valorPlantao = snapshot.val().value;
    });    
    firebase.database().ref("/dados/params-globais/valorMinPlantao/").once('value').then((snapshot) => {
      this.valorMinPlantao = snapshot.val().value;
    });    
  }

  valorMinimo: number = 0.0;
  valorPlantao: number = 0.0;
  valorMinPlantao: number = 0.0;
  gravaParamGlobais() {
    if (isNaN(this.valorMinimo)) { alert('Valor mínimo não informado.'); return }
    if (isNaN(this.valorPlantao)) { alert('Valor hora plantão não informado.'); return }
    if (isNaN(this.valorMinPlantao)) { alert('Valor mínimo plantãp  não informado.'); return }

    firebase.database().ref('/dados/params-globais/valorMinimo/').set({
      value: this.valorMinimo
    });
    firebase.database().ref('/dados/params-globais/valorPlantao/').set({
      value: this.valorPlantao
    });    
    firebase.database().ref('/dados/params-globais/valorMinPlantao/').set({
      value: this.valorMinPlantao
    }); 
  }

  async criarNovaBase(edtNomeNovabase) {
    let nome = edtNomeNovabase.value;
    try {
      let baseExiste = await this.userService.get(nome);
      if (baseExiste.length > 0) {
        alert('Já existe uma base com este nome');
        return
      }
    } catch (error) {
      console.log(error);
      alert('Erro não tratado. Verificar console.');
    }


    firebase.database().ref(`/${nome}/utils/ultimoCodigoOS/`).set({
      value: 0
    });

    let xUsuario = new User();
    xUsuario.adm = true;
    xUsuario.master = true;
    xUsuario.tecnico = false;
    xUsuario.base = nome;
    xUsuario.email = 'daniel.huebes@gmail.com';
    xUsuario.nome = 'Daniel Huebes';
    xUsuario.username = `dhuebes@${nome}`;
    xUsuario.primeiroLogon = false;
    this.db.list("/usuarios").push(xUsuario);

    alert('Base criada com sucesso.');
  }

}
