import { NgModule, ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ClienteComponent } from './components/cliente/cliente.component';
import { ListaClientesComponent } from './components/cliente/lista-clientes.component';
import { HomeComponent } from './components/home/home.component';
import { LogComponent } from './components/log/log.component';
import { LogDetailsComponent } from './components/log/log-details/log-details.component';
import { LoginPageComponent } from './components/login/login-page/login-page.component';
import { SelecionaComponent } from './components/relatorios/seleciona/seleciona.component';
import { SelecionaMobileComponent } from './components/relatorios/mobile/seleciona-mobile/seleciona-mobile.component';
import { PorClienteMobileComponent } from './components/relatorios/mobile/por-cliente-mobile/por-cliente-mobile.component';
import { TecnicoHorasMobileComponent } from './components/relatorios/mobile/tecnico-horas-mobile/tecnico-horas-mobile.component';
import { ConsolidadoMobileComponent } from './components/relatorios/mobile/consolidado-mobile/consolidado-mobile.component';
import { BancoSolucaoComponent } from './components/banco-solucao/banco-solucao.component';
import { ConfigComponent } from './components/config/config.component';
import { DoneComponent } from './components/os-view/done/done.component';
import { EstoqueComponent } from './components/estoque/estoque.component';
import { MobileComponent } from './components/estoque/mobile/mobile.component';
import { OrcamentoComponent } from './components/orcamento/orcamento.component';

export const routes: Routes = [
    { path: '', component: SelecionaComponent },
    { path: 'clientes', component: ListaClientesComponent },
    { path: 'cliente/:cod', component: ClienteComponent },
    { path: 'estoque', component: EstoqueComponent },
    { path: 'estoqueMobile', component: MobileComponent },
    { path: 'log/:cod', component: LogDetailsComponent },
    { path: 'login', component: LoginPageComponent },
    { path: 'relatorios', component: SelecionaComponent },
    { path: 'relatoriosMobile', component: SelecionaMobileComponent },
    { path: 'relPorClienteMobile', component: PorClienteMobileComponent },
    { path: 'relTecHoras', component: TecnicoHorasMobileComponent },
    { path: 'relConsolidado', component: ConsolidadoMobileComponent },
    { path: 'solucoes', component: BancoSolucaoComponent },
    { path: 'config', component: ConfigComponent },
    { path: 'logs', component: LogComponent },
    { path: 'concluidas', component: DoneComponent },
    { path: 'orcamento', component: OrcamentoComponent },
    { path: '**', redirectTo: '', pathMatch: 'full'}
];
