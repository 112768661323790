import { Component, OnInit } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from 'angularfire2/database';
import * as moment from 'moment';
import { User } from '../../../classes/user';
import { Observable } from 'rxjs/Observable';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebaseApp from 'firebase/app';
import { OsFormComponent } from '../../dialogs/os-form/os-form.component';
import {  DialogService } from "../../../ng2-bootstrap-modal";
import { DadosBancoService } from '../../services/dados-banco.service';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-done',
  templateUrl: './done.component.html',
  styleUrls: ['./done.component.css']
})
export class DoneComponent implements OnInit {

  ordemServicoConcluida = [];
  logsList: AngularFireList<any>;
  //ordemServicoConcluidas: AngularFireList<any>;
  ordemServicoAbertas: AngularFireList<any>;
  deleteList: AngularFireList<any>;
  dataBase = 'prod';
  clientes: any[] = [];
  obsUser: Observable<firebaseApp.User>;
  usuAtivo: User;
  isMaster = false;
  isEliane = false;
  tecnicos: any[] = [];
  setores: any[] = [];
  carregou: boolean = false;
  ordemServicoMostrando = [];
  listaSituacao: Observable<any[]>;
  ordemServicoBUG: any[] = [];
  isFirstTime = true;

  qtdPaginas: any[] = [];
  qtdPaginaCombo: any[] = [];
  paginaAtual: number = 0;
  qtdPorPagina = 20;

  filtroCliente: string = '';
  filtroTecnico: string = '';
  filtroSetor: string = '';
  filtroSituacao: string = '';
  filtroCodigo: string = '';
  filtroLocal: number = -1;
  filtroTempo: string = "0";

  orderbyDataFim: boolean = false;
  orderbyDataInicio: boolean = false;
  orderbyLocal: boolean = false;
  orderbyTecnico: boolean = false;
  orderbyCliente: boolean = false;
  orderbyCodigo: boolean = false;
  colunaOrderby: string = 'dataFim';
  orderbyASC: boolean = false;
  isMobile: boolean = false;

  constructor(public db: AngularFireDatabase, private afAuth: AngularFireAuth, private dialogService: DialogService,
              public serviceDados: DadosBancoService, private spinner: NgxSpinnerService) {

    if(/mobi|(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
    || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) 
      this.isMobile = true;

    this.orderbyDataFim = true;
    this.obsUser = this.afAuth.authState;
    let ObsUser1 = afAuth.authState.subscribe((user: firebase.User) => {
      let ObsUser2 = this.db.object("/usuarios").snapshotChanges().map(action => {
           const data = action.payload.toJSON();
           return data;
         }).subscribe(result => {
             Object.keys(result).map(key=> {
               if (user && result[key].email.toLowerCase() == user.email.toLowerCase()){
                 let base = JSON.parse(localStorage.getItem("system-base")).base;
                 if (result[key].base == base) {
                   this.usuAtivo = result[key];
                   this.dataBase = this.usuAtivo.base;
                   this.isMaster = this.usuAtivo.master;
                 } 
               }
               if (result[key].tecnico){
                 this.tecnicos.push({ 'key': key, 'data':result[key] });
               }
               if (result[key].setor) {
                 let setores = result[key].setor.split(';').map((setor) => setor.trim());
                 setores.forEach((item) => {
                   if (this.setores.indexOf(item) == -1) {
                     this.setores.push(item);
                   }
                  })
                 
               }  
             });
             var i = 0;
             while (i < this.tecnicos.length){
               if (this.tecnicos[i].data.base != this.dataBase){
                 this.tecnicos.splice(i, 1);
               } else {
                 i++;
               }
             }

             this.carregarDados();
             //ObsUser2.unsubscribe;
      });
      //ObsUser1.unsubscribe;
    });
  }

  public carregarDados() {
    //this.ordemServicoConcluidas = this.db.list(this.dataBase + "/ordemServico/concluidas/");
    this.ordemServicoAbertas = this.db.list(this.dataBase + "/ordemServico/abertas/");
    this.logsList = this.db.list(this.dataBase + "/logs/");
    this.listaSituacao = this.db.list(this.dataBase + "/situacoes/").valueChanges();

    if (this.dataBase != 'gessner' ) {
      this.carregarDadosComCliente();
    } else {
      this.carregaOS();
    }
  }

  public carregarDadosComCliente() {
    let obsCli = this.db.object(this.dataBase + "/clientes").snapshotChanges().map(action => {
      const data = action.payload.toJSON();
      return data;
    }).subscribe(result => {
       this.clientes = [];
       if (result){
         Object.keys(result).map(key=> {
              this.clientes.push({ 'key': key, 'data':result[key], 'nome': result[key].nomeFantasia });
         });
         this.carregaOS();
       }
       obsCli.unsubscribe;
    });
  }

  public filtro(os){
    let respeitaFiltro = true;
    if (this.filtroCliente != "" && this.filtroCliente.toLowerCase() != this.getClienteNome(os.codCliente).toLowerCase()){
      respeitaFiltro = false;
    }
    if (this.filtroTecnico != "" && this.filtroTecnico.toLowerCase() != os.nomeTecnico.toLowerCase()){
      respeitaFiltro = false;
    }
    if (this.filtroSetor != "" && !os.setor) {
      os.setor = '';
    }
    if (this.filtroSetor != "" && this.filtroSetor.toLowerCase() != os.setor.toLowerCase()){
      respeitaFiltro = false;
    }
    if (this.filtroSituacao != "" && this.filtroSituacao.toLowerCase() != os.situacao.toLowerCase()){
      respeitaFiltro = false;
    }
    if (this.filtroCodigo != "" && this.filtroCodigo != os.codigo){
      respeitaFiltro = false;
    }
    if (this.filtroLocal != -1 && this.filtroLocal != os.local){
      respeitaFiltro = false;
    }

    let now = moment();
    switch (this.filtroTempo){
      case "0": {now.week(now.week() - 1); break }
      case "1": {now.week(now.week() - 2); break }
      case "2": {now.month(now.month() - 1); break }
      case "3": {now.month(now.month() - 2); break }
      case "4": return respeitaFiltro;
    }

    if (moment(os.dataFim, 'YYYY-MM-DDTHH:mm').isBefore(now)){
      respeitaFiltro = false;
    }

    return respeitaFiltro;
  }

  public sortOrdemServicoConcluida(){
    this.ordemServicoConcluida.sort((a: any, b: any) => {
      let dadoA;
      let dadoB;
      if (this.colunaOrderby == 'nomeCliente'){
        dadoA = a.nomeCliente;
        dadoB = b.nomeCliente;
      } else {
        dadoA = a.data[this.colunaOrderby];
        dadoB = b.data[this.colunaOrderby];
      }

      if (isNaN(dadoA)){
        if (dadoA == ''){
          return 1;
        }
        if ( dadoA.toLowerCase() < dadoB.toLowerCase() ){
          return 1;
        }else if( dadoA.toLowerCase() > dadoB.toLowerCase() ){
            return -1;
        }else{
          return 0;
        }
      } else {
        if ( dadoA < dadoB ){
          return 1;
        }else if( dadoA > dadoB ){
            return -1;
        }else{
          return 0;
        }
      }
    });
  }

  public addOrdemServicoConcluida(param){
    let canAdd = true;
    this.ordemServicoConcluida.forEach((item) => {
        if (canAdd && param.key == item.key){
          canAdd = false;
        }
    });

    if (canAdd) {
      if (param.data.atividadesJSON) {
        param.data.atividadesJSON = Object.keys(param.data.atividadesJSON).map(i => param.data.atividadesJSON[i]);
        param.data.atividadesJSON.forEach((item) => {
          if (item.esforcos) {
            item.esforcos = Object.keys(item.esforcos).map(i => item.esforcos[i]);
          } else {
            item.esforcos = [];
          }
        })
      }
      
      //param['atividades'] = JSON.parse(param.data.atividades);
      this.ordemServicoConcluida.push(param);
    }
  }

  public carregaOS(){
    /*let ObsOS = this.db.object(this.dataBase + "/ordemServico/concluidas/2018-09-01").snapshotChanges().map(action => {
         const data = action.payload.toJSON();
         return data;
       }).subscribe(result => {
          if (result){

             Object.keys(result).map(key => {
               //result[key]
               let ordemServico = this.db.list(this.dataBase + "/ordemServico/concluidas/2018-09-01");
               ordemServico.remove(key);
               //let ordemServico = this.db.list(this.dataBase + "/ordemServico/concluidas/2018-09-01");
               //ordemServico.remove(key);
             });
           }
           ObsOS.unsubscribe;
        });*/




    let countDias = 0;
    switch (this.filtroTempo){
      case "0": {countDias = 7; break; }
      case "1": {countDias = 14; break; }
      case "2": {countDias = 30; break; }
      case "3": {countDias = 60; break; }
      case "4": {countDias = -1; break; }
    }

    //this.carregou = false;
    let i = 0;
    this.ordemServicoConcluida = [];
    if (countDias == -1){ // CARREGA TODOS
      let xConfirmed = false;
      if (this.isFirstTime) {
        xConfirmed = confirm("Carregar \"Todos\" pode demorar, deseja continuar?");
      } else {
        xConfirmed = true;
      }

      if (xConfirmed) {
        this.isFirstTime = false;
        this.spinner.show();

        let ObsOS = this.db.object(this.dataBase + "/ordemServico/concluidas/").snapshotChanges().map(action => {
          const data = action.payload.toJSON();
          return data;
        }).subscribe(result => {
          try {              
            this.ordemServicoConcluida = [];
            if (result){
              Object.keys(result).map(date => {
                Object.keys(result[date]).map(key => {
                      if (this.filtro(result[date][key])) {
                        let countAtividades = 0;
                          if (result[date][key].atividades){
                            countAtividades = JSON.parse(result[date][key].atividades).length
                          }
                          this.ordemServicoConcluida.push({ 
                              'key': key, 'data':result[date][key], 'nomeCliente': this.getClienteNome(result[date][key].codCliente),
                              'mobileVisivel': false, 'countAtividades': countAtividades});
                              //'atividades': JSON.parse(result[date][key].atividades)});
                      }
                  })
              });
              this.sortOrdemServicoConcluida();
              if (this.orderbyASC){
                this.ordemServicoConcluida.reverse();
              }

              this.qtdPaginaCombo = [];
              this.qtdPaginas = Array(Math.ceil(this.ordemServicoConcluida.length / this.qtdPorPagina)).fill(0).map((x,i)=>i); // [0,1,2,3,4]
              this.qtdPaginas.forEach((number) => {
                if (number > 9){
                  this.qtdPaginaCombo.push(number);
                }
              })

              this.carregaPagina(0);
            }
            //ObsOS.unsubscribe();
            this.carregou = true;
            this.spinner.hide();
            //this.spinnerService.hide();
          }
          catch(e) {
            console.log(e);
            this.carregou = true;
            this.spinner.hide();
            //this.spinnerService.hide();
          }
        });
      }
      

    } else {
      let countObs = 0;
      let totali = 0;
      this.ordemServicoConcluida = [];
      this.ordemServicoBUG = [];
      if (countDias > 14 || this.isMobile) {
        this.spinner.show();
      }      
      for (i = 0;i <= countDias; i++){
        let dia = moment();
        let diaFormatado = dia.days(dia.days()-i).format('YYYY-MM-DD');
        /*let ObsOS = */this.db.object(this.dataBase + "/ordemServico/concluidas/" + diaFormatado).snapshotChanges().map(action => {
             const data = action.payload.toJSON();
             return data;
           }).subscribe(result => {
              if (result){
                 Object.keys(result).map(key=> {
                   if (this.filtro(result[key])) {


                      // ---------------------
                      // IF é OS automática 
                      if (moment(result[key].dataInicio, 'YYYY-MM-DDTHH:mm').isAfter(moment('01/08/2019', 'YYYY-MM-DD')) && 
                          (result[key].situacao == "Relatório Backup" ||
                           result[key].situacao == "Check List Servidor")) {
                            let cliente = this.getClienteData(result[key].codCliente);

                            result[key].clienteQtdHoras = cliente.qtdHoras;
                            result[key].clienteContrato = cliente.contrato;
                            result[key].clienteValorHr = cliente.valor;
                            result[key].clienteValorHrNot = cliente.valorNoturno ? cliente.valorNoturno : (cliente.valor * 1.5);


                            let countAtividades = 0;
                            if (result[key].atividades){
                              countAtividades = JSON.parse(result[key].atividades).length
                            }                            
                            this.ordemServicoBUG.push({ 'key': key, 'data':result[key], 'nomeCliente': cliente.nomeFantasia, 'mobileVisivel': false, 'countAtividades': countAtividades});
                      }
                      // ---------------------



                      let countAtividades = 0;
                       if (result[key].atividades){
                         countAtividades = JSON.parse(result[key].atividades).length
                       }
                       this.addOrdemServicoConcluida({ 'key': key, 'data':result[key], 'nomeCliente': this.getClienteNome(result[key].codCliente), 'mobileVisivel': false, 'countAtividades': countAtividades});
                       //this.ordemServicoConcluida.push({ 'key': key, 'data':result[key], 'nomeCliente': this.getClienteNome(result[key].codCliente), 'mobileVisivel': false, 'countAtividades': countAtividades});
                   }
                 });
                 this.sortOrdemServicoConcluida();
                 if (this.orderbyASC){
                   this.ordemServicoConcluida.reverse();
                 }

                 this.qtdPaginaCombo = [];
                 this.qtdPaginas = Array(Math.ceil(this.ordemServicoConcluida.length / this.qtdPorPagina)).fill(0).map((x,i)=>i); // [0,1,2,3,4]
                 this.qtdPaginas.forEach((number) => {
                   if (number > 9){
                     this.qtdPaginaCombo.push(number);
                   }
                 });
                 this.carregaPagina(this.paginaAtual);

              }
              countObs++;
              //ObsOS.unsubscribe();
              if (countObs == totali){
                  this.carregou = true;
                  this.spinner.hide();
                  this.carregaPagina(0);
              }
        });
      }
      totali = i;
    }
  }

  atualizarValoresOS() {
    this.ordemServicoBUG.forEach((item) => {       
      this.db.list(this.dataBase + "/ordemServico/concluidas/" + moment(item.data.dataFim, 'YYYY-MM-DDTHH:mm').format('YYYY-MM-DD')).update(item.key, item.data);
    });
  }

  ngOnInit() {
  }

  public proxPagina(){
    if (this.qtdPaginas.length > this.paginaAtual+1){
      this.carregaPagina(this.paginaAtual+1)
    }
  }

  public anteriorPagina(){
    if (this.paginaAtual > 0){
      this.carregaPagina(this.paginaAtual-1)
    }
  }

  public carregaPagina(proxPagina) {
    if (proxPagina == NaN) return

    this.paginaAtual = proxPagina;
    let mimPagina = 0;
    if (proxPagina > 0){
      mimPagina = (proxPagina * this.qtdPorPagina);
    }
    let maxPagina = 0;
    if ((mimPagina + this.qtdPorPagina) < this.ordemServicoConcluida.length){
      maxPagina = mimPagina + this.qtdPorPagina;
    } else {
      maxPagina = this.ordemServicoConcluida.length;
    }

    this.ordemServicoMostrando = [];
    for(var i = mimPagina; i < maxPagina; ++i) {
         this.ordemServicoMostrando.push(this.ordemServicoConcluida[i]);
    }

  }

  public getClienteNome(codCliParam){
    let codCli = +codCliParam;
    let name = '';
    this.clientes.forEach((cliente, index) => {
      if (cliente.data.codCliente == codCli){
        name =  cliente.data.nomeFantasia;
      }
    });

    return name;
  }


  public getClienteData(codCliParam){
    let codCli = +codCliParam;
    let data: any;
    this.clientes.forEach((cliente, index) => {
      if (cliente.data.codCliente == codCli){
        data = cliente.data;
      }
    });

    return data;
  }  

  public formataData(data){
    return moment(data, 'YYYY-MM-DDTHH:mm').format('DD/MM/YYYY HH:mm');
  }

  public getLocalDesc(codLocal){
    if (codLocal == 0){
      return "Interno";
    } else {
      return "Externo";
    }
  }

  public getStatusDesc(codStatus){
    switch (codStatus){
      case 0: return "Aberto";
      case 1: return "Em atraso";
      case 2: return "Concluído";
    }
  }

  public filtraClienteDone(valor){
    this.filtroCliente = valor;
    this.carregaOS();
  }

  public filtraTecnicoDone(valor){
    this.filtroTecnico = valor;
    this.carregaOS();
  }

  public filtraSetorDone(valor){
    this.filtroSetor = valor;
    this.carregaOS();
  }

  public filtraSituacaoDone(valor){
    this.filtroSituacao = valor;
    this.carregaOS();
  }

  public filtraCodigoDone(valor){
    this.filtroCodigo = valor;
    this.carregaOS();
  }

  public filtraLocalDone(valor){
    this.filtroLocal = valor;
    this.carregaOS();
  }

  public filtraTempoDone(valor){
    this.filtroTempo = valor;
    this.carregaOS();
  }

  public orderby(coluna){
    if (this.colunaOrderby == coluna){
      this.orderbyASC = !this.orderbyASC;
      this.ordemServicoConcluida.reverse();
    } else {
      this.orderbyASC = true;
      this.colunaOrderby = coluna;
      this.orderbyCodigo = (coluna == 'codigo');
      this.orderbyCliente = (coluna == 'nomeCliente');
      this.orderbyTecnico = (coluna == 'nomeTecnico');
      this.orderbyLocal = (coluna == 'local');
      this.orderbyDataInicio = (coluna == 'dataInicio');
      this.orderbyDataFim = (coluna == 'dataFim');

      this.sortOrdemServicoConcluida();
      this.ordemServicoConcluida.reverse();
    }

    this.carregaPagina(this.paginaAtual);
  }

  public formataEsforco(esforco){
    let formatarEsforco = moment('00:00', 'HH:mm');
    formatarEsforco.minutes(esforco);
    return formatarEsforco.format('HH:mm');
  }

  public verOS(ordem){
    //this.ordemServicoConcluida.forEach((item) => {
      //let dataFimFormatada = moment(item.data.dataFim, 'yyyy-MM-DDTHH:mm').format('YYYY-MM-DD');
      //let myRef = this.db.list(this.dataBase + "/ordemServico/concluidas/" + dataFimFormatada).push(item.data);
      //let myRef = this.db.list(this.dataBase + "/ordemServico/concluidas/").remove(item.key);
    //});

    var OSCopy = Object.assign({}, ordem.data);
    let disposable = this.dialogService.addDialog(OsFormComponent, {
      title:'Visualizando OS',
      message:'Confirm message',
      itemOS: OSCopy,
      clienteList: this.clientes,
      modoTela: 2,
      tecList: this.tecnicos,
      usuAtivo: this.usuAtivo
    }).subscribe((isConfirmed)=>{
      if(isConfirmed) {
        this.logsList.push({usuario: this.usuAtivo.email, acao: "Alterou", codigo: OSCopy.codigo, tipo: "OS", chaveReg: ordem.key, registros: JSON.stringify(OSCopy), data: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')});

        let dataFimFormatada = moment(OSCopy.dataFim, 'YYYY-MM-DDTHH:mm').format('YYYY-MM-DD');
        if (ordem.data.dataFim != OSCopy.dataFim){
            let oldDataFimFormatada = moment(ordem.data.dataFim, 'YYYY-MM-DDTHH:mm').format('YYYY-MM-DD');
            this.db.list(this.dataBase + "/ordemServico/concluidas/" + oldDataFimFormatada).remove(ordem.key);
            this.db.list(this.dataBase + "/ordemServico/concluidas/" + dataFimFormatada).push(OSCopy);

            this.carregaOS();
        } else {
            let myRef = this.db.list(this.dataBase + "/ordemServico/concluidas/" + dataFimFormatada).update(ordem.key, OSCopy);
        }

      }else{
      }
    });
  }

  public removerOS(item){
    if (confirm("Deseja remover a OS?")){
      this.logsList.push({usuario: this.usuAtivo.email, acao: "Excluiu", codigo: item.data.codigo, tipo: "OS", chaveReg: item.key, registros: "Removeu OS", data: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')});

      this.deleteList = this.db.list(this.dataBase + "/ordemServicoExcluidas");
      this.deleteList.push(item.data);

      let dataFimFormatada = moment(item.data.dataFim, 'YYYY-MM-DDTHH:mm').format('YYYY-MM-DD');
      let myRef = this.db.list(this.dataBase + "/ordemServico/concluidas/" + dataFimFormatada).remove(item.key);
      this.carregaOS();
    }
  }

  public reabrirOS(item){
    item.data.status = 0;
    this.logsList.push({usuario: this.usuAtivo.email, acao: "Reabriu", codigo: item.data.codigo, tipo: "OS", chaveReg: item.key, registros: "Reabriu OS", data: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ')});

    let dataFimFormatada = moment(item.data.dataFim, 'YYYY-MM-DDTHH:mm').format('YYYY-MM-DD');
    this.ordemServicoAbertas.push(item.data);
    let myRef = this.db.list(this.dataBase + "/ordemServico/concluidas/" + dataFimFormatada).remove(item.key);
    this.carregaOS();
  }

  public mudaPagina(NovaPagina){

  }

  public mostrarOS(ordem){
    ordem.mobileVisivel = !ordem.mobileVisivel;
  }

  public esconderOS(ordem){
    ordem.mobileVisivel = false;
  }

  public pegaAtividadesArray(atividade){
    if (atividade){
      return JSON.parse(atividade);
    }
  }
}
